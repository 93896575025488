/**
 * Get object value by path.
 * @param path Path.
 * @param obj Object to resolve path form.
 * @param separator Separator.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function resolvePath(path: string | string[], obj: any, separator = '.'): unknown {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev?.[curr], obj);
}
