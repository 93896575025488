import { Injectable } from '@angular/core';

import { State } from '../../models/state';

/**
 * State filter query params mapper service.
 */
@Injectable({
  providedIn: 'root',
})
export class StateFilterQueryParamsService {
  /**
   * Transform state to query params.
   * @param states States.
   */
  public toQueryParams(states: State[]): string {
    return states.map(state => `${state.id}:${state.geonameCode}:${state.name}`).join(';');
  }

  /**
   * Transform states filter from params to array of State entities.
   * @param params States filter params.
   */
  public fromQueryParams(params: string): State[] {
    return params.split(';').map(state => {
      const [id, geonameCode, name] = state.split(':');
      return new State({
        id: Number(id),
        geonameCode,
        name,
      });
    });
  }
}
