<ng-container *ngIf="currentUser$ | async as user">
  <ng-container *ngIf="showCellValue(user) else lockCell">
    <ng-content></ng-content>
  </ng-container>
</ng-container>
<ng-template #lockCell>
  <div class="lock-cell">
    <div>Tier {{ tier }} Access</div>
    <mat-icon class="lock-icon" svgIcon="lock-black"></mat-icon>
  </div>
</ng-template>