/** Matrix search name. */
export class MatrixSearchName {
  /** Matrix name id. */
  public readonly id: number;
  /** Matrix name. */
  public readonly name: string;

  /**
   * @constructor
   * @param data Constructor init data.
   */
  public constructor(data: MatrixSearchNameConstructorData) {
    this.id = data.id;
    this.name = data.name;
  }
}

export type MatrixSearchNameConstructorData = MatrixSearchName;
