import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ContentChildren,
  QueryList,
  ChangeDetectionStrategy,
  TrackByFunction,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort as MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { TableColumnInfo } from '@scriptac/common/core/models/column-info';
import { PaginationOptions } from '@scriptac/common/core/models/pagination-options';
import { Sort } from '@scriptac/common/core/models/sort';
import { DestroyableComponent } from '@scriptac/common/core/utils/destroyable';
import { matSortToInternalSort } from '@scriptac/common/core/utils/table-sort';

import { TableColumnDirective } from '../../directives/table/table-column.directive';

/** Cell styles. */
export interface CellStyle {
  /** Cell width. */
  width?: string;
  /** Cel min-width. */
  minWidth?: string;
}

/** Base table component. */
@Component({
  selector: 'scriptaw-base-table',
  templateUrl: './base-table.component.html',
  styleUrls: ['./base-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroyableComponent()
export class BaseTableComponent<T> {
  /** Loading indicator. */
  @Input()
  public loading: boolean | null = false;

  /** Items to display. */
  @Input()
  public set rows(value: T[] | null) {
    if (value) {
      this.dataSource.data = value;
    }
  }

  /** Columns information. */
  @Input()
  public columns: TableColumnInfo[] = [];

  /** Sort settings. */
  @Input()
  public sort?: Sort | null;

  /** Table row CSS class. */
  @Input()
  public customRowClass: (row: T) => string | string[] = () => '';

  /** Pagination settings. */
  @Input()
  public pagination?: PaginationOptions | null;

  /** Message that displays when items not found. */
  @Input()
  public emptyMessage = 'No items found.';

  /** Emitted when sort changes. */
  @Output()
  public readonly sortChange: EventEmitter<Sort | undefined> = new EventEmitter();

  /** Emitted when pagination changes. */
  @Output()
  public paginationChange: EventEmitter<PaginationOptions> = new EventEmitter();

  /** Emitted when table scroll to bottom. */
  @Output()
  public readonly scrollToBottom = new EventEmitter();

  /** Internal representation of data source for support of native sorting feature. */
  public readonly dataSource: MatTableDataSource<T> = new MatTableDataSource<T>([]);

  /** Table instance. */
  @ViewChild(MatTable)
  public readonly table!: MatTable<T>;

  /** Columns templates. */
  @ContentChildren(TableColumnDirective)
  private readonly columnTemplates?: QueryList<TableColumnDirective>;

  /**
   * Function for trackBy.
   * @param index Track index.
   */
  @Input()
  public trackBy: TrackByFunction<T> = (index: number) => index;

  /** Handle click on the specific item. */
  public get columnNames(): string[] {
    return this.columns.map(c => c.name);
  }

  /**
   * Handle sort changing.
   * @param matSort Material sort event.
   */
  public onSortChange(matSort: MatSort): void {
    const sort = matSortToInternalSort(matSort);
    this.sortChange.emit(sort);
  }

  /**
   * Get cell template by the name.
   * @param name Column name.
   */
  public getColumnByName(name: string): TableColumnDirective | undefined {
    return this.columnTemplates?.find(column => column.name === name);
  }

  /**
   * Get cell styles.
   * @param col Columns info.
   */
  public getCellStyles(col: TableColumnInfo): CellStyle | null {
    const styles = {} as CellStyle;
    if (col.width) {
      styles.width = col.width;
    }
    if (col.minWidth) {
      styles.minWidth = col.minWidth;
    }
    return Object.keys(styles).length > 0 ? styles : null;
  }

  /**
   * Paginator changed.
   *
   * @param page Pagination event.
   */
  public paginationChanged(page: PageEvent): void {
    this.paginationChange.emit(new PaginationOptions({
      page: page.pageIndex,
      pageSize: page.pageSize,
      totalCount: page.length,
    }));
  }

  /**
   * Get table column header text (only used for default header).
   * @param column Column info.
   * @param textFromDirective Column header text from TableColumn directive.
   */
  public getTableHeaderText(column: TableColumnInfo, textFromDirective: string): string {
    const textFromInfo = column.headerText !== '' ? column.headerText ?? column.name ?? '' : '';
    return textFromDirective !== undefined ? textFromDirective : textFromInfo;
  }

  /**
   * Get table cell classes.
   * @param column Column info.
   * @param element Column element.
   * @param colName Column name.
   */
  public getTableCellClass(
    column: TableColumnDirective,
    element: any,
    colName: string,
  ): string | string[] {
    return column?.cellClassesGetter ? column.cellClassesGetter(element, colName) : '';
  }
}
