import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatrixValue } from '@scriptac/common/core/models/matrix-value';
import { createTrackByPropertyFunction } from '@scriptac/common/core/utils/track-by-property';
import { AccessTierLevel } from '@scriptac/common/core/enums/access-tier-level';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DestroyableComponent } from '@scriptac/common/core/utils/destroyable';
import { CurrentUserService } from '@scriptac/common/core/services/current-user.service';
import { PageEvent } from '@angular/material/paginator';
import { PaginationOptions } from '@scriptac/common/core/models/pagination-options';
import { MatrixColumnInfo } from '@scriptac/common/core/models/matrix-column-info';

import { TableColumnDirective } from '../../directives/table/table-column.directive';

/** Table with header as a first column. */
@Component({
  selector: 'scriptaw-horizontal-matrix',
  templateUrl: './horizontal-matrix.component.html',
  styleUrls: ['./horizontal-matrix.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroyableComponent()
export class HorizontalMatrixComponent<T> {

  /** Fixed column name. */
  public readonly fixedColumnName = 'fixed-column';

  /** State column name. */
  public readonly stateColumnName = 'state-column';

  /** All columns list. */
  public allColumns: MatrixColumnInfo[] = [];

  private columnsValue: MatrixColumnInfo[] = [];

  /**
   * Columns of matrix.
   * @param columns Columns from parent component.
   */
  @Input()
  public set columns(columns: MatrixColumnInfo[] | null) {
    this.allColumns = columns ?? [];
    this.columnsValue = columns?.filter(c => c.shouldDisplay) ?? [];
  }

  /** Get columns list. */
  public get columns(): MatrixColumnInfo[] | null {
    return this.columnsValue;
  }

  /** Pagination info. */
  @Input()
  public pagination: PaginationOptions | null = null;

  /**
   * Track list by elements` name.
   */
  public readonly trackByName = createTrackByPropertyFunction<MatrixColumnInfo>('name');

  /** Internal representation of data source for support of native sorting feature. */
  public readonly dataSource: MatTableDataSource<T> = new MatTableDataSource<T>([]);

  /** Table instance. */
  @ViewChild(MatTable)
  public readonly table!: MatTable<T>;

  /**
   * Fixed cell template.
   */
  @ContentChild('fixedCellTemplate')
  public fixedCellTemplate: TemplateRef<any> | null = null;

  /**
   * State cell template.
   */
  @ContentChild('stateCellTemplate')
  public stateCellTemplate: TemplateRef<any> | null = null;

  /**
   * Custom state cell template.
   * It should be used to rewrite cells styled directly (e.g. Manipulate rowspan attribute).
   * This template will override regular 'stateCellTemplate'.
   */
  @ContentChild('customStateCellTemplate')
  public customStateCellTemplate: TemplateRef<any> | null = null;

  /** Loading indicator. */
  @Input()
  public loading: boolean | null = false;

  /** Date format to display date in cell. */
  @Input()
  public dateFormat?: string;

  /** Function to get values list from matrix element. */
  @Input()
  public getElementValuesList: (elem: T) => MatrixValue[] = () => [];

  /** Function to get row CSS class (added class can be accessed from global scope only). */
  @Input()
  public getRowCssClass?: (row: T, index?: number) => string;

  /** Matrix id. */
  @Input()
  public matrixId: number | null = null;

  /** Flag for show fixed column. */
  @Input()
  public showFixedColumn: boolean | null = false;

  /** Flag for show state column. */
  @Input()
  public showStateColumn: boolean = false;

  /** Message that displays when items not found. */
  @Input()
  public emptyMessage = 'No items found.';

  /** Message that displays when items not found. */
  @Input()
  public disableColumnsReorder = false;

  /** Current user data. */
  public readonly currentUser$ = this.userService.currentUser$;

  /** Items to display. */
  @Input()
  public set matrixData(value: T[] | null) {
    if (value) {
      this.dataSource.data = value;
    }
  }

  /** Emitted when pagination changes. */
  @Output()
  public paginationChange: EventEmitter<PaginationOptions> = new EventEmitter();

  /** Emitted when columns data changed. */
  @Output()
  public columnsUpdate = new EventEmitter();

  /** Columns templates. */
  @ContentChildren(TableColumnDirective)
  private readonly columnTemplates?: QueryList<TableColumnDirective>;

  /** Handle click on the specific item. */
  public get columnNames(): string[] {
    let columnsNames = this.columns?.map(c => c.name) ?? [];
    if (this.showStateColumn) {
      columnsNames = [this.stateColumnName].concat(columnsNames);
    }
    if (this.showFixedColumn) {
      columnsNames = columnsNames.concat(this.fixedColumnName);
    }
    return columnsNames;
  }

  public constructor(
    private readonly userService: CurrentUserService,
  ) { }

  /**
   * Get cell tier from matrix data.
   * @param row Row data.
   * @param columnName Cell column name.
   */
  public getCellTier(row: T, columnName: string): number {
    const foundCellValue = this.getCellValueByColumnName(row, columnName);
    const matrixColumnTier = this.columns?.find(c => c.name === columnName)?.tier;

    return foundCellValue?.field.tier ?? matrixColumnTier ?? AccessTierLevel.Tier1; // Set the most general tier in this case
  }

  /**
   * Find value from revision values by columns name.
   * @param row Row data.
   * @param columnName Cell column name.
   */
  public getCellValueByColumnName(row: T, columnName: string): MatrixValue | null {
    return this.getElementValuesList(row).find(value => value.field.name.toLowerCase() === columnName) ?? null;
  }

  /**
   * Get cell template by the name.
   * @param name Column name.
   */
  public getColumnTemplateByName(name: string): TemplateRef<any> | undefined {
    return this.columnTemplates?.find(column => column.name === name)?.cell?.template;
  }

  /**
   * Handler for element dropped after drag.
   * As some columns can be hidden, we map swap indexes from displayed list to list with all options.
   * @param event Event data.
   */
  public dropListDropped(event: CdkDragDrop<T, T>): void {
    if (event && this.columns) {
      const firstElem = this.columns[event.previousIndex];
      const secondElem = this.columns[event.currentIndex];
      const indexOfFirst = this.allColumns.findIndex(elem => firstElem.name === elem.name);
      const indexOfSecond = this.allColumns.findIndex(elem => secondElem.name === elem.name);
      const cols = [...this.allColumns];
      moveItemInArray(cols, indexOfFirst, indexOfSecond);
      this.columnsUpdate.emit(cols);
    }
  }

  /**
   * Paginator changed.
   *
   * @param page Pagination event.
   */
  public paginationChanged(page: PageEvent): void {
    this.paginationChange.emit(new PaginationOptions({
      page: page.pageIndex,
      pageSize: page.pageSize,
      totalCount: page.length,
    }));
  }

  /**
   * Get empty table colspan.
   */
  public getEmptyTableColspan(): number {
    let colspan = this.columns?.length ?? 2;
    if (this.showFixedColumn) {
      colspan++;
    }

    if (this.showStateColumn) {
      colspan++;
    }

    return colspan;
  }

  /**
   * Update column width in columns width and emit values to parent.
   * @param width Updated width.
   * @param column Column to update.
   */
  public updateColumnWidth(width: number, column: MatrixColumnInfo): void {
    const updatedColumn = { ...column, width };

    const updatedColumns = this.allColumns?.map(col => {
      if (col.name === column.name) {
        return updatedColumn;
      }
      return col;
    }) ?? null;
    this.columnsUpdate.emit(updatedColumns);
  }
}
