<button mat-icon-button class="close-button" [mat-dialog-close]="true" type="button">
  <mat-icon class="close-icon">close</mat-icon>
</button>

<h1 class="title" mat-dialog-title>Scripta LLC Website Privacy Policy</h1>
<div class="content" mat-dialog-content>
  <p>
    Your privacy is important to us. It is Scripta LLC's policy to respect your privacy and comply
    with any applicable law and regulation regarding any personal information we may collect about
    you, including across our website, <a [href]="scriptaUrl">{{ scriptaUrl }}</a
    >, and other sites we own and operate.
  </p>

  <p>
    Personal information is any information about you which can be used to identify you. This
    includes information about you as a person (such as name, address, and date of birth), your
    devices, payment details, and even information about how you use a website or online service.
  </p>

  <p>
    In the event our site contains links to third-party sites and services, please be aware that
    those sites and services have their own privacy policies. After following a link to any
    third-party content, you should read their posted privacy policy information about how they
    collect and use personal information. This Privacy Policy does not apply to any of your
    activities after you leave our site.
  </p>

  <p>This policy is effective as of 31 March 2021.</p>

  <p>Last updated: 2 June 2023</p>

  <h2>Information We Collect</h2>

  <p>
    Information we collect falls into one of two categories: “voluntarily provided” information and
    “automatically collected” information.
  </p>

  <p>
    “Voluntarily provided” information refers to any information you knowingly and actively provide
    us when using or participating in any of our services and promotions.
  </p>

  <p>
    “Automatically collected” information refers to any information automatically sent by your
    devices in the course of accessing our products and services.
  </p>

  <h2>Personal Information</h2>

  <p>
    We may ask for personal information — for example, when you subscribe to our website or when you
    contact us — which may include one or more of the following:
  </p>

  <ul class="list">
    <li>Name</li>
    <li>Business Email</li>
  </ul>

  <h3>Transaction Data</h3>
  <p>
    Transaction data refers to data that accumulates over the normal course of operation on our
    platform. This may include transaction records, stored files, user profiles, analytics data and
    other metrics, as well as other types of information, created or generated, as users interact
    with our services.
  </p>

  <h2>Legitimate Reasons for Processing Your Personal Information</h2>

  <p>
    We only collect and use your personal information when we have a legitimate reason for doing so.
    In which instance, we only collect personal information that is reasonably necessary to provide
    our services to you.
  </p>

  <h2>Collection and Use of Information</h2>

  <p>
    <u>
      We may collect personal information from you when you do any of the following on our website:
    </u>
  </p>

  <ul class="list">
    <li>Register for an account</li>
    <li>Purchase a subscription</li>
    <li>Sign up to receive updates from us via email or social media channels</li>
    <li>Use a mobile device or web browser to access our content</li>
    <li>Contact us via email, social media, or on any similar technologies</li>
  </ul>

  <p>
    <u>
      We may collect, hold, use, and disclose information for the following purposes, and personal
      information will not be further processed in a manner that is incompatible with these
      purposes:
    </u>
  </p>

  <ul class="list">
    <li>to provide you with our platform's core features and services</li>
    <li>
      to enable you to access and use our website, associated applications, and associated social
      media platforms
    </li>
    <li>for internal record keeping and administrative purposes</li>
  </ul>

  <p>
    We may combine voluntarily provided and automatically collected personal information with
    general information or research data we receive from other trusted sources. For example, if you
    provide us with your location, we may combine this with general information about currency and
    language to provide you with an enhanced experience of our site and service.
  </p>

  <h2>Security of Your Personal Information</h2>

  <p>
    When we collect and process personal information, and while we retain this information, we will
    protect it within commercially acceptable means to prevent loss and theft, as well as
    unauthorized access, disclosure, copying, use, or modification.
  </p>

  <p>
    Although we will do our best to protect the personal information you provide to us, we advise
    that no method of electronic transmission or storage is 100% secure, and no one can guarantee
    absolute data security.
  </p>

  <p>
    You are responsible for selecting any password and its overall security strength, ensuring the
    security of your own information within the bounds of our services. For example, you should
    ensure any passwords associated with accessing your personal information and accounts are secure
    and confidential. You should also use a unique password for each website or subscription
    associated with your username or email address.
  </p>

  <h2>How Long We Keep Your Personal Information</h2>

  <p>
    We keep your personal information only for as long as we need to. This time period may depend on
    what we are using your information for, in accordance with this privacy policy. For example, if
    you have provided us with personal information as part of creating an account with us, we may
    retain this information for the duration your account exists on our system. If your personal
    information is no longer required for this purpose, we will delete it or make it anonymous by
    removing all details that identify you.
  </p>

  <p>
    However, if necessary, we may retain your personal information for our compliance with a legal,
    accounting, or reporting obligation or for archiving purposes in the public interest,
    scientific, or historical research purposes or statistical purposes.
  </p>

  <h2>Children`s Privacy</h2>

  <p>
    We do not aim any of our products or services directly at children under the age of 13, and we
    do not knowingly collect personal information about children under 13.
  </p>

  <h2>Your Rights and Controlling Your Personal Information</h2>

  <p>
    Your choice: By providing personal information to us, you understand we will collect, hold, use,
    and disclose your personal information in accordance with this privacy policy. You do not have
    to provide personal information to us, however, if you do not, it may affect your use of our
    website or the products and/or services offered on or through it.
  </p>

  <p>
    Information from third parties: If we receive personal information about you from a third party,
    we will protect it as set out in this privacy policy. If you are a third party providing
    personal information about somebody else, you represent and warrant that you have such person’s
    consent to provide the personal information to us.
  </p>

  <p>
    Marketing permission: If you have previously agreed to us using your personal information for
    direct marketing purposes, you may change your mind at any time by contacting us using the
    details below.
  </p>

  <p>Access: You may request details of the personal information that we hold about you.</p>

  <p>
    Correction: If you believe that any information we hold about you is inaccurate, out of date,
    incomplete, irrelevant, or misleading, please contact us using the details provided in this
    privacy policy. We will take reasonable steps to correct any information found to be inaccurate,
    incomplete, misleading, or out of date.
  </p>

  <p>
    Non-discrimination: We will not discriminate against you for exercising any of your rights over
    your personal information. Unless your personal information is required to provide you with a
    particular service or offer (for example processing transaction data), we will not deny you
    goods or services and/or charge you different prices or rates for goods or services, including
    through granting discounts or other benefits, or imposing penalties, or provide you with a
    different level or quality of goods or services.
  </p>

  <p>
    Notification of data breaches: We will comply with laws applicable to us in respect of any data
    breach.
  </p>

  <p>
    Complaints: If you believe that we have breached a relevant data protection law and wish to make
    a complaint, please contact us using the details below and provide us with full details of the
    alleged breach. We will promptly investigate your complaint and respond to you, in writing,
    setting out the outcome of our investigation and the steps we will take to deal with your
    complaint. You also have the right to contact a regulatory body or data protection authority in
    relation to your complaint.
  </p>

  <p>
    Unsubscribe: To unsubscribe from our email database or opt-out of communications (including
    marketing communications), please contact us using the details provided in this privacy policy,
    or opt-out using the opt-out facilities provided in the communication. We may need to request
    specific information from you to help us confirm your identity.
  </p>

  <h2>Use of Cookies</h2>

  <p>
    We use “cookies” to collect information about you and your activity across our site. A cookie is
    a small piece of data that our website stores on your computer, and accesses each time you
    visit, so we can understand how you use our site. This helps us serve you content based on
    preferences you have specified.
  </p>

  <p>Please refer to our Cookie Policy for more information.</p>

  <h2>Business Transfers</h2>

  <p>
    If we or our assets are acquired, or in the unlikely event that we go out of business or enter
    bankruptcy, we would include data, including your personal information, among the assets
    transferred to any parties who acquire us. You acknowledge that such transfers may occur, and
    that any parties who acquire us may, to the extent permitted by applicable law, continue to use
    your personal information according to this policy, which they will be required to assume as it
    is the basis for any ownership or use rights we have over such information.
  </p>

  <h2>Limits of Our Policy</h2>

  <p>
    Our website may link to external sites that are not operated by us. Please be aware that we have
    no control over the content and policies of those sites, and cannot accept responsibility or
    liability for their respective privacy practices.
  </p>

  <h2>Changes to This Policy</h2>

  <p>
    At our discretion, we may change our privacy policy to reflect updates to our business
    processes, current acceptable practices, or legislative or regulatory changes. If we decide to
    change this privacy policy, we will post the changes here at the same link by which you are
    accessing this privacy policy.
  </p>

  <p>
    If the changes are significant, or if required by applicable law, we will contact you (based on
    your selected preferences for communications from us) and all our registered users with the new
    details and links to the updated or changed policy.
  </p>

  <p>
    If required by law, we will get your permission or give you the opportunity to opt in to or opt
    out of, as applicable, any new uses of your personal information.
  </p>

  <h2>Additional Disclosures for California Compliance (US)</h2>

  <p>
    Under California Civil Code Section 1798.83, if you live in California and your business
    relationship with us is mainly for personal, family, or household purposes, you may ask us about
    the information we release to other organizations for their marketing purposes.
  </p>

  <p>
    To make such a request, please contact us using the details provided in this privacy policy with
    “Request for California privacy information” in the subject line. You may make this type of
    request once every calendar year. We will email you a list of categories of personal information
    we revealed to other organisations for their marketing purposes in the last calendar year, along
    with their names and addresses. Not all personal information shared in this way is covered by
    Section 1798.83 of the California Civil Code.
  </p>

  <h2>Do Not Track</h2>

  <p>
    Some browsers have a “Do Not Track” feature that lets you tell websites that you do not want to
    have your online activities tracked. At this time, we do not respond to browser “Do Not Track”
    signals.
  </p>

  <p>
    We adhere to the standards outlined in this privacy policy, ensuring we collect and process
    personal information lawfully, fairly, transparently, and with legitimate, legal reasons for
    doing so.
  </p>

  <h2>Cookies and Pixels</h2>

  <p>
    At all times, you may decline cookies from our site if your browser permits. Most browsers allow
    you to activate settings on your browser to refuse the setting of all or some cookies.
    Accordingly, your ability to limit cookies is based only on your browser’s capabilities. Please
    refer to the Cookies section of this privacy policy for more information.
  </p>

  <h2>California Notice of Collection</h2>

  <p>
    In the past 12 months, we have collected the following categories of personal information
    enumerated in the California Consumer Privacy Act:
  </p>

  <ul class="list">
    <li>
      Identifiers, such as name, email address, phone number account name, IP address, and an ID or
      number assigned to your account.
    </li>
    <li>Commercial information, such as products or services history and purchases.</li>
  </ul>

  <p>
    For more information on information we collect, including the sources we receive information
    from, review the “Information We Collect” section. We collect and use these categories of
    personal information for the business purposes described in the “Collection and Use of
    Information” section, including to provide and manage our Service.
  </p>

  <h2>Right to Know and Delete</h2>

  <p>
    If you are a California resident, you have rights to delete your personal information we
    collected and know certain information about our data practices in the preceding 12 months. In
    particular, you have the right to request the following from us:
  </p>

  <ul class="list">
    <li>The categories of personal information we have collected about you;</li>
    <li>The categories of sources from which the personal information was collected;</li>
    <li>
      The categories of personal information about you we disclosed for a business purpose or sold;
    </li>
    <li>
      The categories of third parties to whom the personal information was disclosed for a business
      purpose or sold;
    </li>
    <li>The business or commercial purpose for collecting or selling the personal information;</li>
    <li>The specific pieces of personal information we have collected about you.</li>
  </ul>

  <p>
    To exercise any of these rights, please contact us using the details provided in this privacy
    policy.
  </p>

  <h2>Shine the Light</h2>

  <p>
    If you are a California resident, in addition to the rights discussed above, you have the right
    to request information from us regarding the manner in which we share certain personal
    information as defined by California’s “Shine the Light” with third parties and affiliates for
    their own direct marketing purposes.
  </p>

  <p>
    To receive this information, send us a request using the contact details provided in this
    privacy policy. Requests must include “California Privacy Rights Request” in the first line of
    the description and include your name, street address, city, state, and ZIP code.
  </p>

  <h2>Contact Us</h2>

  <p>
    For any questions or concerns regarding your privacy, you may contact us using the following
    details:
  </p>

  <ul class="list">
    <li>Melissa Steinrock</li>
    <li><a href="mailto:info@scripta.llc">info@scripta.llc</a></li>
  </ul>
</div>
