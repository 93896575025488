import { Injectable } from '@angular/core';

import { PagedList } from '../../models/paged-list';
import { PaginationOptions } from '../../models/pagination-options';

import { PagedListDto } from './dto/paged-list-dto';
import { IMapperFromDto } from './mappers';

/** Paged list mapper. */
@Injectable({
  providedIn: 'root',
})
export class PagedListMapper {
  /**
   * Map PagedListDto to PagedList.
   *
   * @param listDto List with pagination received from server.
   * @param itemsMapper Mapper for list items.
   * @param pagination Optional pagination info.
   */
  public fromDto<TDto, TDomain>(
    listDto: PagedListDto<TDto>,
    itemsMapper: IMapperFromDto<TDto, TDomain> | ((dto: TDto) => TDomain),
    pagination?: PaginationOptions,
  ): PagedList<TDomain> {
    return new PagedList({
      pagination: new PaginationOptions({
        page: pagination?.page ?? 0,
        pageSize: pagination?.pageSize ?? listDto.count,
        totalCount: listDto.count,
        haveNext: Boolean(listDto?.next),
      }),
      items: listDto.results.map(dto => {
        if (typeof itemsMapper === 'function') {
          return itemsMapper(dto);
        }
        return itemsMapper.fromDto(dto);
      }),
    });
  }
}
