<div *ngIf="loading" class="progress-bar-container">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div class="table-holder" #resizableTable>
  <table
    mat-table
    cdkDropList
    cdkDropListLockAxis="x"
    cdkDropListOrientation="horizontal"
    [trackBy]="trackBy"
    [dataSource]="dataSource"
    (cdkDropListDropped)="dropListDropped($event)"
  >
    <ng-container
      *ngFor="let col of columns; trackBy: trackByName; let first = first"
      [matColumnDef]="col.name"
      [sticky]="stickyFirstColumn && first">
      <ng-container *ngLet="getColumnByName(col.name) as column">
        <!-- HEADER FOR THE CELL -->
        <th
          *matHeaderCellDef
          mat-header-cell
          cdkDrag
          [class.default-header]="!column?.header?.template"
          [class.fixed-cell]="stickyFirstColumn && first"
          [attr.data-colName]="col.name"
        >
          <scriptac-resizable [columnWidth]="col.width" (updatedWidth)="updateColumnWidth($event, col)">
            <ng-container
              *ngIf="column; else defaultCellBlock"
              [ngTemplateOutletContext]="{ $implicit: col, headerText: column.headerText }"
              [ngTemplateOutlet]="column.header?.template || defaultHeaderTemplate"
            >
            </ng-container>
            <!-- If not found column template -->
            <ng-template #defaultCellBlock>
              <ng-container
                [ngTemplateOutletContext]="{ $implicit: col }"
                [ngTemplateOutlet]="defaultHeaderTemplate"
              >
              </ng-container>
            </ng-template>
            <mat-icon *ngIf="!(stickyFirstColumn && first)" cdkDragHandle class="header-menu-icon">reorder</mat-icon>
          </scriptac-resizable>
        </th>

        <!-- BODY FOR THE CELL -->
        <td
          *matCellDef="let element; let index = index"
          mat-cell
          [ngClass]="column ? getTableCellClass(column, element, col.name) : ''"
          [class.table-cell-left-align]="alignTextLeft"
          mat-cell
          scriptacAutoResizeColumn 
          [class.fixed-cell]="stickyFirstColumn && first"
          [tableElement]="resizableTable"
          [colName]="col.name"
          [columnWidth]="col.width || 100"
        >
          <!-- If found column template -->
          <ng-container
            *ngIf="column; else elseCellBlock"
            [ngTemplateOutletContext]="{ $implicit: element, col: col.name, index: index  }"
            [ngTemplateOutlet]="column.cell?.template || defaultCellTemplate"
          >
          </ng-container>
          <!-- If not found column template -->
          <ng-template #elseCellBlock>
            <ng-container
              [ngTemplateOutletContext]="{ $implicit: element, col: col.name, index: index }"
              [ngTemplateOutlet]="defaultCellTemplate"
            >
            </ng-container>
          </ng-template>
        </td>
      </ng-container>
    </ng-container>

    <!-- FIXED COLUMN FOR ACTION BUTTONS -->
    <ng-container [matColumnDef]="fixedColumnName" [stickyEnd]="true">
      <!-- HEADER FOR THE CELL -->
      <th *matHeaderCellDef mat-header-cell class="fixed-header-cell"></th>

      <!-- BODY FOR THE CELL -->
      <td *matCellDef="let element" mat-cell class="fixed-cell">
        <ng-container
          *ngIf="fixedCellTemplate; else defaultFixedCellTemplate"
          [ngTemplateOutlet]="fixedCellTemplate"
          [ngTemplateOutletContext]="{ $implicit: element }"
        >
        </ng-container>
        <ng-template #defaultFixedCellTemplate></ng-template>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="columnNames; sticky: true" mat-header-row [class.hidden]="dataSource.data.length === 0"></tr>

    <tr
      *matRowDef="let row; columns: columnNames"
      mat-row
      [class.clickable-row]="clickableRows"
      (click)="onItemClick(row)"
    >
    </tr>

    <!-- Empty Table message -->
    <ng-container matColumnDef="noRecords">
      <td *matFooterCellDef mat-cell class="empty-table-message" [attr.colspan]="columns.length">
        {{ loading ? 'Loading...' : emptyMessage }}
      </td>
    </ng-container>
    <tr
      *matFooterRowDef="['noRecords']"
      mat-footer-row
      [class.hidden]="dataSource.data.length > 0"
    ></tr>
  </table>

  <!-- If not found cell template -->
  <ng-template #defaultCellTemplate let-row let-col="col">
    <div [title]="row[col] ?? ''">
      {{ getValue(row, col) ?? '' }}
    </div>
  </ng-template>

  <!-- If not found header template -->
  <ng-template #defaultHeaderTemplate let-col let-headerText="headerText">
    <span>{{ getTableHeaderText(col, headerText) }}</span>
  </ng-template>
</div>

<mat-paginator
  *ngIf="pagination"
  color="primary"
  [pageSize]="pagination.pageSize"
  [pageIndex]="pagination.page"
  [length]="pagination.totalCount"
  [hidePageSize]="true"
  (page)="paginationChanged($event)"
>
</mat-paginator>

