<div class="progress-bar-container">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</div>

<!-- This wrapper is needed to keep loader position on top -->
<div class="table-wrapper">
  <table
    mat-table
    class="full-width"
    [dataSource]="dataSource"
  >
    <ng-container *ngFor="let col of columns; trackBy: trackByName" [matColumnDef]="col.name">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container
          *ngIf="headerCellTemplate && col.name !== 'title'; else defaultHeaderCellTemplate"
          [ngTemplateOutlet]="headerCellTemplate"
          [ngTemplateOutletContext]="{ $implicit: col, data: col.data }"
        >
        </ng-container>
        <ng-template #defaultHeaderCellTemplate>{{ col.headerText }}</ng-template>
      </th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="col.name !== titleColumnName; else titleCell">
          <scriptaw-user-tier-lock [tier]="getCellTier(col, element)">
            <scriptaw-matrix-dynamic-cell
              [matrixValue]="element[col.name]"
            ></scriptaw-matrix-dynamic-cell>
            <span *ngIf="element[col.name]?.note">({{element[col.name].note}})</span>
          </scriptaw-user-tier-lock>
        </ng-container>
        <ng-template #titleCell>{{ element[col.name] }}</ng-template>
      </td>
    </ng-container>

    <!-- Header cells -->
    <tr *matHeaderRowDef="columnNames" mat-header-row [class.hidden]="dataSource.data.length === 0"></tr>

    <tr *matRowDef="let row; columns: columnNames" mat-row></tr>

    <!-- Empty Table message -->
    <ng-container matColumnDef="noRecords">
      <td *matFooterCellDef mat-cell class="empty-table-message" [attr.colspan]="columns.length">
        <span *ngIf="!loading; else loadingRow">{{ emptyMessage }}</span>
        <ng-template #loadingRow>Loading data...</ng-template>
      </td>
    </ng-container>
    <tr
      *matFooterRowDef="['noRecords']"
      mat-footer-row
      [class.hidden]="dataSource.data.length > 0"
    ></tr>
  </table>
</div>
