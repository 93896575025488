import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EditReportingTemplate } from '@scriptac/common/core/models/edit-reporting-template';
import { ReportingTemplate } from '@scriptac/common/core/models/reporting-template';
import { State } from '@scriptac/common/core/models/state';
import { catchValidationData } from '@scriptac/common/core/rxjs/catch-validation-data';
import { FileStorageService } from '@scriptac/common/core/services/file-storage.service';
import { LocationService } from '@scriptac/common/core/services/location.service';
import { ReportingDataService } from '@scriptac/common/core/services/reporting-data.service';
import { createTrackByPropertyFunction } from '@scriptac/common/core/utils/track-by-property';
import { first } from 'rxjs/operators';

/**
 * Reporting template edit data.
 */
export interface ReportingTemplateEditData {
  /** Reporting template. */
  readonly template: ReportingTemplate | null;
}

/**
 * Reporting template edit dialog.
 */
@Component({
  selector: 'scriptaw-reporting-template-edit-dialog',
  templateUrl: './reporting-template-edit-dialog.component.html',
  styleUrls: ['./reporting-template-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportingTemplateEditDialogComponent {
  /** Is edit mode. */
  public readonly isEdit = Boolean(this.data.template);

  /** Form. */
  public readonly form = this.fb.groupTyped<EditReportingTemplate>({
    templateId: [this.data.template?.id ?? null],
    jurisdictionId: [this.data.template?.state.id ?? null as unknown as number, Validators.required],
    templateUrl: [this.data.template?.templateUrl ?? '', Validators.required],
  });

  /** States. */
  public readonly states$ = this.locationService.states$;

  /** Track by function. */
  public readonly trackById = createTrackByPropertyFunction<State>('id');

  public constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: ReportingTemplateEditData,
    private readonly dialogRef: MatDialogRef<ReportingTemplateEditDialogComponent>,
    private readonly fb: FormBuilder,
    private readonly locationService: LocationService,
    private readonly fileStorageService: FileStorageService,
    private readonly reportingService: ReportingDataService,
  ) { }

  /**
   * Handle form submit.
   */
  public submitForm(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.reportingService.saveReportingTemplate(this.form.value).pipe(
      first(),
      catchValidationData(this.form),
    ).subscribe(template => this.dialogRef.close(template));
  }

  /**
   * Save opened file.
   * @param event Input event.
   */
  public uploadFile(event: Event): void {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];

    if (!file) {
      return;
    }

    this.fileStorageService.uploadFile('reporting_templates', file, file.name).pipe(
      first(),
    ).subscribe(templateUrl => {
      this.form.patchValue({ templateUrl });
    });
  }

  /** Close dialog. */
  public onClose(): void {
    this.dialogRef.close();
  }
}
