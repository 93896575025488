<form [formGroup]="filtersForm">
  <scriptac-label class="full-width" labelText="Search">
    <input formControlName="search" placeholder="Search"  />
  </scriptac-label>
</form>

<div class="user-list">
  <mat-progress-bar *ngIf="listManager.listLoading$ | async" mode="indeterminate">
  </mat-progress-bar>

  <mat-selection-list
    (scroll)="onScroll($event)"
    [formControl]="listControl"
    [multiple]="data.multiple"
  >
    <ng-container *ngIf="users$ | async as users else loading">
      <ng-container *ngIf="users?.length else emptyMsg">
        <mat-list-option *ngFor="let user of users" role="listitem" [value]="user">
          {{ user.firstName }} {{ user.lastName }}
        </mat-list-option>
      </ng-container>
    </ng-container>

    <ng-template #emptyMsg>
      <mat-list-item>No users found</mat-list-item>
    </ng-template>
  
    <ng-template #loading>
      <mat-list-item>Loading...</mat-list-item>
    </ng-template>
  </mat-selection-list>
</div>

<p *ngIf="data.multiple; else altText">Users selected: {{ listControl.value.length }}</p>
<ng-template #altText>
  <p>Option selected: {{ listControl.value[0]?.firstName }} {{ listControl.value[0]?.lastName }}</p>
</ng-template>

<div class="controls full-width">
  <button (click)="onClose()" type="button" mat-flat-button color="warn">Cancel</button>
  <button (click)="onConfirm()" type="button" mat-flat-button color="accent">Confirm</button>
</div>
