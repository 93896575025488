import { NullObject } from './null-object';
import { Region, NullRegion } from './region';

/** City model. */
export class City {
  /** City id. */
  public readonly id: number;
  /** City name. */
  public readonly name: string;
  /** City timezone. */
  public readonly timezone?: string;
  /** Region. */
  public readonly region?: number;
  /** Region data. */
  public readonly regionData: Region;

  /**
   * @constructor
   * @param data Data to fill class fields.
   */
  public constructor(data: CityConstructorData) {
    this.id = data.id;
    this.name = data.name;
    this.timezone = data.timezone;
    this.region = data.region;
    this.regionData = data.regionData;
  }
}

/** Nullable city data. */
export class NullCity extends City implements NullObject {
  /** Is null check. */
  public readonly isNull: boolean = true;

  public constructor(
    data: CityConstructorData = {
      id: -1,
      name: '',
      regionData: new NullRegion(),
    },
  ) {
    super(data);
  }
}

export type CityConstructorData = City;
