import { enumToArray } from '../utils/enum-to-array';
import { MatrixValueEditControlName } from '../models/matrix-value';

/** Type of matrix fields. */
export enum MatrixFieldType {
  /** NAUPA2 code. */
  Naupa2Code = 'NAUPA2_CODE',
  /** Text. */
  Text = 'TEXT',
  /** Bool. */
  Bool = 'BOOL',
  /** Date. */
  Date = 'DATE',
  /** Single choice. */
  SingleChoice = 'SINGLE_CHOICE',
  /** Multi choice. */
  MultiChoice = 'MULTI_CHOICE',
  /** Dormancy. */
  Dormancy = 'DORMANCY',
  /** Dormancy. */
  AlternativeDormancy = 'ALTERNATIVE_DORMANCY',
  /** Holder type. */
  HolderType = 'HOLDER_TYPE',
}

export namespace MatrixFieldType {
  const TO_CONTROL_NAME: Readonly<Record<MatrixFieldType, MatrixValueEditControlName>> = {
    [MatrixFieldType.Naupa2Code]: 'naupa2CodeValues',
    [MatrixFieldType.Text]: 'textValue',
    [MatrixFieldType.Bool]: 'boolValue',
    [MatrixFieldType.Date]: 'dateValue',
    [MatrixFieldType.SingleChoice]: 'singleChoiceValue',
    [MatrixFieldType.MultiChoice]: 'multipleChoicesValues',
    [MatrixFieldType.Dormancy]: 'value',
    [MatrixFieldType.AlternativeDormancy]: 'alternativeValue',
    [MatrixFieldType.HolderType]: 'holderTypeValues',
  };

  /**
   * Convert a certain enum value to control name.
   * @param value Value of enum.
   */
  // tslint:disable-next-line: completed-docs
  export function toControlName(value: MatrixFieldType): MatrixValueEditControlName {
    const readable = TO_CONTROL_NAME[value];
    if (readable == null) {
      console.warn(`Unknown type: ${value}`);
    }
    return readable;
  }

  const TITLE_MAP: Record<MatrixFieldType, string> = {
    [MatrixFieldType.Naupa2Code]: 'NAUPA II Code',
    [MatrixFieldType.Text]: 'Text',
    [MatrixFieldType.Bool]: 'Bool',
    [MatrixFieldType.Date]: 'Date',
    [MatrixFieldType.SingleChoice]: 'Single Choice',
    [MatrixFieldType.MultiChoice]: 'Multi Choice',
    [MatrixFieldType.Dormancy]: 'Dormancy',
    [MatrixFieldType.AlternativeDormancy]: 'Alternative Dormancy',
    [MatrixFieldType.HolderType]: 'Holder Type',
  };

  /**
   * Convert a certain enum value to readable title.
   * @param value Value of enum.
   */
  // tslint:disable-next-line: completed-docs
  export function toReadable(value: MatrixFieldType): string {
    const readable = TITLE_MAP[value];
    if (readable == null) {
      console.warn(`Unknown type: ${value}`);
    }
    return readable;
  }

  /**
   * Get list of all enum items.
   */
  // tslint:disable-next-line: completed-docs
  export function toArray(): MatrixFieldType[] {
    return enumToArray(MatrixFieldType);
  }

  /**
   * Track by function.
   * @param _index Index of item.
   * @param item Item.
   */
  // tslint:disable-next-line:completed-docs
  export function trackBy(_index: number, item: MatrixFieldType): MatrixFieldType {
    return item;
  }
}
