
import { Pipe, PipeTransform } from '@angular/core';
import {
  DomSanitizer,
  SafeStyle,
  SafeResourceUrl,
  SafeScript,
  SafeHtml,
  SafeUrl,
} from '@angular/platform-browser';

export type SafePipeType
  = 'html'
  | 'style'
  | 'script'
  | 'url'
  | 'resourceUrl';

/**
 * Safe pipe.
 */
@Pipe({
  name: 'safe',
  pure: true,
})
export class SafePipe implements PipeTransform {
  public constructor(private readonly sanitizer: DomSanitizer) { }

  /** @inheritdoc */
  public transform(value: string | null, type: SafePipeType): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    if (value === null) {
      return '';
    }
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`SafePipe unable to bypass security for invalid type: ${type}`);
    }
  }
}
