import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { createTrackByPropertyFunction } from '@scriptac/common/core/utils/track-by-property';
import { Revision } from '@scriptac/common/core/models/revision';
import { MatrixCellValuePipe } from '@scriptac/common/shared/pipes/matrix-cell-value.pipe';
import { MatrixValue } from '@scriptac/common/core/models/matrix-value';
import { MatTable } from '@angular/material/table';
import { Change, DiffService } from '@scriptac/common/core/services/diff.service';
import { RevisionStatus } from '@scriptac/common/core/enums/revision-status';
import { HTML_TAG_REGEX } from '@scriptac/common/core/utils/constants';
import { CurrentUserService } from '@scriptac/common/core/services/current-user.service';
import { first } from 'rxjs/operators';
import { UserProfile } from '@scriptac/common/core/models/user-profile';
import { checkUserAccess } from '@scriptac/common/core/utils/check-user-access';
import { filterNull } from '@scriptac/common/core/rxjs/filter-null';
import { AccessTierLevel } from '@scriptac/common/core/enums/access-tier-level';

import { ColumnInfo, RowInfo } from '../vertical-matrix/vertical-matrix.component';

interface ExtendedRowInfo extends RowInfo {
  /** Id. */
  readonly id: number;
}

interface MatrixRowValue {
  /** Field id. */
  readonly id: number;
  /** Title. */
  readonly title: string;
  /** Column values. */
  readonly columnValues: Array<MatrixValue | null>;
}

/** Component with matrix show only revision rows with changes. */
@Component({
  selector: 'scriptaw-revision-matrix-with-changes',
  templateUrl: './revision-matrix-with-changes.component.html',
  styleUrls: ['./revision-matrix-with-changes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MatrixCellValuePipe],
})
export class RevisionMatrixWithChangesComponent implements AfterViewInit {
  /** Message that displays when items not found. */
  @Input()
  public emptyMessage = 'No items found.';

  /** Matrix API data. */
  @Input()
  public set matrixData(value: Revision[] | null) {
    if (value) {
      this.columns = this.getMatrixColumns(value);
      const rowsInfo = this.getMatrixRowsInfo(value);
      this.transposedData = this.transposeMatrixData(value, rowsInfo);
    }
  }

  /** Table element. */
  @ViewChild(MatTable, { read: ElementRef })
  public readonly table!: ElementRef;

  /** Lock icon. */
  @ViewChild('lockIcon', { read: ElementRef })
  public readonly lockIcon!: ElementRef;

  /** Track list by elements` name. */
  public readonly trackByName = createTrackByPropertyFunction<ColumnInfo<Revision>>('name');

  /** Columns list. */
  public columns: ColumnInfo<Revision>[] = [];

  /** Transposed matrix data. */
  public transposedData: MatrixRowValue[] = [];

  /** Return columns names. */
  public get columnNames(): string[] {
    return this.columns.map(c => c.name);
  }

  // Be careful with changing 'addCssClass' and 'deleteCssClass' values.
  // We have some global styles attached to them.
  private readonly addCssClass = 'table-diff_add';
  private readonly deleteCssClass = 'table-diff_delete';

  public constructor(
    private readonly renderer: Renderer2,
    private readonly matrixValuePipe: MatrixCellValuePipe,
    private readonly diffService: DiffService,
    private readonly userService: CurrentUserService,
  ) { }

  /** @inheritDoc */
  public ngAfterViewInit(): void {
    this.userService.currentUser$.pipe(
      filterNull(),
      first(),
    ).subscribe(user => {
      this.createTableContent(this.transposedData, user);
    });
  }

  private getMatrixColumns(matrixDataValue: Revision[]): ColumnInfo<Revision>[] {
    const columnWithTitles: ColumnInfo<Revision> = {
      name: 'title',
      headerText: '',
      data: null,
    };

    const columnsFromData = matrixDataValue.map(revision => ({
      name: revision.id.toString(),
      headerText: RevisionStatus.toReadable(revision.status),
      data: revision,
    }));

    return [columnWithTitles, ...columnsFromData];
  }

  private getMatrixRowsInfo(matrixDataValue: Revision[]): ExtendedRowInfo[] {
    return matrixDataValue.length ?
      matrixDataValue[0].values.map(value => ({
        fieldName: value.field.name,
        fieldType: value.field.fieldType,
        id: value.fieldId,
      })) : [];
  }

  private transposeMatrixData(matrixData: Revision[], rowsInfo: ExtendedRowInfo[]): MatrixRowValue[] {
    return rowsInfo.map(row => ({
      id: row.id,
      title: row.fieldName,
      columnValues: matrixData.map(col => col.values.find(
        value => value.fieldId === row.id,
      ) ?? null),
    }));
  }

  private createTableContent(matrixData: MatrixRowValue[], user: UserProfile): void {
    for (const row of matrixData) {
      const tr = this.renderer.createElement('tr') as HTMLTableRowElement;

      const titleCell = this.createTitleCell(row.title);
      tr.appendChild(titleCell);

      const referenceValue = row.columnValues[0];
      const columnsToCompare = [row.columnValues[1], ...row.columnValues.slice(1)];

      for (const [index, column] of columnsToCompare.entries()) {
        const diff = this.getRowDiff(column, referenceValue);

        // Only removed parts should be highlighted.
        let changes = diff.filter(elem => elem.removed || !elem.removed && !elem.added);
        // Only added parts should be highlighted in first column.
        if (index === 0) {
          changes = diff.filter(elem => elem.added || !elem.added && !elem.removed);
        }

        const tier = column?.field.tier ?? AccessTierLevel.Tier1;

        let cell: HTMLTableCellElement;
        if (checkUserAccess(user, tier)) {
          cell = this.createChangesCell(changes);
        } else {
          cell = this.createLockedCell(tier);
        }
        tr.appendChild(cell);
      }

      this.table.nativeElement.appendChild(tr);
    }
  }

  private getRowDiff(left: MatrixValue | null, right: MatrixValue | null): Change[] {
    const leftText = this.getCellTextContent(left).replace(HTML_TAG_REGEX, '');
    const rightText = this.getCellTextContent(right).replace(HTML_TAG_REGEX, '');

    return this.diffService.getDiffWords(leftText, rightText);
  }

  private getCellTextContent(value: MatrixValue | null): string {
    if (!value) {
      return '';
    }

    let currentText = this.matrixValuePipe.transform(value);
    if (value.note) {
      currentText += ` (${value.note})`;
    }

    return currentText;
  }

  private createChangesCell(diff: Change[]): HTMLTableCellElement {
    const td = this.renderer.createElement('td') as HTMLTableCellElement;

    for (const part of diff) {
      const span = this.renderer.createElement('span') as HTMLSpanElement;

      if (part.removed) {
        span.classList.add(this.deleteCssClass);
      } else if (part.added) {
        span.classList.add(this.addCssClass);
      }

      span.appendChild(this.renderer.createText(part.value));
      td.appendChild(span);
    }

    this.renderer.addClass(td, 'data-cell');
    this.renderer.addClass(td, 'mat-cell');
    return td;
  }

  private createTitleCell(text: string): HTMLTableCellElement {
    const td = this.renderer.createElement('td') as HTMLTableCellElement;
    this.renderer.addClass(td, 'title-cell');
    this.renderer.addClass(td, 'mat-cell');

    const span = this.renderer.createElement('span');
    span.appendChild(this.renderer.createText(text));
    td.appendChild(span);

    return td;
  }

  private createLockedCell(tier: AccessTierLevel): HTMLTableCellElement {
    const cell = this.renderer.createElement('td') as HTMLTableCellElement;
    const lockContainer = this.renderer.createElement('div') as HTMLDivElement;

    this.renderer.addClass(cell, 'data-cell');
    this.renderer.addClass(cell, 'mat-cell');
    this.renderer.addClass(lockContainer, 'lock-wrapper');

    lockContainer.appendChild(this.renderer.createText(`Tier ${tier} Access`));
    // Timeout is necessary to correctly render icon element.
    setTimeout(() => {
      lockContainer.appendChild(this.lockIcon.nativeElement.cloneNode(true));
    }, 0);
    cell.appendChild(lockContainer);
    return cell;
  }
}
