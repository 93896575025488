<nav>
  <a [routerLink]="routePaths.welcome">
    <img src="assets/scripta-light.svg" alt="logo">
  </a>
  <div class="links-row">
    <a
      *ngFor="let link of navLinks"
      class="nav-link"
      routerLinkActive="active"
      [title]="link.title"
      [routerLink]="link.route"
    >
      <mat-icon [svgIcon]="link.icon"></mat-icon>
      <span class="nav-title">{{ link.title }}</span>
    </a>
  </div>
</nav>
<ng-content></ng-content>
<img class="background" [src]="backgroundImg">