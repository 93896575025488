<div class="progress-bar-container">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</div>

<div class="table-wrapper" #resizableTable>
  <table
    mat-table
    cdkDropList
    cdkDropListLockAxis="x"
    cdkDropListOrientation="horizontal"
    class="full-width"
    [dataSource]="dataSource"
    [class.with-fixed-column]="showFixedColumn"
    (cdkDropListDropped)="dropListDropped($event)"
  >

    <!-- STATE COLUMN -->
    <ng-container [matColumnDef]="stateColumnName" [sticky]="true">
      <!-- HEADER FOR THE CELL -->
      <th *matHeaderCellDef mat-header-cell class="fixed-header-cell">
        <span>
          Jurisdiction
        </span>
      </th>

      <!-- BODY FOR THE CELL -->
      <ng-container *ngIf="customStateCellTemplate else regularStateCell">
        <ng-container *matCellDef="let element" mat-cell [ngTemplateOutlet]="customStateCellTemplate" [ngTemplateOutletContext]="{ $implicit: element }">
        </ng-container>
      </ng-container>
      <ng-template #regularStateCell>
        <td *matCellDef="let element" mat-cell class="fixed-cell">
          <ng-container
            *ngIf="stateCellTemplate; else defaultStateCellTemplate"
            [ngTemplateOutlet]="stateCellTemplate"
            [ngTemplateOutletContext]="{ $implicit: element }"
          >
          </ng-container>
          <ng-template #defaultStateCellTemplate></ng-template>
        </td>
      </ng-template>
    </ng-container>

    <ng-container *ngFor="let col of columns; let i = index; trackBy: trackByName" [matColumnDef]="col.name">
      <th
        *matHeaderCellDef
        mat-header-cell
        cdkDrag
        [cdkDragDisabled]="disableColumnsReorder"
        [attr.data-colName]="col.name"
      >
        <scriptac-resizable [columnWidth]="col.width" (updatedWidth)="updateColumnWidth($event, col)">
          <mat-icon *ngIf="!disableColumnsReorder" cdkDragHandle class="header-menu-icon">reorder</mat-icon>
          <span>{{ col.headerText }}</span>
        </scriptac-resizable>
      </th>
      <td
        *matCellDef="let element"
        mat-cell
        scriptacAutoResizeColumn
        [tableElement]="resizableTable"
        [colName]="col.name"
        [columnWidth]="col.width || 100"
      >
        <scriptaw-user-tier-lock [tier]="getCellTier(element, col.name)">
          <ng-container *ngLet="getColumnTemplateByName(col.name) as customTemplate">
            <ng-container
              *ngIf="customTemplate else defaultCellBlock"
              [ngTemplateOutletContext]="{ $implicit: element }"
              [ngTemplateOutlet]="customTemplate"
            >
            </ng-container>
          </ng-container>
          <ng-template #defaultCellBlock>
            <scriptaw-matrix-dynamic-cell
              [matrixValue]="getCellValueByColumnName(element, col.name)"
              [dateFormat]="dateFormat"
            ></scriptaw-matrix-dynamic-cell>
          </ng-template>
        </scriptaw-user-tier-lock>
      </td>
    </ng-container>

    <!-- FIXED COLUMN FOR ACTION BUTTONS -->
    <ng-container [matColumnDef]="fixedColumnName" [stickyEnd]="true">
      <!-- HEADER FOR THE CELL -->
      <th *matHeaderCellDef mat-header-cell class="fixed-header-cell"></th>

      <!-- BODY FOR THE CELL -->
      <td *matCellDef="let element" mat-cell class="fixed-cell">
        <ng-container
          *ngIf="fixedCellTemplate; else defaultFixedCellTemplate"
          [ngTemplateOutlet]="fixedCellTemplate"
          [ngTemplateOutletContext]="{ $implicit: element }"
        >
        </ng-container>
        <ng-template #defaultFixedCellTemplate></ng-template>
      </td>
    </ng-container>

    <!-- Header cells -->
    <tr *matHeaderRowDef="columnNames; sticky: true" mat-header-row [class.hidden]="dataSource.data.length === 0"></tr>

    <tr *matRowDef="let row; columns: columnNames; let index = index" [ngClass]="getRowCssClass ? getRowCssClass(row, index) : ''" mat-row></tr>

    <!-- Empty Table message -->
    <ng-container matColumnDef="noRecords">
      <td *matFooterCellDef mat-cell class="empty-table-message" [attr.colspan]="getEmptyTableColspan()">
        <span *ngIf="!loading; else loadingRow">{{ emptyMessage }}</span>
        <ng-template #loadingRow>Loading data...</ng-template>
      </td>
    </ng-container>
    <tr
      *matFooterRowDef="['noRecords']"
      mat-footer-row
      [class.hidden]="dataSource.data.length > 0"
    ></tr>
  </table>
</div>

<mat-paginator
  *ngIf="pagination"
  color="primary"
  [pageSize]="pagination.pageSize"
  [pageIndex]="pagination.page"
  [length]="pagination.totalCount"
  [hidePageSize]="true"
  (page)="paginationChanged($event)"
>
</mat-paginator>
