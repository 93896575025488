import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { FormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { listenControlChanges } from '@scriptac/common/core/rxjs/listen-control-changes';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { InfiniteScrollListStrategy, ListManager } from '@scriptac/common/core/utils/list-manager';
import { createTrackByPropertyFunction } from '@scriptac/common/core/utils/track-by-property';
import { KeyValue } from '@angular/common';
import { SortDirection } from '@scriptac/common/core/enums/sort-direction';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HolderType } from '@scriptac/common/core/models/holder-type';
import { MatrixSearchName } from '@scriptac/common/core/models/matrix-search-name';
import { ControlValueAccessorBase } from '@scriptac/common/core/utils/control-value-accessor-base';
import { HolderTypeService } from '@scriptac/common/core/services/holder-type.service';

/** Multi select with autocomplete for holder type. */
@Component({
  selector: 'scriptaw-holder-type-autocomplete',
  templateUrl: './holder-type-autocomplete.component.html',
  styleUrls: [
    './holder-type-autocomplete.component.scss',
    '../naupa-code-autocomplete/naupa-code-autocomplete.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HolderTypeAutocompleteComponent),
      multi: true,
    },
  ],
})
export class HolderTypeAutocompleteComponent extends ControlValueAccessorBase<MatrixSearchName[]> {
  /** Placeholder. */
  @Input()
  public placeholder = 'Select';

  /** Whether need All Option. */
  @Input()
  public needAllOption = false;

  /** List of selected options. */
  public readonly selectedOptions = new Map<number, HolderType>();

  /** Control for input. */
  public readonly searchControl = this.fb.controlTyped<string>('');

  /** Track by key id. */
  public trackByKey = createTrackByPropertyFunction<KeyValue<number, HolderType>>('key');

  /** Track by id. */
  public trackById = createTrackByPropertyFunction<HolderType>('id');

  private readonly filter$ = listenControlChanges<string>(this.searchControl);

  /** List manager. */
  public readonly listManager = new ListManager<HolderType, string>({
    strategy: new InfiniteScrollListStrategy(),
    filter$: this.filter$,
    pageSize: 30,
  });

  /** Options list. */
  private readonly optionsList$ = this.listManager
    .getPaginatedItems(option => this.holderTypeService.getHolderTypePagedList(option))
    .pipe(
      map(list => {
        if (this.needAllOption) {
          return [{ id: -1, name: 'All' } as HolderType].concat(list);
        }
        return list;
      }),
      shareReplay({
        bufferSize: 1,
        refCount: true,
      }),
    );

  private readonly reload$ = new Subject();

  /** Filtered options list. */
  public readonly options$ = combineLatest([
    this.optionsList$,
    this.reload$.pipe(startWith(null)),
  ]).pipe(
    map(([options]) => options.filter(option => !this.selectedOptions.has(option.id))),
  );

  public constructor(
    private readonly fb: FormBuilder,
    private readonly holderTypeService: HolderTypeService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
    this.listManager.setSort({
      direction: SortDirection.ASC,
      field: 'name',
    });
  }

  /**
   * Remove option from selected by id.
   *
   * @param option Option.
   */
  public remove(option: HolderType): void {
    this.selectedOptions.delete(option.id);
    this.reload$.next(undefined);
    this.setValue([...this.selectedOptions.values()]);
  }

  /**
   * Add selected value to selected codes.
   *
   * @param event Autocomplete select event.
   */
  public selected(event: MatAutocompleteSelectedEvent): void {
    const option: MatrixSearchName = event.option.value;

    if (!option?.id) {
      return;
    }

    // If new option is All then clear others
    if (option.id === -1) {
      this.selectedOptions.clear();
    } else if (this.selectedOptions.get(-1)) {
      // If selected option not All, but we already select All then we should remove All option
      this.selectedOptions.delete(-1);
    }

    this.selectedOptions.set(option.id, option);
    this.reload$.next(undefined);
    this.setValue([...this.selectedOptions.values()]);

    this.searchControl.setValue('');
  }

  /** @inheritDoc */
  public afterWriteValue(): void {
    if (this.value) {
      this.selectedOptions.clear();
      this.value.forEach(val => this.selectedOptions.set(val.id, val));
    }

    if (!this.value || this.value?.length === 0) {
      this.selectedOptions.clear();
    }
  }
}
