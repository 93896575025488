import { Injectable } from '@angular/core';

import { PropertyCodeCategory } from '../../models/property-code-category';

import { PropertyCodeCategoryDto } from './dto/property-code-category';
import { IMapperFromDto } from './mappers';
import { StateMapper } from './state.mapper';

/** Mapper for PropertyCode Categories. */
@Injectable({ providedIn: 'root' })
export class PropertyCodeCategoryMapper implements IMapperFromDto<PropertyCodeCategoryDto, PropertyCodeCategory> {

  public constructor(
    private readonly stateMapper: StateMapper,
  ) {
  }
  /** @inheritdoc */
  public fromDto(dto: PropertyCodeCategoryDto): PropertyCodeCategory {
    return new PropertyCodeCategory({
      id: dto.id,
      name: dto.name,
      code: dto.code,
      states: dto.jurisdictions?.map(stateDto => this.stateMapper.fromDto(stateDto)) ?? [],
    });
  }
}
