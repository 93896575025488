import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { Revision, RevisionEdit } from '../../models/revision';

import { RevisionEditForm } from '../forms/matrix-revision-form-utils.service';

import { RevisionDto, RevisionEditDto, RevisionEditErrorDto } from './dto/revision-dto';
import { MatrixValueMapper } from './matrix-value.mapper';
import { IMapperFromDto, IValidationErrorMapper } from './mappers';
import { StateMapper } from './state.mapper';
import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error-dto';
import { DateMapper } from './date.mapper';

/** Revision mapper. */
@Injectable({ providedIn: 'root' })
export class RevisionMapper implements IMapperFromDto<RevisionDto, Revision>,
  IValidationErrorMapper<RevisionEditErrorDto, RevisionEditForm> {
  public constructor(
    private readonly valueMapper: MatrixValueMapper,
    private readonly stateMapper: StateMapper,
    private readonly dateMapper: DateMapper,
  ) {
  }

  /** @inheritdoc */
  public fromDto(dto: RevisionDto): Revision {
    return new Revision({
      id: dto.id,
      version: dto.version,
      status: dto.status,
      effectiveTill: dto.effective_till ? this.dateMapper.fromDto(dto.effective_till) : null,
      effectiveSince: dto.effective_since ? this.dateMapper.fromDto(dto.effective_since) : null,
      law: dto.law,
      lawData: dto.law_data ? {
        id: dto.law_data.id,
        stateId: dto.law_data.jurisdiction,
        state: this.stateMapper.fromDto(dto.law_data.jurisdiction_data),
      } : null,
      values: dto.values ? dto.values.map(val => this.valueMapper.fromDto(val)) : [],
      reasonForChange: dto.reason_for_change,
    });
  }

  /** @inheritdoc */
  public toDto(data: RevisionEdit): RevisionEditDto {
    return {
      effective_since: data.effectiveSince ? this.dateMapper.toDto(data.effectiveSince) : null,
      effective_till: data.effectiveTill ? this.dateMapper.toDto(data.effectiveTill) : null,
      law: data.law,
      values: data.values.map(v => this.valueMapper.toDto(v)),
      status: data.status,
      reason_for_change: data.reasonForChange,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<RevisionEditErrorDto>,
  ): EntityValidationErrors<RevisionEditForm> {
    return {
      params: {
        effectiveSince: extractErrorMessage(errorDto.effective_since) ?? extractErrorMessage(errorDto.effective_term),
        law: extractErrorMessage(errorDto.law),
        status: extractErrorMessage(errorDto.status),
        effectiveTill: extractErrorMessage(errorDto.effective_till),
      },
      // TODO: Update EntityValidationErrors type to support arrays.
      values: errorDto.values && (errorDto.values as any[]).map(this.valueMapper.validationErrorFromDto) as any,
    };
  }
}
