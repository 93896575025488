import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from '@scriptac/common/core/services/app-config.service';
import { map } from 'rxjs/operators';
import { MatrixSearchResultDto } from '@scriptac/common/core/services/mappers/dto/matrix-search-result-dto';
import { MatrixSearchResult } from '@scriptac/common/core/models/matrix-search-result';
import { SearchResultsMapper } from '@scriptac/common/core/services/mappers/search-results.mapper';
import { SearchResultFiltersMapper } from '@scriptac/common/core/services/mappers/search-result-filters.mapper';
import { MatrixSearchFilters } from '@scriptac/common/core/models/matrix-search-filter';
import { FetchListOptions } from '@scriptac/common/core/models/fetch-list-options';
import { MatrixSearchName } from '@scriptac/common/core/models/matrix-search-name';
import { HttpParamsMapper } from '@scriptac/common/core/services/mappers/http-params-mapper';
import { PagedListDto } from '@scriptac/common/core/services/mappers/dto/paged-list-dto';
import { PagedListMapper } from '@scriptac/common/core/services/mappers/paged-list.mapper';
import { PagedList } from '@scriptac/common/core/models/paged-list';
import { MatrixSearchNameMapper } from '@scriptac/common/core/services/mappers/matrix-search-name.mapper';
import { SearchResultsLaw } from '@scriptac/common/core/models/law';
import { SearchResultsLawDto } from '@scriptac/common/core/services/mappers/dto/law-dto';
import { SearchMatrixLawsFiltersMapper } from '@scriptac/common/core/services/mappers/search-matrix-laws-filters.mapper';
import { SearchMatrixLawsFilters } from '@scriptac/common/core/models/search-matrix-laws-filters';
import { MatrixSearchNameFiltersMapper } from '@scriptac/common/core/services/mappers/matrix-search-name-filters.mapper';
import { LawMapper } from '@scriptac/common/core/services/mappers/law.mapper';

import { Dormancy } from '../../models/dormancy';
import { DormancyDto } from '../mappers/dto/dormancy-dto';
import { DormancyMapper } from '../mappers/dormancy.mapper';
import { DormancyFilters } from '../../models/dormancy-filters';
import { DormancyFiltersMapper } from '../mappers/dormancy-filters.mapper';

import { PaginationApiService } from './pagination-api.service';

/** Service for search results API. */
@Injectable({ providedIn: 'root' })
export class SearchResultsApiService {
  private readonly searchUrl = new URL('laws/matrix-search/', this.config.apiUrl).toString();

  private readonly dormancyUrl = new URL('laws/dormancy/', this.config.apiUrl).toString();

  public constructor(
    private readonly searchResultsFiltersMapper: SearchResultFiltersMapper,
    private readonly searchResultsMapper: SearchResultsMapper,
    private readonly http: HttpClient,
    private readonly lawMapper: LawMapper,
    private readonly config: AppConfigService,
    private readonly paramsMapper: HttpParamsMapper,
    private readonly listMapper: PagedListMapper,
    private readonly lawsFiltersMapper: SearchMatrixLawsFiltersMapper,
    private readonly matrixSearchNameMapper: MatrixSearchNameMapper,
    private readonly matrixSearchNameFiltersMapper: MatrixSearchNameFiltersMapper,
    private readonly dormancyMapper: DormancyMapper,
    private readonly dormancyFiltersMapper: DormancyFiltersMapper,
    private readonly paginationApiService: PaginationApiService,
  ) { }

  /**
   * Get matrix search results.
   * @param options Pagination, sorting and filtering options.
   */
  public getSearchResults(options: MatrixSearchFilters): Observable<MatrixSearchResult[]> {
    const params = new HttpParams({
      fromObject: {
        ...this.searchResultsFiltersMapper.toDto(options),
        ...this.paginationApiService.defaultPaginationParams,
      },
    });

    return this.paginationApiService.getAllItemsFromPagedList(this.http
      .get<PagedListDto<MatrixSearchResultDto>>(this.searchUrl, { params }))
      .pipe(
        map(response => response.map(matrix => this.searchResultsMapper.fromDto(matrix))),
      );
  }

  /**
   * Get matrix laws list.
   * @param options Filtering options.
   */
  public getSearchMatrixLaws(options: SearchMatrixLawsFilters): Observable<SearchResultsLaw[]> {
    const params = new HttpParams({
      fromObject: {
        ...this.lawsFiltersMapper.toDto(options),
        ...this.paginationApiService.defaultPaginationParams,
      },
    });

    return this.paginationApiService.getAllItemsFromPagedList(this.http
      .get<PagedListDto<SearchResultsLawDto>>(`${this.searchUrl}laws/`, { params }))
      .pipe(
        map(response => response.map(dto => this.lawMapper.fromSearchResultsLawDto(dto))),
      );
  }

  /**
   * Get list of matrix search names.
   * @param options Fetch options.
   */
  public getMatrixSearchNames(options: FetchListOptions): Observable<PagedList<MatrixSearchName>> {
    const params = this.paramsMapper.toDto(options, this.matrixSearchNameFiltersMapper);

    return this.http
      .get<PagedListDto<MatrixSearchName>>(`${this.searchUrl}names/`, { params })
      .pipe(
        map(response => this.listMapper.fromDto(response, this.matrixSearchNameMapper, options.pagination)),
      );
  }

  /**
   * Get dormancy list.
   * @param options Fetch options.
   */
  public getDormancyList(options: FetchListOptions<DormancyFilters>): Observable<PagedList<Dormancy>> {
    const params = this.paramsMapper.toDto(options, this.dormancyFiltersMapper);

    return this.http
      .get<PagedListDto<DormancyDto>>(this.dormancyUrl, { params })
      .pipe(
        map(response => this.listMapper.fromDto(
          response,
          this.dormancyMapper,
          options.pagination,
        )),
      );
  }
}
