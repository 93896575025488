import { Injectable } from '@angular/core';

import { IraDistributionRule } from '../../models/ira-distribution-rule';

import { IraDistributionRuleDto } from './dto/ira-distribution-rule-dto';
import { IMapper } from './mappers';

/** Mapper for IRA Distribution rule. */
@Injectable({ providedIn: 'root' })
export class IraDistributionRuleMapper implements IMapper<IraDistributionRuleDto, IraDistributionRule> {
  /** @inheritdoc */
  public fromDto(data: IraDistributionRuleDto): IraDistributionRule {
    return new IraDistributionRule({
      id: data.id,
      survivingSpouse: data.surviving_spouse,
      eligibleDesignatedBene: data.eligible_designated_bene,
      otherRelationship: data.other_relationship,
      nonIndividual: data.non_individual,
      nonIndividualTrust: data.non_individual_trust,
    });
  }

  /** @inheritdoc */
  public toDto(data: IraDistributionRule): IraDistributionRuleDto {
    return {
      id: data.id,
      surviving_spouse: data.survivingSpouse,
      eligible_designated_bene: data.eligibleDesignatedBene,
      other_relationship: data.otherRelationship,
      non_individual: data.nonIndividual,
      non_individual_trust: data.nonIndividualTrust,
    };
  }
}
