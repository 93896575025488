import { TrackByFunction } from '@angular/core';

/**
 * Factory for trackBy function that allows Angular track the value by provided prop name.
 * @param propName Property by which you want to track the item.
 */
export function createTrackByPropertyFunction<T, P extends keyof T = keyof T>(
  propName: P,
): TrackByFunction<T> {
  return (_index, item) => item[propName];
}
