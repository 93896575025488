import { PropertyCodeCategory } from './property-code-category';
import { Rule } from './rule';

/** Naupa 2 code (code of national standard for reporting unclaimed property). */
export class Naupa2Code {
  /** Code id. */
  public readonly id: number;
  /** Code value. */
  public readonly code: string;
  /** Rules' list for this naupa code with property types. */
  public readonly rules: Rule[];
  /** Category id. */
  public readonly categoryId: number;
  /** Category. */
  public readonly category?: PropertyCodeCategory;
  /** Is restricted. */
  public readonly isRestricted: boolean;

  public constructor(data: Naupa2CodeConstructorData) {
    this.id = data.id;
    this.code = data.code;
    this.rules = data.rules;
    this.categoryId = data.categoryId;
    this.category = data.category;
    this.isRestricted = data.isRestricted;
  }
}

/** Constructor data. */
export type Naupa2CodeConstructorData = Naupa2Code;
