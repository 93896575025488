import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CurrentUserService } from '@scriptac/common/core/services/current-user.service';
import { routePaths } from 'projects/web/src/app/route-paths';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

/**
 * Guard that checks that user is authenticated.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  /**
   * @constructor
   * @param currentUserService Service to manage current user.
   * @param router Service to manage route paths.
   */
  public constructor(
    protected readonly currentUserService: CurrentUserService,
    protected readonly router: Router,
  ) {}

  /**
   * Determine if route could be achieved.
   * @param _route Route.
   * @param state Router state.
   */
  public canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.check(state.url);
  }

  /**
   * @inheritDoc
   */
  public canLoad(): Observable<boolean | UrlTree> {
    return this.check();
  }

  /**
   * Guard logic.
   * Pass only authenticated users.
   * @param isAuthenticated User auth state.
   * @param url Next url.
   */
  public guard(isAuthenticated: boolean, url: string): boolean | UrlTree {
    if (isAuthenticated) {
      return true;
    }

    if (url === '/') {
      return this.router.createUrlTree(routePaths.welcome);
    }

    return this.router.createUrlTree(routePaths.login, {
      queryParams: url ? { next: url } : undefined,
    });
  }

  /**
   * Check if user is authenticated in user service.
   *
   * If user is not authenticated, he will be redirected to login page by
   * userService for further login.
   * @param url Url to be used for redirect after login.
   */
  protected check(url: string = ''): Observable<boolean | UrlTree> {
    return this.currentUserService.isAuthenticated$.pipe(
      first(),
      map(isAuthenticated => this.guard(isAuthenticated, url)),
    );
  }
}
