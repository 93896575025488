<mat-divider></mat-divider>
<ng-container *ngLet="matrix$ | async as matrix">
  <ng-container *ngLet="isAdmin$ | async as isAdmin">
    <header class="header">
      <div class="title">
        <h1>Due Diligence</h1>
      </div>
      <div *ngIf="isAdmin" class="buttons">
        <button
          mat-flat-button
          class="custom-icon-button"
          type="button"
          title="Add Law"
          (click)="openLawDialog(matrix)"
        >
          <span class="button-content">
            <mat-icon svgIcon="add"></mat-icon>
            <span>Add Law</span>
          </span>
        </button>
        <scriptaw-export-menu-buttons
          (selectExportFileFormat)="exportResults($event)"
        ></scriptaw-export-menu-buttons>
      </div>
    </header>
    <mat-divider></mat-divider>

    <form class="filters-form" [formGroup]="filtersForm">
      <scriptac-label labelText="Jurisdiction" class="filter">
        <scriptaw-states-autocomplete
          formControlName="states"
          [needAllOption]="true"
        ></scriptaw-states-autocomplete>
      </scriptac-label>
      <scriptac-label labelText="Holder Type" class="filter">
        <scriptaw-holder-type-autocomplete
          formControlName="holderTypes"
        ></scriptaw-holder-type-autocomplete>
      </scriptac-label>
      <scriptaw-export-menu-buttons
        *ngIf="(showExportButton$ | async) && !isAdmin"
        class="export-button"
        (selectExportFileFormat)="exportResults($event)"
      ></scriptaw-export-menu-buttons>
    </form>
    <div class="page-content">
      <scriptaw-horizontal-matrix
        [matrixData]="matrixLaws$ | async"
        [matrixId]="matrix?.id || null"
        [showStateColumn]="true"
        [showFixedColumn]="true"
        [dateFormat]="dateFormat"
        [getElementValuesList]="getValuesListFromLaw"
        [columns]="columns$ | async"
        [loading]="tableManager.listLoading$ | async"
        (columnsUpdate)="saveUpdatedColumns($event)"
      >
        <ng-template #stateCellTemplate let-element>
          {{ element.state.name }}
        </ng-template>

        <ng-template #fixedCellTemplate let-element>
          <div *ngIf="isAdmin; else regularUserActions" class="admin-buttons">
            <button
              mat-flat-button
              class="custom-icon-button"
              type="button"
              title="Add Revision"
              [disabled]="tableManager.listLoading$ | async"
              (click)="openRevisionDialog(element, matrix)"
            >
              <span class="button-content">
                <mat-icon svgIcon="add"></mat-icon>
                <span>Add Revision</span>
              </span>
            </button>
            <a
              mat-flat-button
              title="View History"
              class="custom-icon-button"
              [routerLink]="routePaths.matrixRevision(element.matrixId, element.id)"
            >
              <span class="button-content">
                <mat-icon svgIcon="history"></mat-icon>
                <span>View History</span>
              </span>
            </a>
            <button
              mat-flat-button
              title="Delete Law"
              class="custom-icon-button"
              (click)="deleteLaw(element)"
            >
              <span class="button-content delete">
                <mat-icon svgIcon="trash"></mat-icon>
                <span>Delete Law</span>
              </span>
            </button>
          </div>
          <ng-template #regularUserActions>
            <a
              *ngIf="shouldDisplayViewHistory(element)"
              mat-flat-button
              title="View History"
              class="custom-icon-button"
              [routerLink]="routePaths.resultDetails(element.id)"
            >
              <span class="button-content">
                <mat-icon svgIcon="history"></mat-icon>
                <span>View History</span>
              </span>
            </a>
          </ng-template>
        </ng-template>
      </scriptaw-horizontal-matrix>

      <mat-paginator
        *ngIf="tableManager.pagePagination$ | async as pagination"
        color="primary"
        [pageSize]="pagination.pageSize"
        [pageIndex]="pagination.page"
        [length]="pagination.totalCount"
        [hidePageSize]="true"
        (page)="paginationChanged($event)"
      >
      </mat-paginator>
    </div>
  </ng-container>
</ng-container>

<scriptac-page-loader *ngIf="(matrixLaws$ | async) === null"></scriptac-page-loader>
