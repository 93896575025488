<button
  mat-flat-button
  class="custom-icon-button"
  type="button"
  title="Export"
  [matMenuTriggerFor]="menu"
>
  <span class="button-content">
    <mat-icon svgIcon="export"></mat-icon>
    <span>Export</span>
  </span>
</button>

<mat-menu #menu>
  <button
    *ngFor="let fileFormat of exportFileFormat.toArray(); trackBy: exportFileFormat.trackBy"
    mat-menu-item
    (click)="selectExportFileFormat.emit(fileFormat)"
  >
    {{ exportFileFormat.toReadable(fileFormat) }}
  </button>
</mat-menu>
