import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

const MIN_COLUMN_WIDTH = 100;

/**
 * Resizable column component.
 */
@Component({
  selector: 'scriptac-resizable',
  templateUrl: './resizable-column.component.html',
  styleUrls: ['./resizable-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResizableColumnComponent {

  /** Width. */
  @HostBinding('style.min-width.px')
  public minWidth: number | null = null;

  /** Column width. */
  @Input()
  public set columnWidth(value: number | undefined) {
    let width = value ?? MIN_COLUMN_WIDTH;
    if (width < MIN_COLUMN_WIDTH) {
      width = MIN_COLUMN_WIDTH;
    }
    this.minWidth = width;
  }

  /** Send updated width in parent component. */
  @Output()
  public readonly updatedWidth = new EventEmitter<number>();

  /**
   * Column resize handler.
   * @param width Width.
   */
  public onResize(width: number): void {
    let newWidth = Math.round(width);
    if (newWidth < MIN_COLUMN_WIDTH) {
      newWidth = MIN_COLUMN_WIDTH;
    }

    this.minWidth = newWidth;
    this.updatedWidth.emit(newWidth);
  }
}
