import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { filter, first, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { UserType } from '@scriptac/common/core/enums/user-type';
import { ReportingDataService, ReportingMechanicsFilters } from '@scriptac/common/core/services/reporting-data.service';
import { combineLatest, merge, Subject } from 'rxjs';
import { listenControlChanges } from '@scriptac/common/core/rxjs/listen-control-changes';
import { filterNull } from '@scriptac/common/core/rxjs/filter-null';
import { ListManager, TableListStrategy } from '@scriptac/common/core/utils/list-manager';
import { Law, LawEdit, SearchResultsLaw } from '@scriptac/common/core/models/law';
import { MatrixColumnInfo } from '@scriptac/common/core/models/matrix-column-info';
import { CurrentUserService } from '@scriptac/common/core/services/current-user.service';
import { MatrixColumnsStorageService } from '@scriptac/common/core/services/matrix-columns-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StateFilterQueryParamsService } from '@scriptac/common/core/services/mappers/state-filter-query-params.mapper';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { NotificationService } from '@scriptac/common/core/services/notifications.service';
import { LawApiService } from '@scriptac/common/core/services/api/law-api.service';
import { DialogUtilsService } from '@scriptac/common/core/services/dialog-utils.service';
import { DestroyableComponent, takeUntilDestroy } from '@scriptac/common/core/utils/destroyable';
import { MatrixValue } from '@scriptac/common/core/models/matrix-value';
import { PageEvent } from '@angular/material/paginator';
import { PaginationOptions } from '@scriptac/common/core/models/pagination-options';
import { MatrixSearchResult } from '@scriptac/common/core/models/matrix-search-result';
import { createMatrixColumns } from '@scriptac/common/core/utils/create-matrix-columns';
import { getInitialMatrixColumns } from '@scriptac/common/core/utils/get-initial-matrix-columns';
import { routePaths } from 'projects/web/src/app/route-paths';
import { REPORTING_DATE_FORMAT } from '@scriptac/common/core/utils/constants';
import { Revision } from '@scriptac/common/core/models/revision';
import { ExportFileFormat } from '@scriptac/common/core/enums/export-file-format';
import { MatrixExportService } from '@scriptac/common/core/services/matrix-export.service';
import { DueDiligenceMatrixExportFilters } from '@scriptac/common/core/models/due-diligence-matrix-export-filters';

import { MatrixRevisionDialogComponent, AddRevisionDialogData, MatrixRevisionDialogResult } from '../matrix-revision-dialog/matrix-revision-dialog.component';
import { MatrixAddLawDialogComponent, AddLawDialogData } from '../../../admin-matrix/matrix-add-law-dialog/matrix-add-law-dialog.component';
import { ALL_OPTION_ID } from '../states-autocomplete/states-autocomplete.component';
import { MatrixExportDialogComponent, MatrixExportDialogData } from '../matrix-export-dialog/matrix-export-dialog.component';

/** Due diligence matrix with filters. */
@Component({
  selector: 'scriptaw-due-diligence-matrix',
  templateUrl: './due-diligence-matrix.component.html',
  styleUrls: ['./due-diligence-matrix.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MatrixColumnsStorageService],
})
@DestroyableComponent()
export class DueDiligenceMatrixComponent implements OnInit {
  /** Route paths. */
  public readonly routePaths = routePaths;

  /** Date cell format. */
  public readonly dateFormat = REPORTING_DATE_FORMAT;

  /** Is admin. */
  public readonly isAdmin$ = this.currentUserService.currentUser$.pipe(
    map(user => user?.userType === UserType.Admin),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /** Whether show export button according to user tier. */
  public readonly showExportButton$ = this.currentUserService.hasThirdTier$;

  private readonly reloadMatrix$ = new Subject<void>();

  /** Matrix data stream. */
  public readonly matrix$ = this.reloadMatrix$.pipe(
    startWith(null),
    switchMap(() => this.reportingDataService.getDueDiligenceMatrix()),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  /** Filters form. */
  public readonly filtersForm = this.fb.groupTyped<ReportingMechanicsFilters>({
    states: [undefined],
    holderTypes: [undefined],
  });

  /** Filters. */
  public readonly filters$ = combineLatest([
    listenControlChanges<ReportingMechanicsFilters>(this.filtersForm),
    this.matrix$,
  ]).pipe(
    map(([filters]) => filters),
  );

  /** States names. */
  public readonly statesNames$ = this.filters$.pipe(
    filterNull(),
    map(filters => filters.states?.map(state => state.name).join(', ')),
  );

  /** Table manager. */
  public readonly tableManager = new ListManager<SearchResultsLaw, ReportingMechanicsFilters>({
    strategy: new TableListStrategy(),
    filter$: this.filters$,
  });

  /** Matrix laws list. */
  public readonly matrixLaws$ = this.tableManager.getPaginatedItems(
    options => this.reportingDataService.getDueDiligenceMatrixLaws(options),
  ).pipe(
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  /** Update columns from filter. */
  private readonly updatedColumns$ = new Subject<MatrixColumnInfo[]>();

  /** Columns from storage to active matrix. */
  private readonly columnsFromStorage$ = this.matrix$.pipe(
    switchMap(matrix => this.matrixColumnsStorageService.getColumns(matrix.id)),
  );

  private readonly columnsUpdate$ = merge(
    this.updatedColumns$,
    this.columnsFromStorage$,
  );

  /** Columns data for active tab matrix. */
  public readonly columns$ = combineLatest([
    this.matrix$,
    this.columnsUpdate$,
  ]).pipe(
    map(([matrix, savedColumns]) => this.calculateMatrixColumns(matrix, savedColumns)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public constructor(
    private readonly reportingDataService: ReportingDataService,
    private readonly currentUserService: CurrentUserService,
    private readonly matrixColumnsStorageService: MatrixColumnsStorageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly stateFilterQueryParamsMapper: StateFilterQueryParamsService,
    private readonly dialog: MatDialog,
    private readonly fb: FormBuilder,
    private readonly notificationService: NotificationService,
    private readonly lawService: LawApiService,
    private readonly dialogUtilsService: DialogUtilsService,
    private readonly matrixExportService: MatrixExportService,
  ) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    const states = this.route.snapshot.queryParamMap.get('states');
    if (states) {
      this.filtersForm.patchValue({
        states: this.stateFilterQueryParamsMapper.fromQueryParams(states),
      });
    }

    this.filters$.pipe(
      takeUntilDestroy(this),
    ).subscribe(filters => this.setQueryParams(filters));
  }

  /**
   * Add new law in matrix.
   * @param matrix Matrix data.
   */
  public openLawDialog(matrix: MatrixSearchResult | null): void {
    if (matrix === null) {
      return;
    }

    const dialogRef = this.dialog.open<MatrixAddLawDialogComponent, AddLawDialogData, LawEdit>(MatrixAddLawDialogComponent, {
      width: '500px',
      data: {
        matrixId: matrix.id,
      },
    });

    dialogRef.afterClosed().pipe(
      filterNull(),
      switchMap(updatedLaw => this.lawService.createLaw(updatedLaw)),
      tap(law => this.openRevisionDialog(law, matrix)),
      tap(() => this.tableManager.reloadList()),
      takeUntilDestroy(this),
    ).subscribe();
  }

  /**
   * Open add revision dialog.
   * @param law Law.
   * @param matrix Matrix.
   */
  public openRevisionDialog(law: Law, matrix: MatrixSearchResult | null): void {
    const templateRevisions = [
      law.currentRevision,
      law.pendingRevision,
      law.enactedRevision,
    ].filter((revision): revision is Revision => Boolean(revision));

    const dialogRef = this.dialog.open<MatrixRevisionDialogComponent, AddRevisionDialogData, MatrixRevisionDialogResult>(
      MatrixRevisionDialogComponent, {
      width: '100%',
      data: {
        law,
        revision: null,
        templateRevisions,
        matrixFields: matrix?.fields,
      },
    },
    );

    dialogRef
      .afterClosed()
      .subscribe(result => {
        if (result?.revision) {
          this.tableManager.reloadList();
        }

        if (result?.shouldReloadMatrix) {
          this.reloadMatrix$.next();
        }
      });
  }

  /**
   * Delete law.
   * @param law Law.
   */
  public deleteLaw(law: SearchResultsLaw): void {
    this.dialogUtilsService.openConfirmationModal('Are you sure you want to delete this law?')
      .pipe(
        filter(Boolean),
        switchMap(() => this.lawService.deleteLaw(law.id)),
      ).subscribe(() => {
        this.notificationService.showSuccess('Law was deleted successfully');
        this.tableManager.reloadList();
      });
  }

  /**
   * Get values list from law data.
   * @param law Law data.
   */
  public getValuesListFromLaw(law: SearchResultsLaw): MatrixValue[] {
    return law.currentRevision?.values ?? law.pendingRevision?.values ?? [];
  }

  /**
   * Whether display view history button for law.
   * @param law Law info.
   */
  public shouldDisplayViewHistory(law: SearchResultsLaw): boolean {
    return law.hasHistory;
  }

  /**
   * Save columns to storage.
   * @param columns Columns list.
   */
  public saveUpdatedColumns(columns: MatrixColumnInfo[]): void {
    this.matrix$.pipe(
      first(),
      switchMap(matrix => this.matrixColumnsStorageService.saveColumns(columns, matrix.id)),
    )
      .subscribe(() => this.updatedColumns$.next(columns));
  }

  /**
   * Paginator changed.
   * @param page Page event.
   */
  public paginationChanged(page: PageEvent): void {
    const newPagination = new PaginationOptions({
      page: page.pageIndex,
      pageSize: page.pageSize,
      totalCount: page.length,
    });

    this.tableManager.setPagination(newPagination, true);
  }

  /**
   * Export results.
   * @param fileFormat Export file format.
   */
  public exportResults(fileFormat: ExportFileFormat): void {
    const jurisdictionIds = this.filtersForm.controls.states?.value?.reduce<number[]>((acc, state) => {
      if (state.id === ALL_OPTION_ID) {
        return acc;
      }
      return acc.concat(state.id);
    }, []) ?? [];
    const holderTypesIds = this.filtersForm.controls.holderTypes?.value?.map(type => type.id) ?? [];

    this.dialog.open<MatrixExportDialogComponent, MatrixExportDialogData<DueDiligenceMatrixExportFilters>>(MatrixExportDialogComponent, {
      width: '500px',
      data: {
        filters: {
          fileFormat,
          jurisdictionIds,
          holderTypesIds,
        },
        getMatrixExportResult: jobId => this.matrixExportService.getDueDiligenceMatrixExportResult(jobId),
        startMatrixExport: args => this.matrixExportService.startDueDiligenceMatrixExport(args),
      },
      disableClose: true,
    });
  }

  private calculateMatrixColumns(matrix: MatrixSearchResult, savedColumns: MatrixColumnInfo[] | null): MatrixColumnInfo[] {
    const columnsFromApi = createMatrixColumns(matrix.fields);
    return getInitialMatrixColumns(savedColumns, columnsFromApi);
  }

  private setQueryParams(filters: ReportingMechanicsFilters): void {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          states: filters.states?.length ?
            this.stateFilterQueryParamsMapper.toQueryParams(filters.states) :
            undefined,
        },
      },
    );
  }
}
