import { Injectable } from '@angular/core';

import { MatrixValue, MatrixValueEdit } from '../../models/matrix-value';

import { EntityValidationErrors } from '../../models/app-error';

import { IMapperFromDto, IValidationErrorMapper } from './mappers';
import { MatrixValueDto, MatrixValueEditDto } from './dto/matrix-value-dto';
import { MatrixFieldMapper } from './matrix-field-mapper';
import { ChoicesValueMapper } from './choices-value.mapper';
import { Naupa2CodeMapper } from './naupa-2-code.mapper';
import { DateMapper } from './date.mapper';
import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error-dto';
import { HolderTypeMapper } from './holder-type.mapper';

/** Matrix value mapper. */
@Injectable({ providedIn: 'root' })
export class MatrixValueMapper implements IMapperFromDto<MatrixValueDto, MatrixValue>,
  IValidationErrorMapper<MatrixValueEditDto, MatrixValueEdit> {

  public constructor(
    private readonly fieldMapper: MatrixFieldMapper,
    private readonly choicesValueMapper: ChoicesValueMapper,
    private readonly naupa2CodeMapper: Naupa2CodeMapper,
    private readonly dateMapper: DateMapper,
    private readonly holderTypeMapper: HolderTypeMapper,
  ) {
  }

  /** @inheritdoc */
  public fromDto(dto: MatrixValueDto): MatrixValue {
    return new MatrixValue({
      id: dto.id,
      fieldId: dto.field,
      field: this.fieldMapper.fromDto(dto.field_data),
      textValue: dto.text_value ?? '',
      dateValue: dto.date_value ? this.dateMapper.fromDto(dto.date_value) : null,
      boolValue: dto.bool_value,
      choicesValues: dto.choices_values_data?.map(item => this.choicesValueMapper.fromDto(item)) ?? [],
      naupa2CodeValues: dto.naupa2_code_values_data?.map(item => this.naupa2CodeMapper.fromDto(item)) ?? [],
      holderTypeValues: dto.holder_type_values_data?.map(item => this.holderTypeMapper.fromDto(item)) ?? [],
      note: dto.note,
    });
  }

  /** @inheritdoc */
  public toDto(data: MatrixValueEdit): MatrixValueEditDto {
    let choicesValues: number[] = [];

    if (data.multipleChoicesValues) {
      choicesValues = data.multipleChoicesValues.map(choice => choice.id);
    }
    if (data.singleChoiceValue) {
      choicesValues = [data.singleChoiceValue.id];
    }

    return {
      id: data.id ?? null,
      field: data.field.id,
      text_value: data.textValue ?? data.value ?? data.alternativeValue ?? null,
      bool_value: data.boolValue ?? null,
      date_value: data.dateValue ? this.dateMapper.toDto(data.dateValue) : null,
      choices_values: choicesValues,
      naupa2_code_values: data.naupa2CodeValues ? data.naupa2CodeValues.map(c => c.id) : [],
      holder_type_values: data.holderTypeValues ? data.holderTypeValues.map(item => item.id) : [],
      note: data.note,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<MatrixValueEditDto>,
  ): EntityValidationErrors<MatrixValueEdit> {
    return {
      textValue: extractErrorMessage(errorDto.text_value),
      boolValue: extractErrorMessage(errorDto.bool_value),
      dateValue: extractErrorMessage(errorDto.date_value),
      singleChoiceValue: extractErrorMessage(errorDto.choices_values),
      multipleChoicesValues: extractErrorMessage(errorDto.choices_values),
      naupa2CodeValues: extractErrorMessage(errorDto.naupa2_code_values),
      holderTypeValues: extractErrorMessage(errorDto.holder_type_values),
      note: extractErrorMessage(errorDto.note),
    };
  }
}
