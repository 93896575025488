<div class="header-container">
  <img src="assets/scripta-color.svg" alt="logo" />
  <div class="buttons-panel">
    <a
      *ngFor="let link of links"
      class="button"
      routerLinkActive="active"
      [title]="link.title"
      [routerLink]="link.route"
    >
      <mat-icon [svgIcon]="link.icon"></mat-icon>
      <span class="button-title">{{ link.title }}</span>
    </a>
  </div>
  <div class="auth-buttons">
    <a class="button small" routerLinkActive="active" title="Account" [routerLink]="routePaths.profile">
      <mat-icon svgIcon="account"></mat-icon>
      <span class="button-title">Account</span>
    </a>
    <button mat-flat-button class="logout" color="accent" title="Logout" (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
    </button>
  </div>
</div>
