import { UserType } from '../../../common/core/enums/user-type';

/** List with all route paths. */
export const routePaths = {
  welcome: ['/welcome'],

  // Welcome module
  aboutUs: ['/welcome/about-us'],
  ourServices: ['/welcome/our-services'],

  // News module
  news: ['/news'],
  newsPost: (id: string | number): string[] => [...routePaths.news, String(id)],

  // Admin News module
  adminNews: ['/admin-news'],
  createNewsPost: ['/admin-news/new'],
  editNewsPost: (id: string | number): string[] => [...routePaths.adminNews, String(id)],

  // Admin Ira distribution module
  adminIraDistribution: ['/ira-distribution-rules'],
  adminIraDistributionAddRow: ['/ira-distribution-rules/add'],
  adminIraDistributionEdit: (id: string | number): string[] => [...routePaths.adminIraDistribution, 'edit', String(id)],

  // Admin Priority Rules
  adminPriorityRules: ['/priority-rules'],
  adminPriorityRuleNew: ['/priority-rules/new'],
  adminPriorityRuleEdit: (id: string | number): string[] => [...routePaths.adminPriorityRules, 'edit', String(id)],

  // Admin Definitions module
  adminDefinitions: ['/definitions'],
  definitionsDetails: (id: string | number): string[] => [...routePaths.adminDefinitions, String(id)],

  // Auth Module
  login: ['/auth'],
  resetPassword: ['/auth/reset-password'],
  newPassword: ['/auth/new-password'],

  // Search
  search: ['/search'],
  searchIra: ['/search/ira'],
  searchPriorityRules: ['/search/priority-rules'],
  searchDefinitions: ['/search/definitions'],
  searchDormancy: ['/search/dormancy'],
  codesAndDescriptions: ['/search/codes-and-descriptions'],
  searchPropertyCodes: ['/search/property-codes'],
  searchKeywords: ['/search/keywords'],
  searchTopics: ['/search/topics'],
  searchResults: ['/search/results'],
  resultDetails: (id: string | number): string[] => [...routePaths.searchResults, String(id)],

  // Legislative Tracking
  tracking: ['/tracking'],
  changeLog: (billId: number | string, impactId: string | number): string[] => [
    ...routePaths.tracking,
    String(billId),
    'changelog',
    String(impactId),
  ],
  createRecord: ['/tracking/new'],
  editRecord: (id: string | number): string[] => [...routePaths.tracking, String(id)],

  // Reporting
  reporting: ['/reporting'],
  reportingData: ['/reporting/matrix-data'],
  materials: ['/reporting/materials'],
  calendar: ['/reporting/calendar'],
  dueDiligence: ['/reporting/due-diligence'],

  // Change Report
  currentChangeReport: ['/change-report/current'],
  currentChangeReportResults: ['/change-report/current/search-results'],

  // Enacted Change Report
  enactedChangeReport: ['/change-report/enacted'],
  enactedChangeReportResults: ['/change-report/enacted/search-results'],

  // Profile
  profile: ['/profile'],
  changePassword: ['/profile/password-change'],

  // Admin companies account manage
  accounts: ['/accounts'],
  companies: ['/accounts/companies'],
  companyNew: ['/accounts/companies/new'],
  companyEdit: (id: string | number): string[] => [...routePaths.companies, 'edit', String(id)],

  // Admin users account manage
  users: ['/accounts/users'],
  userNew: ['/accounts/users/new'],
  userEdit: (id: string | number): string[] => [...routePaths.users, 'edit', String(id)],

  // Admin dashboard
  dashboard: ['/dashboard'],
  matrixDetails: (id: string | number): string[] => [
    ...routePaths.dashboard,
    String(id),
  ],
  matrixConfigure: (id: string | number): string[] => [
    ...routePaths.dashboard,
    String(id),
    'configure',
  ],
  matrixRevision: (matrixId: number | string, lawId: string | number): string[] => [
    ...routePaths.matrixDetails(matrixId),
    String(lawId),
    'revisions',
  ],

  // Admin Reporting.
  adminReporting: ['/admin-reporting'],
  adminReportingData: ['/admin-reporting/reporting-data'],
  adminReportingMaterials: ['/admin-reporting/reporting-materials'],
  adminReportingDueDiligence: ['/admin-reporting/due-diligence'],

  // Admin settings.
  settings: ['/settings'],
  propertyTypesSettings: ['/settings/property-types'],
  propertyCodesSettings: ['/settings/property-codes'],

  notFound: ['/not-found'],
};

/**
 * Main pages for each of user type
 * Here are specified pages with high priority, where the user will be redirected after login.
 */
export const mainPageByUserType: Record<UserType, string[]> = {
  [UserType.Admin]: routePaths.dashboard,
  [UserType.User]: routePaths.search,
};
