import { Injectable } from '@angular/core';

import { AccessTier } from '../../models/access-tier';

import {
  AccessTierDto,
  AccessTierEditDto,
} from './dto/access-tier-dto';
import { IMapperFromDto, IMapperToDto } from './mappers';
import { DateMapper } from './date.mapper';

/** Mapper for access tier. */
@Injectable({ providedIn: 'root' })
export class AccessTierMapper
implements IMapperFromDto<AccessTierDto, AccessTier>, IMapperToDto<AccessTierEditDto, AccessTier> {

  public constructor(
    private readonly dateMapper: DateMapper,
  ) {
  }

  /** @inheritdoc */
  public toDto(data: AccessTier): AccessTierEditDto {
    return {
      tier: data.tier,
      is_active: data.isActive,
      valid_until: this.dateMapper.toDto(data.validUntil),
    };
  }

  /** @inheritDoc */
  public fromDto(dto: AccessTierDto): AccessTier {
    return new AccessTier({
      tier: dto.tier,
      validUntil: this.dateMapper.fromDto(dto.valid_until),
      isActive: dto.is_active,
    });
  }
}
