import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { expand, reduce } from 'rxjs/operators';

import { PagedListDto } from '../mappers/dto/paged-list-dto';
import { PaginationOptionsMapper } from '../mappers/pagination-options.mapper';
import { PaginationOptions } from '../../models/pagination-options';

/**
 * Service to store helpers for server pagination.
 */
@Injectable({
  providedIn: 'root',
})
export class PaginationApiService {
  public readonly defaultPaginationParams = this.paginationOptionsMapper.toDto(new PaginationOptions({
    pageSize: 10,
  }));

  public constructor(
    private readonly http: HttpClient,
    private readonly paginationOptionsMapper: PaginationOptionsMapper,
  ) {}

  /**
   * Get all items from server using pagination API.
   * We need this utility to get fetch all items using small chunks.
   * It helps us to handle large queries which might stuck in some cases due to server timeout.
   * @param request$ Request.
   */
  public getAllItemsFromPagedList<TDto>(
    request$: Observable<PagedListDto<TDto>>,
  ): Observable<TDto[]> {
    return request$.pipe(
      expand(response => response.next ? this.http.get<PagedListDto<TDto>>(response.next) : EMPTY),
      reduce((acc: TDto[], items) => acc.concat(items.results ?? items), []),
    );
  }
}
