import { Component, ChangeDetectionStrategy, Input, TrackByFunction } from '@angular/core';

/** Navigation tab. */
export interface NavigationTab {
  /** Tab label. */
  readonly label: string;
  /** Route path. */
  readonly path: string | string[];
}

/**
 * Navigation tabs component.
 */
@Component({
  selector: 'scriptaw-navigation-tabs',
  templateUrl: './navigation-tabs.component.html',
  styleUrls: ['./navigation-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationTabsComponent {

  /** Tabs. */
  @Input()
  public tabs: NavigationTab[] = [];

  /**
   * Track by function.
   * @param index Index.
   */
  public readonly trackByIndex: TrackByFunction<NavigationTab> = index => index;
}
