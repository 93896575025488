/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ExportJob } from '../models/export-job';
import { ReportingMatrixExportFilters } from '../models/reporting-matrix-export-filters';
import { MatrixExportFilters } from '../models/matrix-export-filters';
import { DueDiligenceMatrixExportFilters } from '../models/due-diligence-matrix-export-filters';
import { DormancyMatrixExportFilters } from '../models/dormancy-matrix-export-filters';

import { AppConfigService } from './app-config.service';
import { ExportJobDto } from './mappers/dto/export-job-dto';
import { ExportJobMapper } from './mappers/export-job.mapper';

/**
 * Matrix export service.
 */
@Injectable({
  providedIn: 'root',
})
export class MatrixExportService {
  private readonly searchApiUrl = new URL('laws/matrix-search/export/', this.config.apiUrl).toString();
  private readonly reportingApiUrl = new URL('laws/reporting-mechanics/export/', this.config.apiUrl).toString();
  private readonly dueDiligenceApiUrl = new URL('laws/due-diligence/export/', this.config.apiUrl).toString();
  private readonly dormancyApiUrl = new URL('laws/dormancy/export/', this.config.apiUrl).toString();

  public constructor(
    private readonly http: HttpClient,
    private readonly config: AppConfigService,
    private readonly exportJobMapper: ExportJobMapper,
  ) { }

  /**
   * Start matrix export.
   * @param options.matrixId Matrix id.
   * @param options.fileFormat Export file format.
   * @param options.jurisdictionIds Jurisdiction ids.
   * @param options.naupa2CodesIds Naupa codes ids.
   * @returns Export job id.
   */
  public startMatrixExport({
    matrixId,
    fileFormat,
    jurisdictionIds,
    naupa2CodesIds,
  }: MatrixExportFilters): Observable<ExportJob> {
    const url = new URL('start/', this.searchApiUrl).toString();
    return this.http.post<ExportJobDto>(url, {
      file_format: fileFormat,
    }, {
      params: {
        matrix: matrixId,
        jurisdictions: jurisdictionIds.join(','),
        naupa2_codes: naupa2CodesIds.join(','),
      },
    }).pipe(
      map(dto => this.exportJobMapper.fromDto(dto)),
    );
  }

  /**
   * Get matrix export result.
   * @param jobId Export job id.
   */
  public getMatrixExportResult(jobId: number): Observable<ExportJob> {
    const url = new URL(`${jobId}/`, this.searchApiUrl).toString();
    return this.http.get<ExportJobDto>(url).pipe(
      map(dto => this.exportJobMapper.fromDto(dto)),
    );
  }

  /**
   * Start reporting matrix export.
   * @param options.fileFormat Export file format.
   * @param options.jurisdictionIds Jurisdiction ids.
   * @returns Export job id.
   */
  public startReportingMatrixExport({ fileFormat, jurisdictionIds }: ReportingMatrixExportFilters): Observable<ExportJob> {
    const url = new URL('start/', this.reportingApiUrl).toString();
    return this.http.post<ExportJobDto>(url, {
      file_format: fileFormat,
    }, {
      params: {
        jurisdictions: jurisdictionIds.join(','),
      },
    }).pipe(
      map(dto => this.exportJobMapper.fromDto(dto)),
    );
  }

  /**
   * Get matrix export result.
   * @param jobId Export job id.
   */
  public getReportingMatrixExportResult(jobId: number): Observable<ExportJob> {
    const url = new URL(`${jobId}/`, this.reportingApiUrl).toString();
    return this.http.get<ExportJobDto>(url).pipe(
      map(dto => this.exportJobMapper.fromDto(dto)),
    );
  }

  /**
   * Start due diligence matrix export.
   * @param options.fileFormat Export file format.
   * @param options.jurisdictionIds Jurisdiction ids.
   * @param options.holderTypesIds Holder types ids.
   * @returns Export job id.
   */
  public startDueDiligenceMatrixExport({
    fileFormat,
    jurisdictionIds,
    holderTypesIds,
  }: DueDiligenceMatrixExportFilters): Observable<ExportJob> {
    const url = new URL('start/', this.dueDiligenceApiUrl).toString();
    return this.http.post<ExportJobDto>(url, {
      file_format: fileFormat,
    }, {
      params: {
        jurisdictions: jurisdictionIds.join(','),
        holder_types: holderTypesIds.join(','),
      },
    }).pipe(
      map(dto => this.exportJobMapper.fromDto(dto)),
    );
  }

  /**
   * Get due diligence matrix export result.
   * @param jobId Export job id.
   */
  public getDueDiligenceMatrixExportResult(jobId: number): Observable<ExportJob> {
    const url = new URL(`${jobId}/`, this.dueDiligenceApiUrl).toString();
    return this.http.get<ExportJobDto>(url).pipe(
      map(dto => this.exportJobMapper.fromDto(dto)),
    );
  }

  /**
   * Start dormancy matrix export.
   * @param options.fileFormat Export file format.
   * @param options.jurisdictionIds Jurisdiction ids.
   * @param options.naupa2CodesIds Naupa codes ids.
   * @returns Export job id.
   */
  public startDormancyMatrixExport({
    fileFormat,
    jurisdictionIds,
    naupa2CodesIds,
  }: DormancyMatrixExportFilters): Observable<ExportJob> {
    const url = new URL('start/', this.dormancyApiUrl).toString();
    return this.http.post<ExportJobDto>(url, {
      file_format: fileFormat,
    }, {
      params: {
        jurisdictions: jurisdictionIds.join(','),
        naupa2_codes: naupa2CodesIds.join(','),
      },
    }).pipe(
      map(dto => this.exportJobMapper.fromDto(dto)),
    );
  }

  /**
   * Get dormancy matrix export result.
   * @param jobId Export job id.
   */
  public getDormancyMatrixExportResult(jobId: number): Observable<ExportJob> {
    const url = new URL(`${jobId}/`, this.dormancyApiUrl).toString();
    return this.http.get<ExportJobDto>(url).pipe(
      map(dto => this.exportJobMapper.fromDto(dto)),
    );
  }
}
