import { Injectable } from '@angular/core';

import { TierStats } from '../../models/tier-stat';

import { TierStatsDto } from './dto/tier-stats-dto';
import { IMapperFromDto } from './mappers';

/** Mapper for tier stats. */
@Injectable({ providedIn: 'root' })
export class TierStatsMapper implements IMapperFromDto<TierStatsDto, TierStats> {
  /** @inheritdoc */
  public fromDto(dto: TierStatsDto): TierStats {
    return new TierStats({
      totalCount: dto.total_count,
      noTier: dto.no_tier,
      tier1: dto.tier_1,
      tier2: dto.tier_2,
      tier3: dto.tier_3,
    });
  }
}
