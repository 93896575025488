import { RevisionStatus } from '../enums/revision-status';
import { Revision } from '../models/revision';

/**
 * Get law matrix revision title.
 * @param revision Revision.
 * @param list Revisions list.
 */
export function getLawMatrixRevisionTitle(revision: Revision, list: Revision[]): string {
  if (revision.status !== RevisionStatus.Prior) {
    return RevisionStatus.toReadable(revision.status);
  }
  const priorRevisionsIds = list
    .filter(rev => rev.status === RevisionStatus.Prior)
    .map(rev => rev.id);
  const index = priorRevisionsIds.length - priorRevisionsIds.indexOf(revision.id);

  return `Prior ${index}`;
}
