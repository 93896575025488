import { Directive, Input, ContentChild } from '@angular/core';

import { TableCellDirective } from './table-cell.directive';
import { TableHeaderDirective } from './table-header.directive';

/**
 * Directive to specify how to render column in the table. Base usage examples:
 *
 * <ng-container scriptawTableColumn="name">
 *   <ng-container *scriptawTableHeader>
 *     Name
 *   </ng-container>.
 *   <ng-container *scriptawTableCell="let row">
 *     {{row.name}}
 *   </ng-container>.
 * </ng-container>.
 *
 * <ng-container
 *   scriptawTableColumn="name"
 *   headerText="Name">
 *   <ng-container *scriptawTableCell="let row">
 *     {{row.name}}
 *   </ng-container>.
 * </ng-container>.
 */
@Directive({
  selector: '[scriptawTableColumn]',
})
export class TableColumnDirective {
  /** Name of the column. */
  @Input('scriptawTableColumn')
  public name!: string;

  /** Function to get class for cells in this column. */
  @Input()
  public cellClassesGetter?: (row: unknown, cellName: string) => string[];

  /** Text for the header if TableHeaderDirective not provided. */
  @Input()
  public headerText?: string;

  /** Width of column. */
  @Input()
  public width?: string;

  /** Get cell template. */
  @ContentChild(TableCellDirective)
  public cell?: TableCellDirective;

  /** Get header template. */
  @ContentChild(TableHeaderDirective)
  public header?: TableHeaderDirective;
}
