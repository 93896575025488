import { Directive, ElementRef, Input, OnInit } from '@angular/core';

/**
 * Custom table cell directive.
 * This directive is required for applying custom styles for base-table cells.
 */
@Directive({
  selector: '[scriptawCustomTableCell]',
})
export class CustomTableCellDirective implements OnInit {

  /** Table cell class. */
  @Input()
  public cellClass = '';

  public constructor(private readonly element: ElementRef) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    const cellElement = this.element.nativeElement.parentElement as HTMLTableCellElement | null;
    if (cellElement && this.cellClass) {
      cellElement.classList.add(this.cellClass);
    }
  }
}
