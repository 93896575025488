<scriptaw-header [links]="links$ | async"></scriptaw-header>
<div class="container">
  <div class="breadcrumbs">
    <xng-breadcrumb separator=">">
      <ng-container *xngBreadcrumbItem="let breadcrumb; let last = last; let first = first; let count = count">
        <div
          *ngIf="count > 1"
          class="breadcrumb-text"
          [class.first-breadcrumb]="first"
          [class.last-breadcrumb]="last">
          {{ breadcrumb }}
        </div>
      </ng-container>
    </xng-breadcrumb>
  </div>
  <router-outlet></router-outlet>
  <scriptaw-footer></scriptaw-footer>
</div>
