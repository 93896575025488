import { Injectable } from '@angular/core';

import { Industry } from '../../models/industry';

import { NaupaCodeWithDescription } from '../../models/naupa-code-with-description';

import { NaupaCodeWithDescriptionDto } from './dto/naupa-code-with-description-dto';
import { IMapperFromDto } from './mappers';
import { Naupa2CodeMapper } from './naupa-2-code.mapper';
import { PropertyTypeMapper } from './property-type.mapper';
import { StateMapper } from './state.mapper';

/** Mapper for NaupaCodeWithDescription. */
@Injectable({ providedIn: 'root' })
export class NaupaCodeWithDescriptionMapper implements IMapperFromDto<NaupaCodeWithDescriptionDto, NaupaCodeWithDescription> {
  public constructor(
    private readonly stateMapper: StateMapper,
    private readonly naupaCodeMapper: Naupa2CodeMapper,
    private readonly propertyTypeMapper: PropertyTypeMapper,
  ) {}

  /** @inheritdoc */
  public fromDto(dto: NaupaCodeWithDescriptionDto): NaupaCodeWithDescription {
    return new NaupaCodeWithDescription({
      id: dto.id,
      naupaCodeId: dto.naupa2_code,
      naupaCode: this.naupaCodeMapper.fromDto(dto.naupa2_code_data),
      propertyTypeId: dto.property_type,
      propertyType: this.propertyTypeMapper.fromDto(dto.property_type_data),
      industryIds: dto.industries,
      industries: dto.industries_data.map(industry => new Industry({
        id: industry.id,
        name: industry.name,
      })),
      jurisdictionIds: dto.jurisdictions,
      jurisdictions: dto.jurisdictions_data.map(state => this.stateMapper.fromDto(state)),
      matrixIds: dto.matrices,
      matrices: dto.matrices_data.map(matrix => ({
        id: matrix.id,
        name: matrix.name,
      })),
    });
  }
}
