import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { AccessTierLevel } from '@scriptac/common/core/enums/access-tier-level';
import { UserType } from '@scriptac/common/core/enums/user-type';
import { TableColumnInfo } from '@scriptac/common/core/models/column-info';
import { ReportingTemplate } from '@scriptac/common/core/models/reporting-template';
import { filterNull } from '@scriptac/common/core/rxjs/filter-null';
import { listenControlChanges } from '@scriptac/common/core/rxjs/listen-control-changes';
import { onMessageOrFailed } from '@scriptac/common/core/rxjs/on-message-or-failed';
import { CurrentUserService } from '@scriptac/common/core/services/current-user.service';
import { DialogUtilsService } from '@scriptac/common/core/services/dialog-utils.service';
import { StateFilterQueryParamsService } from '@scriptac/common/core/services/mappers/state-filter-query-params.mapper';
import { NotificationService } from '@scriptac/common/core/services/notifications.service';
import { ReportingMechanicsFilters, ReportingDataService } from '@scriptac/common/core/services/reporting-data.service';
import { DestroyableComponent, takeUntilDestroy } from '@scriptac/common/core/utils/destroyable';
import { createTrackByPropertyFunction } from '@scriptac/common/core/utils/track-by-property';
import { BehaviorSubject, Observable, Subject, combineLatest } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap, switchMapTo, tap } from 'rxjs/operators';
import { ExportFileFormat } from '@scriptac/common/core/enums/export-file-format';
import { MatrixExportService } from '@scriptac/common/core/services/matrix-export.service';
import { ReportingMatrixExportFilters } from '@scriptac/common/core/models/reporting-matrix-export-filters';

import { routePaths } from '../../../../route-paths';
import {
  ReportingTemplateEditData,
  ReportingTemplateEditDialogComponent,
} from '../../../admin-reporting/reporting-template-edit-dialog/reporting-template-edit-dialog.component';
import { ALL_OPTION_ID } from '../states-autocomplete/states-autocomplete.component';
import { MatrixExportDialogComponent, MatrixExportDialogData } from '../matrix-export-dialog/matrix-export-dialog.component';

/**
 * Reporting Templates table component.
 */
@Component({
  selector: 'scriptaw-reporting-templates-table.component',
  templateUrl: './reporting-templates-table.component.html',
  styleUrls: ['./reporting-templates-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroyableComponent()
export class ReportingTemplatesTableComponent implements OnInit {
  /** Route paths. */
  public readonly routePaths = routePaths;

  /** Filters form. */
  public readonly filtersForm = this.fb.groupTyped<ReportingMechanicsFilters>({
    states: [undefined],
  });

  /** Filters. */
  public readonly filters$ = listenControlChanges<ReportingMechanicsFilters>(this.filtersForm);

  /** Is loading. */
  public readonly isLoading$ = new BehaviorSubject(false);

  private readonly triggerChange$ = new Subject();

  /** Reporting templates. */
  public readonly reportingTemplate$ = this.triggerChange$.pipe(
    startWith(null),
    switchMapTo(this.filters$),
    tap(() => this.isLoading$.next(true)),
    switchMap(filters => this.reportingService.getReportingTemplates(filters)),
    onMessageOrFailed(() => this.isLoading$.next(false)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /** Track by function. */
  public readonly trackById = createTrackByPropertyFunction<ReportingTemplate>('id');

  /** Table columns. */
  public readonly tableColumns$ = this.userService.currentUser$.pipe(
    map(user => {
      const baseColumns: TableColumnInfo[] = [
        { name: 'jurisdiction', sort: null, headerText: 'Jurisdiction', width: '30%' },
        { name: 'viewData', sort: null, headerText: 'Reporting Data', width: '30%' },
      ];

      if (user?.appUserData?.currentAccessTier?.tier !== AccessTierLevel.Tier1) {
        baseColumns.push({ name: 'downloadTemplate', sort: null, headerText: 'Current State Handbook', width: '30%' });
      }

      return baseColumns;
    }),
  );

  /** Check user has admin role. */
  public readonly isAdmin$ = this.userService.currentUser$.pipe(
    map(user => user?.userType === UserType.Admin),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /** View data link. */
  public readonly viewDataLink$ = this.isAdmin$.pipe(
    map(isAdmin => {
      if (isAdmin) {
        return routePaths.adminReportingData;
      }
      return routePaths.reportingData;
    }),
  );

  /** Whether show export button according to user tier. */
  public readonly showExportButton$ = this.createShowExportButton();

  public constructor(
    private readonly fb: FormBuilder,
    private readonly reportingService: ReportingDataService,
    private readonly dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly stateFilterQueryParamsMapper: StateFilterQueryParamsService,
    private readonly dialogUtilsService: DialogUtilsService,
    private readonly notificationService: NotificationService,
    private readonly userService: CurrentUserService,
    private readonly matrixExportService: MatrixExportService,
  ) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    const states = this.route.snapshot.queryParamMap.get('states');
    if (states) {
      this.filtersForm.patchValue({
        states: this.stateFilterQueryParamsMapper.fromQueryParams(states),
      });
    }
  }

  /**
   * Edit template.
   * @param template Template.
   */
  public editTemplate(template: ReportingTemplate | null): void {
    this.dialog.open<ReportingTemplateEditDialogComponent, ReportingTemplateEditData, ReportingTemplate>(
      ReportingTemplateEditDialogComponent,
      {
        width: '500px',
        data: {
          template,
        },
      },
    ).afterClosed().pipe(
      filterNull(),
    ).subscribe(() => {
      this.triggerChange$.next(undefined);
      this.notificationService.showSuccess('Template successfully saved');
    });
  }

  /**
   * Delete template.
   * @param template Template.
   */
  public deleteTemplate(template: ReportingTemplate): void {
    this.dialogUtilsService.openConfirmationModal('Are you sure you want to delete this template?')
      .pipe(
        filter(Boolean),
        switchMap(() => this.reportingService.deleteTemplate(template.id)),
        takeUntilDestroy(this),
      ).subscribe(() => {
        this.triggerChange$.next(undefined);
        this.notificationService.showSuccess('Template successfully deleted');
      });
  }

  /**
   * Get view data query params.
   * @param template Template.
   */
  public getViewDataQueryParams(template: ReportingTemplate): Params {
    return {
      states: this.stateFilterQueryParamsMapper.toQueryParams([template.state]),
    };
  }

  /**
   * Export results.
   * @param fileFormat Export file format.
   */
  public exportResults(fileFormat: ExportFileFormat): void {
    const jurisdictionIds = this.filtersForm.controls.states?.value?.reduce<number[]>((acc, state) => {
      if (state.id === ALL_OPTION_ID) {
        return acc;
      }
      return acc.concat(state.id);
    }, []) ?? [];

    this.dialog.open<MatrixExportDialogComponent, MatrixExportDialogData<ReportingMatrixExportFilters>>(MatrixExportDialogComponent, {
      width: '500px',
      data: {
        filters: {
          fileFormat,
          jurisdictionIds,
        },
        getMatrixExportResult: jobId => this.matrixExportService.getReportingMatrixExportResult(jobId),
        startMatrixExport: args => this.matrixExportService.startReportingMatrixExport(args),
      },
      disableClose: true,
    });
  }

  private createShowExportButton(): Observable<boolean> {
    return combineLatest([this.userService.hasThirdTier$, this.isAdmin$]).pipe(
      map(([hasThirdTier, isAdmin]) => hasThirdTier || isAdmin),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }
}
