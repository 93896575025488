import { Component } from '@angular/core';
import { IconsService } from '@scriptac/common/core/services/icons.service';
import { BreadcrumbService } from 'xng-breadcrumb';

/** Base app component. */
@Component({
  selector: 'scriptaw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  // We need this as https://github.com/udayvunnam/xng-breadcrumb/issues/18#issuecomment-546668857
  public constructor(
    public readonly breadcrumbService: BreadcrumbService,
    iconService: IconsService,
  ) {
    iconService.registerIcons();
  }
}
