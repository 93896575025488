import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Data,
  Route,
  Router,
  UrlTree,
} from '@angular/router';
import { UserType } from '@scriptac/common/core/enums/user-type';
import { CurrentUserService } from '@scriptac/common/core/services/current-user.service';
import { NotificationService } from '@scriptac/common/core/services/notifications.service';
import { mainPageByUserType, routePaths } from 'projects/web/src/app/route-paths';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

/**
 * Guard that checks user type.
 *
 * Available roles should be in data.userTypes field. If none userTypes specified, no users can enter.
 *
 * {
 *   path: 'users',
 *   canActivate: [RestrictRolesGuard],
 *   data: {
 *     userTypes: [UserType.Admin, UserType.Assistant],
 *   },.
 *   ...,
 * },.
 *
 * If in route data specified "permissionErrorMessage" key, this message will show if access denied.
 */
@Injectable({
  providedIn: 'root',
})
export class RestrictUserTypesGuard implements CanActivate, CanLoad {
  public constructor(
    protected readonly currentUserService: CurrentUserService,
    private readonly notificationService: NotificationService,
    protected readonly router: Router,
  ) {}

  /**
   * Get user types.
   *
   * @param data Represents static data associated with a particular route.
   */
  protected getUserTypes(data?: Data): UserType[] {
    return data?.userTypes || [];
  }

  /**
   * Determine if route could be achieved.
   * @param route Activated route.
   */
  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.commonRouteActivateOrLoadCheck(route);
  }

  /**
   * Determine if route could be loaded.
   * @param route Route.
   */
  public canLoad(route: Route): Observable<boolean | UrlTree> {
    return this.commonRouteActivateOrLoadCheck(route);
  }

  /**
   * Check that user accept terms.
   *
   * @param userTypes User type.
   * @param data Represents static data associated with a particular route.
   */
  protected checkRole(userTypes: UserType[]): Observable<boolean | UrlTree> {
    return this.currentUserService.currentUser$.pipe(
      first(),
      map(user => {
        const userType = user?.userType;
        if (userType && userTypes.includes(userType)) {
          return true;
        }

        this.notificationService.showPrimary('You do not have access to this page.');
        const redirectLink = userType ? mainPageByUserType[userType] : routePaths.login;
        // Access denied, return user to home page
        return this.router.createUrlTree(redirectLink);
      }),
    );
  }

  /**
   * Common function to check for route activate or route load.
   * @param route Activated Route or Route.
   */
  private commonRouteActivateOrLoadCheck(
    route: ActivatedRouteSnapshot | Route,
  ): Observable<boolean | UrlTree> {
    const types = this.getUserTypes(route.data);
    return this.checkRole(types);
  }
}
