import { Injectable } from '@angular/core';

import { LawsListFilters } from '../../models/laws-list-filters';
import { stringifyListByProp } from '../../utils/stringifyListByProp';
import { Naupa2Code } from '../../models/naupa-2-code';
import { State } from '../../models/state';

import { IMapperToDto } from './mappers';
import { LawsListFiltersDto } from './dto/laws-list-filters-dto';

type LawsQueryParams = Partial<Record<keyof LawsListFilters, string>>;

/** Mapper for laws list filters. */
@Injectable({ providedIn: 'root' })
export class LawsListFiltersMapper implements IMapperToDto<LawsListFiltersDto, LawsListFilters> {

  /** @inheritdoc */
  public toDto(data: LawsListFilters): LawsListFiltersDto {
    const params: LawsListFiltersDto = {};

    if (data.states?.length) {
      params.jurisdiction__in = stringifyListByProp(data.states, 'id');
    }

    if (data.propertyCodes?.length) {
      params.naupa2_codes = stringifyListByProp(data.propertyCodes, 'id');
    }

    if (data.matrixes?.length) {
      params.matrix__in = data.matrixes.join(',');
    }

    if (data.holderTypes?.length) {
      params.holder_types = stringifyListByProp(data.holderTypes, 'id');
    }

    return params;
  }

  /**
   * To query params.
   * @param data Filters.
   */
  public toQueryParams(data: LawsListFilters): LawsQueryParams {
    return {
      // Add state to query params as 'stateId:stateGeonameCode'
      // Example: `states=1:AR,2:CA,3:AL`
      states: data.states?.length ?
        data.states?.map(state => `${state.id}:${state.geonameCode}`).join(';') :
        undefined,
      // Add naupa2Code to query params as 'codeId:codeName'
      // Example: `naupa2Codes=1:AC01,2:AC02,3:AC03`
      propertyCodes: data.propertyCodes?.length ?
        data.propertyCodes?.map(code => `${code.id}:${code.code}`).join(';') :
        undefined,
    };
  }

  /**
   * To query params.
   * @param data Filters.
   */
  public fromQueryParams(data: LawsQueryParams): LawsListFilters {
    const propertyCodes = data.propertyCodes?.split(';').map(code => {
      const [id, name] = code.split(':');
      return new Naupa2Code({
        id: Number(id),
        code: name,
        // We don't need other fields for display, so we omit them.
      } as any);
    });
    const states = data.states?.split(';').map(state => {
      const [id, geonameCode] = state.split(':');
      return new State({
        id: Number(id),
        geonameCode,
        // We don't need other fields for display, so we omit them.
      } as any);
    });

    return {
      states,
      propertyCodes,
    };
  }

}
