import { Injectable } from '@angular/core';

import { MatrixSearchName } from '../../models/matrix-search-name';

import { IMapperFromDto } from './mappers';
import { MatrixSearchNameDto } from './dto/matrix-search-name-dto';

/** Mapper for matrix search name. */
@Injectable({ providedIn: 'root' })
export class MatrixSearchNameMapper implements IMapperFromDto<MatrixSearchNameDto, MatrixSearchName> {
  /** @inheritdoc */
  public fromDto(data: MatrixSearchNameDto): MatrixSearchName {
    return new MatrixSearchName({
      id: data.id,
      name: data.name,
    });
  }
}
