/**
 * Implements memoization for functions similar to pure {@link: Pipe}.
 * Replaces getter with its calculated value upon first call or keeps track of last call arguments and returned
 * value for function, skipping calculation when arguments are strictly the same.
 * @param _target Target.
 * @param propertyKey Property key.
 * @param descriptor Property descriptor.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function Pure<T>(
  _target: Object,
  propertyKey: string,
  { enumerable, value }: TypedPropertyDescriptor<T>,
): TypedPropertyDescriptor<T> {
  if (typeof value !== 'function') {
    throw new Error('Pure can only be used with functions or getters');
  }

  const original = value;

  return {
    enumerable,
    get(): T {
      let previousArgs: ReadonlyArray<unknown> = [];
      let previousResult: T;

      const patched = (...args: Array<unknown>): T => {
        if (
          previousArgs.length === args.length &&
          args.every((arg, index) => arg === previousArgs[index])
        ) {
          return previousResult;
        }

        previousArgs = args;
        previousResult = original(...args);

        return previousResult;
      };

      Object.defineProperty(this, propertyKey, {
        value: patched,
      });

      return patched as any;
    },
  };
}
