import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

const MIN_COLUMN_WIDTH = 100;

/**
 * Auto resize column directive.
 */
@Directive({
  selector: '[scriptacAutoResizeColumn]',
})
export class AutoResizeColumnDirective implements AfterViewInit {

  /** Table element. */
  @Input()
  public tableElement?: HTMLElement;

  /** Column name. */
  @Input()
  public colName = '';

  /** Column width. */
  @Input()
  public columnWidth = MIN_COLUMN_WIDTH;

  private get columnHeaderElement(): HTMLElement | null {
    return this.tableElement
      ?.querySelector(`th[data-colname="${this.colName}"`)
      ?.querySelector('scriptac-resizable') ??
      null;
  }

  public constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly renderer2: Renderer2,
  ) { }

  /** @inheritdoc */
  public ngAfterViewInit(): void {
    setTimeout(() => {
      // I select this count according to displaying styles. We can change it if necessary.
      const WORDS_MAX_LENGTH = 30;

      const element = this.elementRef.nativeElement;
      const wordsLength = element.textContent?.split(' ').length ?? null;

      const isCustomWidth = this.columnWidth !== MIN_COLUMN_WIDTH;

      if (this.columnHeaderElement && wordsLength !== null && wordsLength > WORDS_MAX_LENGTH && !isCustomWidth) {
        this.renderer2.setStyle(this.columnHeaderElement, 'min-width', '500px');
      }
      // We need this timeout to ensure all components are rendered.
    }, 1000);
  }
}
