import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, mapTo, shareReplay, switchMap, tap } from 'rxjs/operators';

import { UserProfile } from '../models/user-profile';
import { filterNull } from '../rxjs/filter-null';
import { AccessTierLevel } from '../enums/access-tier-level';

import { AppConfigService } from './app-config.service';
import { AuthDto } from './mappers/dto/auth-dto';
import { UserProfileDto } from './mappers/dto/user-profile-dto';
import { UserProfileMapper } from './mappers/user-profile-mapper';
import { TokenService } from './token.service';

/** Service for manage current user data. */
@Injectable({ providedIn: 'root' })
export class CurrentUserService {
  private readonly profileUrl = new URL('users/users/profile/', this.config.apiUrl).toString();

  private readonly userValue$ = new ReplaySubject<UserProfile | null>(1);

  private readonly userFromStorage$ = this.tokenService.token$.pipe(
    switchMap(token => {
      if (!token) {
        return throwError('Unauthorized');
      }
      return this.getCurrentUser();
    }),
    catchError(() => this.logout().pipe(mapTo(null))),
  );

  /**
   * Current user.
   * Emits `null` if current user is not authenticated.
   */
  public readonly currentUser$ = merge(this.userFromStorage$, this.userValue$).pipe(shareReplay(1));

  /** Is current user authenticated. */
  public readonly isAuthenticated$ = this.currentUser$.pipe(
    map(user => user !== null),
    shareReplay(1),
  );

  /** Whether user has third tier. */
  public readonly hasThirdTier$ = this.currentUser$.pipe(
    map(currentUser => currentUser?.appUserData?.currentAccessTier?.tier === AccessTierLevel.Tier3),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  /**
   * @constructor
   * @param http Http service.
   * @param config Config service.
   * @param userMapper UserMapper service.
   * @param tokenService TokenService service.
   */
  public constructor(
    private readonly http: HttpClient,
    private readonly config: AppConfigService,
    private readonly userMapper: UserProfileMapper,
    private readonly tokenService: TokenService,
  ) { }

  /**
   * Get current user info.
   * @returns User.
   */
  public getCurrentUser(): Observable<UserProfile> {
    return this.http.get<UserProfileDto>(this.profileUrl).pipe(
      filterNull(),
      map(response => this.userMapper.fromDto(response)),
    );
  }

  /** Update user profile. */
  public updateUserData(): Observable<void> {
    return this.getCurrentUser().pipe(
      tap(profile => this.userValue$.next(profile)),
      mapTo(void 0),
    );
  }

  /**
   * Set token and user data.
   * @param authDto Auth user data.
   */
  public completeAuthorizationProcess(authDto: AuthDto): Observable<UserProfile> {
    return this.tokenService
      .setToken({
        value: authDto.token,
        expiry: authDto.expiry,
      })
      .pipe(
        map(() => this.userMapper.fromDto(authDto.user)),
        tap(user => this.userValue$.next(user)),
        filterNull(),
      );
  }

  /** Remove the token and clear the current user. */
  public logout(): Observable<void> {
    return this.tokenService.clear().pipe(tap(() => this.userValue$.next(null)));
  }
}
