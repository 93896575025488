import { Injectable } from '@angular/core';
import {
  AbstractControlTyped,
  FormArrayTyped,
  FormBuilder,
  FormControlTyped,
  FormGroupTyped,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ScriptaValidators } from '@scriptac/common/shared/validators/scripta-validators';

import { MatrixFieldType } from '../../enums/matrix-field-type';
import { RevisionStatus } from '../../enums/revision-status';
import { Law } from '../../models/law';
import { MatrixField } from '../../models/matrix-field';
import { MatrixFieldChoice } from '../../models/matrix-field-choice';
import { MatrixValueEdit, MatrixValueEditControlType } from '../../models/matrix-value';
import { Naupa2Code } from '../../models/naupa-2-code';
import { HolderType } from '../../models/holder-type';
import { Revision, RevisionEdit } from '../../models/revision';
import { calcDynamicValue } from '../../utils/calc-dynamic-value';

/** Revision edit form. */
export interface RevisionEditForm {
  /** Revision params. */
  readonly params: RevisionParamsForm;
  /** Revision values. */
  readonly values: MatrixValueEdit[];
}

export type RevisionParamsForm = Omit<RevisionEdit, 'values'>;

interface RevisionEditFormInitParams {
  /** Law. */
  readonly law: Law;
  /** Revision. */
  readonly revision: Revision | null;
  /** Matrix fields. This field is optional, if not passed data from law will be used. */
  readonly matrixFields?: MatrixField[];
}

/**
 * Matrix revision edit form utils service.
 */
@Injectable({
  providedIn: 'root',
})
export class MatrixRevisionFormUtilsService {

  public constructor(
    private readonly fb: FormBuilder,
  ) { }

  /**
   * Create revision params form.
   * @param params Params.
   */
  public createRevisionEditForm(params: RevisionEditFormInitParams): FormGroupTyped<RevisionEditForm> {
    return this.fb.groupTyped<RevisionEditForm>({
      params: this.createRevisionParamsForm(params.law, params.revision),
      values: this.createRevisionValuesFormArray(params.matrixFields ?? params.law.matrix?.fields ?? [], params.revision),
    });
  }

  /**
   * Get revision values.
   * @param formValue Form array values.
   * @param revision Revision.
   */
  public getRevisionEditValue(formValue: RevisionEditForm, revision: Revision | null): RevisionEdit {
    const mappedValues = formValue.values.map(value => this.mapValueFormToDto(value, revision));
    const { params } = formValue;
    return {
      ...params,
      effectiveSince: params.effectiveSince ?? null,
      effectiveTill: params.effectiveTill ?? null,
      values: mappedValues,
    };
  }

  /**
   * Create Law Revision values form array.
   * @param matrixFields Matrix.
   * @param revision Revision.
   */
  public createRevisionValuesFormArray(matrixFields: MatrixField[], revision: Revision | null): FormArrayTyped<MatrixValueEdit> {
    return this.fb.array(
      matrixFields.map(field => this.mapFieldToFormControl(field, revision)),
    ) as FormArrayTyped<MatrixValueEdit>;
  }

  /**
   * Get mapped matrix values.
   * @param values Matrix values.
   * @param revision Revision.
   */
  public getMappedMatrixValues(values: MatrixValueEdit[], revision?: Revision | null): MatrixValueEdit[] {
    return values.map(value => this.mapValueFormToDto(value, revision));
  }

  private createRevisionParamsForm(law: Law, revision: Revision | null): FormGroupTyped<RevisionParamsForm> {
    return this.fb.groupTyped<RevisionParamsForm>({
      status: [
        revision?.status ?? RevisionStatus.Pending, [
          Validators.required,
          control => this.updateStatusRelatedValidations(control as AbstractControlTyped<RevisionStatus>),
          control => this.applyStatusChangeSideEffect(control as AbstractControlTyped<RevisionStatus>),
        ],
      ],
      effectiveSince: [revision?.effectiveSince ?? null],
      effectiveTill: [revision?.effectiveTill ?? null],
      law: [law.id],
      reasonForChange: [revision?.reasonForChange ?? null, Validators.required],
    });
  }

  private mapFieldToFormControl(field: MatrixField, revision: Revision | null): FormGroupTyped<MatrixValueEdit> {
    const controlName = MatrixFieldType.toControlName(field.fieldType);
    const matrixValue = revision?.values.find(val => val.field.name === field.name);
    const controlValue = matrixValue ? calcDynamicValue(matrixValue) : null;

    const valueForm = this.fb.groupTyped<MatrixValueEdit>({
      field: [field],
      [controlName]: this.getFormControlByFieldType(field, controlValue),
      note: [matrixValue?.note ?? ''],
    });

    return valueForm;
  }

  private getFormControlByFieldType(
    field: MatrixField,
    initValue: MatrixValueEditControlType | null,
  ): FormControlTyped<MatrixValueEditControlType> {
    const INTERNAL_TYPES: Record<
      MatrixFieldType,
      FormControlTyped<MatrixValueEditControlType>
    > = {
      [MatrixFieldType.Naupa2Code]: this.fb.controlTyped<Naupa2Code[]>([]),
      [MatrixFieldType.Text]: this.fb.controlTyped<string>(''),
      [MatrixFieldType.Dormancy]: this.fb.controlTyped<string>(''),
      [MatrixFieldType.AlternativeDormancy]: this.fb.controlTyped<string>(''),
      [MatrixFieldType.Bool]: this.fb.controlTyped<boolean>(false),
      [MatrixFieldType.Date]: this.fb.controlTyped<Date | null>(null),
      [MatrixFieldType.SingleChoice]: this.fb.controlTyped<MatrixFieldChoice | null>(null),
      [MatrixFieldType.MultiChoice]: this.fb.controlTyped<MatrixFieldChoice[]>([]),
      [MatrixFieldType.HolderType]: this.fb.controlTyped<HolderType[]>([]),
    };
    const control = INTERNAL_TYPES[field.fieldType];

    if (initValue) {
      control.setValue(initValue);
    }

    return control;
  }

  private mapValueFormToDto(value: MatrixValueEdit, revision?: Revision | null): MatrixValueEdit {
    const valueFromRevision = revision ? revision.values.find(val => val.field.id === value.field.id) : null;
    const { fieldType } = value.field;
    return {
      boolValue: fieldType === MatrixFieldType.Bool ? value.boolValue : undefined,
      singleChoiceValue: fieldType === MatrixFieldType.SingleChoice ? value.singleChoiceValue : undefined,
      multipleChoicesValues: fieldType === MatrixFieldType.MultiChoice ? value.multipleChoicesValues : undefined,
      dateValue: fieldType === MatrixFieldType.Date ? value.dateValue : undefined,
      textValue: fieldType === MatrixFieldType.Text ? value.textValue : undefined,
      value: fieldType === MatrixFieldType.Dormancy ? value.value : undefined,
      alternativeValue: fieldType === MatrixFieldType.AlternativeDormancy ? value.alternativeValue : undefined,
      field: value.field,
      id: valueFromRevision ? valueFromRevision.id : undefined,
      naupa2CodeValues: value.field.fieldType === MatrixFieldType.Naupa2Code ? value.naupa2CodeValues : undefined,
      holderTypeValues: value.field.fieldType === MatrixFieldType.HolderType ? value.holderTypeValues : undefined,
      note: value.note,
    };
  }

  private applyStatusChangeSideEffect(control: AbstractControlTyped<RevisionStatus>): null {
    const formGroup = control.parent as FormGroupTyped<RevisionParamsForm>;
    if (!formGroup) {
      return null;
    }

    const status = control.value;
    const { effectiveSince, effectiveTill } = formGroup.controls;
    if (status === RevisionStatus.Pending) {
      effectiveSince.disable();
      effectiveTill.disable();
    } else {
      effectiveSince.enable();
      effectiveTill.enable();
    }

    return null;
  }

  private updateStatusRelatedValidations(control: AbstractControlTyped<RevisionStatus>): null {
    const formGroup = control.parent as FormGroupTyped<RevisionParamsForm>;
    if (!formGroup) {
      return null;
    }

    const status = control.value;
    const { effectiveSince, effectiveTill } = formGroup.controls;

    const effectiveSinceValidators = this.createEffectiveSinceValidators(status);
    const effectiveTillValidators = this.createEffectiveTillValidators(status);

    effectiveSince.setValidators(effectiveSinceValidators);
    effectiveTill.setValidators(effectiveTillValidators);

    effectiveSince.updateValueAndValidity();
    effectiveTill.updateValueAndValidity();

    return null;
  }

  private createEffectiveSinceValidators(status: RevisionStatus): ValidatorFn[] {
    if (status === RevisionStatus.Current || status === RevisionStatus.Prior) {
      return [Validators.required];
    }

    if (status === RevisionStatus.Enacted) {
      return [Validators.required, ScriptaValidators.minDateValidator(new Date(), 'The date should be in the future')];
    }

    return [];
  }

  private createEffectiveTillValidators(status: RevisionStatus): ValidatorFn[] {
    if (status === RevisionStatus.Prior) {
      return [Validators.required];
    }

    return [];
  }
}
