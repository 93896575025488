import {
  ChangeDetectionStrategy,
  Component,
  Input, Output,
  EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { listenControlChanges } from '@scriptac/common/core/rxjs/listen-control-changes';
import { map, skip, tap } from 'rxjs/operators';
import { DestroyableComponent, takeUntilDestroy } from '@scriptac/common/core/utils/destroyable';
import { MatrixColumnInfo } from '@scriptac/common/core/models/matrix-column-info';
import { createTrackByPropertyFunction } from '@scriptac/common/core/utils/track-by-property';

/**
 * Multi select with search for columns filter.
 */
@Component({
  selector: 'scriptaw-columns-filter',
  templateUrl: './columns-filter-select.component.html',
  styleUrls: ['./columns-filter-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroyableComponent()
export class ColumnsFilterSelectComponent {
  private readonly allOptions$ = new BehaviorSubject<MatrixColumnInfo[]>([]);

  /**
   * Search control.
   */
  public readonly searchControl = new FormControl('');

  /**
   * Select form control.
   */
  public readonly selectFormControl = new FormControl([]);

  /**
   * Filtered options by column name and search text.
   */
  public readonly filteredOptions$ = combineLatest([
    this.allOptions$,
    listenControlChanges<string>(this.searchControl),
  ]).pipe(
    map(([allOptions, search]) => allOptions ?
      allOptions.filter(option => this.compareOptionWithSearch(option, search)) : []),
  );

  /**
   * Track list by elements` name.
   */
  public readonly trackBy = createTrackByPropertyFunction<MatrixColumnInfo>('name');

  /** All options. */
  @Input()
  public set options(val: MatrixColumnInfo[] | null) {
    if (val && JSON.stringify(val) !== JSON.stringify(this.allOptions$.value)) {
      this.allOptions$.next(val);
      this.selectFormControl.setValue(val.filter(opt => opt.shouldDisplay));
    }
  }

  /** Emitter for filter changes. */
  @Output()
  public filterChange = new EventEmitter();

  public constructor() {
    listenControlChanges<MatrixColumnInfo[]>(this.selectFormControl).pipe(
      skip(1),
      tap(options => {
        const result = this.prepareOutput(options);
        this.filterChange.emit(result);
      }),
      takeUntilDestroy(this),
    ).subscribe();
  }

  /**
   * Compare two columns by name.
   * @param option1 Column 1.
   * @param option2 Column 2.
   */
  public columnComparisonFunction(option1: MatrixColumnInfo, option2: MatrixColumnInfo): boolean {
    return option1.name === option2.name;
  }

  private compareOptionWithSearch(option: MatrixColumnInfo, searchText: string): boolean {
    return option.headerText?.toLowerCase().includes(searchText.toLowerCase()) ?? false;
  }

  private prepareOutput(selectedColumns: MatrixColumnInfo[]): MatrixColumnInfo[] {
    const selectedColumnNames = selectedColumns?.map(col => col.name);
    const mergedSavedAndSelected = this.allOptions$.value?.map(col => ({
      ...col,
      shouldDisplay: selectedColumnNames?.includes(col.name) ?? true,
    })) ?? [];
    return mergedSavedAndSelected;
  }
}
