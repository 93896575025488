import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Dialog component. */
@Component({
  selector: 'scriptac-app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  /** Dialog title. */
  @Input()
  public title = '';
}
