import { Injectable } from '@angular/core';

import { CompanyUsersParams } from '../../models/company-users.params';

import { CompanyUsersParamsDto } from './dto/company-users-params-dto';
import { IMapperToDto } from './mappers';

/** Mappers for company users params. */
@Injectable({ providedIn: 'root' })
export class CompanyUsersParamsMapper
implements IMapperToDto<CompanyUsersParamsDto, CompanyUsersParams> {
  /** @inheritdoc */
  public toDto(data: CompanyUsersParams): CompanyUsersParamsDto {
    return {
      company_users: data.userIdList,
    };
  }
}
