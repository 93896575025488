/** Common constants. */
export const DEFAULT_THROTTLE_TIME = 500;
export const DEFAULT_DEBOUNCE_TIME = 300;

export const PHONE_MASK = '(000) 000-0000';

export const REPORTING_DATE_FORMAT = 'dd MMM';

export const EDITOR_CONFIG = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ size: ['small', false, 'large', 'huge'] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ font: [] }],
    ['link'],
  ],
};

export const HTML_TAG_REGEX = /<[^>]*>/g;
