<ng-container *ngLet="isAdmin$ | async as isAdmin">
  <mat-divider></mat-divider>
  <header class="header">
    <div>
      <h3>Reporting Data</h3>
      <h4 *ngIf="statesNames$ | async as states">{{ states }}</h4>
    </div>
    <div class="buttons">
      <scriptaw-export-menu-buttons
        *ngIf="showExportButton$ | async"
        (selectExportFileFormat)="exportResults($event)"
      ></scriptaw-export-menu-buttons>
      <ng-container *ngIf="isAdmin">
        <a
          *ngIf="matrix$ | async as matrix"
          mat-flat-button
          class="custom-icon-button"
          type="button"
          title="Configure Matrix"
          [routerLink]="routePaths.matrixConfigure(matrix.id)"
        >
          <span class="button-content">
            <mat-icon svgIcon="edit"></mat-icon>
            <span>Configure Matrix</span>
          </span>
        </a>
        <button
          mat-flat-button
          class="custom-icon-button"
          type="button"
          title="Add Law"
          (click)="addLaw()"
        >
          <span class="button-content">
            <mat-icon svgIcon="add"></mat-icon>
            <span>Add Law</span>
          </span>
        </button>
      </ng-container>
    </div>
  </header>
  <mat-divider></mat-divider>

  <ng-container *ngLet="matrixLaws$ | async as matrixLaws">
    <form *ngIf="isAdmin" class="filters-form" [formGroup]="filtersForm">
      <scriptac-label labelText="Jurisdiction" class="jurisdiction-select">
        <scriptaw-states-autocomplete
          formControlName="states"
          [needAllOption]="true"
        ></scriptaw-states-autocomplete>
      </scriptac-label>
    </form>

    <scriptaw-horizontal-matrix
      *ngLet="matrix$ | async as matrix"
      [matrixData]="matrixLaws"
      [matrixId]="matrix?.id || null"
      [showStateColumn]="true"
      [dateFormat]="dateFormat"
      [getElementValuesList]="getValuesListFromLaw"
      [columns]="columns$ | async"
      [loading]="tableManager.listLoading$ | async"
      [showFixedColumn]="isAdmin || shouldDisplayViewHistoryColumn(matrixLaws)"
      (columnsUpdate)="saveUpdatedColumns($event)"
    >
      <ng-template #stateCellTemplate let-element>
        {{ element.state.name }}
      </ng-template>

      <ng-template #fixedCellTemplate let-element>
        <div *ngIf="isAdmin; else regularUserActions" class="admin-buttons">
          <button
            mat-flat-button
            class="custom-icon-button"
            type="button"
            title="Add Revision"
            [disabled]="tableManager.listLoading$ | async"
            (click)="openRevisionDialog(element)"
          >
            <span class="button-content">
              <mat-icon svgIcon="add"></mat-icon>
              Add Revision
            </span>
          </button>
          <a
            mat-flat-button
            title="View History"
            class="custom-icon-button"
            [routerLink]="routePaths.matrixRevision(element.matrixId, element.id)"
          >
            <span class="button-content">
              <mat-icon svgIcon="history"></mat-icon>
              View History
            </span>
          </a>
          <button
            mat-flat-button
            title="Delete"
            class="custom-icon-button"
            (click)="deleteLaw(element)"
          >
            <span class="button-content delete">
              <mat-icon svgIcon="trash"></mat-icon>
              Delete
            </span>
          </button>
        </div>

        <ng-template #regularUserActions>
          <a
            *ngIf="shouldDisplayViewHistory(element)"
            mat-flat-button
            title="View History"
            class="custom-icon-button"
            [routerLink]="routePaths.resultDetails(element.id)"
          >
            <span class="button-content">
              <mat-icon svgIcon="history"></mat-icon>
              View History
            </span>
          </a>
        </ng-template>
      </ng-template>
    </scriptaw-horizontal-matrix>

    <mat-paginator
      *ngIf="tableManager.pagePagination$ | async as pagination"
      color="primary"
      [pageSize]="pagination.pageSize"
      [pageIndex]="pagination.page"
      [length]="pagination.totalCount"
      [hidePageSize]="true"
      (page)="paginationChanged($event)"
    >
    </mat-paginator>

    <scriptac-page-loader *ngIf="matrixLaws === null"></scriptac-page-loader>
  </ng-container>
</ng-container>
