/**
 * Industry.
 */
export class Industry {
  /** Id. */
  public readonly id: number;
  /** Name. */
  public readonly name: string;

  public constructor(data: IndustryInitArgs) {
    this.id = data.id;
    this.name = data.name;
  }
}

type IndustryInitArgs = Industry;
