<h2> {{ isEdit ? 'Edit Template' : 'Add Template' }}</h2>

<form [formGroup]="form" (ngSubmit)="submitForm()">
  <scriptac-label labelText="Jurisdiction" class="full-width">
    <mat-select formControlName="jurisdictionId" placeholder="Select">
      <mat-option *ngFor="let state of states$ | async; trackBy: trackById" [value]="state.id">
        {{ state.name }}
      </mat-option>
    </mat-select>
  </scriptac-label>
  <scriptac-label class="upload-file" labelText="Template">
    <a *ngIf="form.value.templateUrl" class="link" download="Template" [href]="form.value.templateUrl">
      <span>Download</span>
    </a>
    <button
      mat-button
      type="button"
      color="primary"
      (click)="fileInput.click()">
      Upload
    </button>
    <input #fileInput hidden type="file" (change)="uploadFile($event)">
    <input hidden formControlName="templateUrl">
  </scriptac-label>

  <div class="buttons">
    <button mat-flat-button color="warn" type="button" (click)="onClose()">Cancel</button>
    <button mat-flat-button color="accent" type="submit">Save</button>
  </div>
</form>

