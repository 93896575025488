import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FetchListOptions } from '../../models/fetch-list-options';

import { DefaultFiltersMapper } from './default-filters.mapper';

import { IMapperToDto } from './mappers';
import { PaginationOptionsMapper } from './pagination-options.mapper';
import { SortMapper } from './sort.mapper';

/**
 * Mapper for HttpParams.
 */
@Injectable({ providedIn: 'root' })
export class HttpParamsMapper<T = {}> implements IMapperToDto<HttpParams, FetchListOptions<T>> {
  /**
   * @constructor
   * @param paginationMapper Mapper for pagination.
   * @param sortMapper Mapper for sort.
   * @param defaultFiltersMapper Default mapper for filters.
   */
  public constructor(
    private readonly paginationMapper: PaginationOptionsMapper,
    private readonly sortMapper: SortMapper,
    private readonly defaultFiltersMapper: DefaultFiltersMapper<T>,
  ) {}

  /** @inheritDoc */
  public toDto<TDto = {}>(
    options: FetchListOptions<T>,
    customFiltersMapper?: IMapperToDto<TDto, T>,
  ): HttpParams {
    const filtersMapper = customFiltersMapper ?? this.defaultFiltersMapper;

    return new HttpParams({
      fromObject: {
        ...(options.pagination ? this.paginationMapper.toDto(options.pagination) : {}),
        ...(options.sort ? this.sortMapper.toDto(options.sort) : {}),
        ...(options.filters ? filtersMapper.toDto(options.filters) : {}),
      },
    });
  }
}
