import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';

import { Company, CompanyAppUser, CompanyEditData } from '../../models/company';

import { AccessTierMapper } from './access-tier.mapper';
import { AppUserMapper } from './app-user.mapper';
import { CityMapper } from './city.mapper';
import { CompanyCreationUserDto, CompanyDto, CompanyEditDto } from './dto/company-dto';
import { extractErrorMessage, ValidationErrorDto } from './dto/validation-error-dto';
import { IMapperFromDto, IMapperToDto, IValidationErrorMapper } from './mappers';

/** Mapper for company. */
@Injectable({ providedIn: 'root' })
export class CompanyMapper
implements
    IMapperFromDto<CompanyDto, Company>,
    IMapperToDto<CompanyEditDto, CompanyEditData>,
    IValidationErrorMapper<CompanyEditDto, CompanyEditData> {
  /**
   * @constructor
   * @param appUserMapper User mapper.
   * @param cityMapper City mapper.
   * @param accessTierMapper Tier mapper.
   */
  public constructor(
    private readonly appUserMapper: AppUserMapper,
    private readonly cityMapper: CityMapper,
    private readonly accessTierMapper: AccessTierMapper,
  ) {}

  /** @inheritdoc */
  public toDto(data: CompanyEditData): CompanyEditDto {
    return {
      name: data.name,
      owner: data.owner ?? null,
      owner_creation_data: data.ownerData && data.owner == null ? {
        first_name: data.ownerData.firstName,
        last_name: data.ownerData.lastName,
        email: data.ownerData.email,
        phone: data.ownerData.phone,
      } : undefined,
      city: data.cityData.id,
      access_tier: this.accessTierMapper.toDto(data.accessTier),
      address: data.address,
      zipcode: data.zipcode,
      company_users: data.usersData.map(u => u.id),
      is_active: data.isActive,
    };
  }

  /** @inheritdoc */
  public fromDto(dto: CompanyDto): Company {
    return new Company({
      id: dto.id,
      name: dto.name,
      owner: dto.owner,
      isActive: dto.is_active,
      ownerData: dto.owner_data ? this.appUserMapper.fromDto(dto.owner_data) : undefined,
      city: dto.city,
      cityData: this.cityMapper.fromDto(dto.city_data),
      accessTier: this.accessTierMapper.fromDto(dto.access_tier),
      address: dto.address,
      zipcode: dto.zipcode,
      users: dto.company_users,
      usersData: dto.company_users_data.map(user => ({
        id: user.id,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phone: user.phone,
      })),
    });
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<CompanyEditDto>,
  ): EntityValidationErrors<CompanyEditData> {
    return {
      name: extractErrorMessage(errorDto.name),
      address: extractErrorMessage(errorDto.address),
      accessTier: extractErrorMessage(errorDto.access_tier),
      cityData: extractErrorMessage(errorDto.city),
      zipcode: extractErrorMessage(errorDto.zipcode),
      usersData: extractErrorMessage(errorDto.company_users),
      newOwner: errorDto.owner_creation_data ? this.validationErrorFromOwnerDataDto(errorDto.owner_creation_data as any) : undefined,
    };
  }

  /** @inheritdoc */
  public validationErrorFromOwnerDataDto(errorDto: ValidationErrorDto<CompanyCreationUserDto>): EntityValidationErrors<CompanyAppUser> {
    const validationErrors: EntityValidationErrors<CompanyAppUser> = {
      firstName: extractErrorMessage(errorDto.first_name),
      lastName: extractErrorMessage(errorDto.last_name),
      email: extractErrorMessage(errorDto.email),
      phone: extractErrorMessage(errorDto.phone),
    };
    return validationErrors;
  }
}
