import { NullObject } from '@scriptac/common/core/models/null-object';

/** StateData. */
export class State {
  /** Id. */
  public id: number;
  /** Name. */
  public name: string;
  /** Geo name code. */
  public geonameCode: string;

  /**
   * @constructor
   * @param data Constructor init data.
   */
  public constructor(data: StateConstructorData) {
    this.id = data.id;
    this.name = data.name;
    this.geonameCode = data.geonameCode;
  }
}

/** Nullable state data. */
export class NullState extends State implements NullObject {
  /** Is null check. */
  public readonly isNull: boolean = true;

  public constructor(
    data: StateConstructorData = {
      id: -1,
      name: '',
      geonameCode: '',
    },
  ) {
    super(data);
  }
}

export type StateConstructorData = State;
