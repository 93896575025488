import { Directive, Input, OnInit } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { DestroyableComponent, takeUntilDestroy } from '@scriptac/common/core/utils/destroyable';
import { Observable, BehaviorSubject, NEVER, of } from 'rxjs';
import { switchMap, tap, map, startWith } from 'rxjs/operators';

import { EntityValidationErrors } from '../../core/models/app-error';
import { ValidationErrorCode } from '../../core/models/validation-error-code';

/**
 * Form control directive to display API validation errors.
 * Set errors for a host control according to provided errors.
 * If the name of control the same as name of model property then you don't have to provide path.
 * Otherwise you have to provide path.
 */
@DestroyableComponent()
@Directive({
  // eslint-disable-next-line max-len
  selector: '[ngModel][scriptacValidation],[formControl][scriptacValidation],[formControlName][scriptacValidation],[formArrayName][scriptacValidation]',
})
export class AppValidationDirective implements OnInit {
  /**
   * Errors.
   * Simple value of an Observable.
   */
  @Input()
  public set tmpcValidation(value: EntityValidationErrors<unknown> | null) {
    this.errorsChange$.next(value);
  }

  /**
   * Path to certain error in `errors`. If not specific then `path` of `NgControl` will be used.
   */
  @Input()
  public path?: string[];

  /**
   * Errors.
   * Simple value of an Observable.
   */
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('scriptacValidation')
  public set errors(value: EntityValidationErrors<any> | null) {
    this.errorsChange$.next(value);
  }

  private errorsChange$ = new BehaviorSubject<EntityValidationErrors<unknown> | string | null>(
    null,
  );

  public constructor(private ngControl: NgControl) {}

  /** @inheritdoc */
  public ngOnInit(): void {
    this.createInvalidationStream().pipe(takeUntilDestroy(this)).subscribe();
  }

  private createInvalidationStream(): Observable<unknown> {
    const { ngControl } = this;
    const errorMessage$ = this.errorsChange$.pipe(
      switchMap(errorOrStream => {
        // Errors could be provided as an object or as an Observable.
        if (errorOrStream == null) {
          return of(null);
        }
        return of(errorOrStream);
      }),
      map(errors => {
        // If this is an exactly error message, then just use it.
        if (typeof errors === 'string') {
          return errors;
        }

        // If specific path provided then use it otherwise use path of NgControl.
        const path = this.path ?? ngControl.path ?? [];
        return this.extractError(errors, path);
      }),
    );

    // Display error and hide it if value was changed.
    return errorMessage$.pipe(
      switchMap(errorMessage => {
        const { control } = ngControl;
        if (control == null || ngControl.valueChanges == null) {
          const controlId = ngControl.name ?? ngControl.path?.join(',');
          throw new Error(`Control and valueChanges could not be null: ${controlId}`);
        }
        if (errorMessage == null) {
          // Run validators to reset current error.
          this.updateControlError(control, null);
          return NEVER;
        }
        const valueWhenError = control.value;
        return ngControl.valueChanges.pipe(
          startWith(valueWhenError),
          tap(value => {
            // If value the same for that we got this error, then display it otherwise hide it.
            const controlErrorMessage = value === valueWhenError ? errorMessage : null;
            this.updateControlError(control, controlErrorMessage);
          }),
        );
      }),
    );
  }

  /**
   * Update error of certain control. Reset validation error if error is null.
   * @param control Certain control.
   * @param error Error to display.
   */
  private updateControlError(control: AbstractControl, error: string | null): void {
    if (error == null) {
      // If not value then remove it from control if presented.
      if (control.errors != null) {
        delete control.errors[ValidationErrorCode.AppError];
      }
      return;
    }
    control.setErrors({
      ...control.errors,
      [ValidationErrorCode.AppError]: {
        message: error,
      },
    });
    control.markAsDirty();
    control.markAsTouched();
  }

  /**
   * Extract error of the current control.
   * @param error Errors object or certain error.
   * @param path Path to a certain error in the `error` object.
   */
  private extractError(
    error: EntityValidationErrors<unknown> | string | null,
    path: string[],
  ): string | null {
    if (error == null) {
      return null;
    }
    if (path.length === 0) {
      if (!(typeof error === 'string')) {
        // eslint-disable-next-line max-len
        console.warn('Could not extract error message for form control because path is empty and error is not a string. Use [path] input to provide some specific path');
        return null;
      }
      return error as string;
    }
    if (typeof error === 'string') {
      return error;
    }
    const propertyName = path[0];
    const propertyError = error[propertyName as keyof EntityValidationErrors<unknown>] as
      | EntityValidationErrors<unknown>
      | string
      | null;
    if (propertyError == null) {
      return null;
    }
    const nestedPath = path.slice(1);
    return this.extractError(propertyError, nestedPath);
  }
}
