import { Injectable } from '@angular/core';

import { State } from '../../models/state';

import { StateDto } from './dto/state-dto';
import { IMapperFromDto } from './mappers';

/** Mapper for StateData. */
@Injectable({ providedIn: 'root' })
export class StateMapper implements IMapperFromDto<StateDto, State> {
  /** @inheritdoc */
  public fromDto(data: StateDto): State {
    return new State({
      id: data.id,
      name: data.name,
      geonameCode: data.geoname_code,
    });
  }
}
