<div *ngFor="let form of formControls; let i = index" [formGroup]="form">
  <fieldset class="field-control" [ngSwitch]="form.value.field.fieldType">
    <ng-container
      *ngSwitchCase="matrixFieldType.Text"
    >
      <span class="label">{{ form.value.field.name }}</span>
      <quill-editor [modules]="editorOptions" formControlName="textValue" #editor (click)="editor.quillEditor.focus()">
      </quill-editor>
      <scriptac-form-control-validation-message controlName="textValue">
      </scriptac-form-control-validation-message>
    </ng-container>

    <scriptac-label
      *ngSwitchCase="matrixFieldType.Dormancy"
      [labelText]="form.value.field.name"
    >
      <input formControlName="value" placeholder="Dormancy Value">
    </scriptac-label>

    <scriptac-label
      *ngSwitchCase="matrixFieldType.AlternativeDormancy"
      [labelText]="form.value.field.name"
    >
      <input formControlName="alternativeValue" placeholder="Alternative Dormancy Value">
    </scriptac-label>

    <scriptac-label
      *ngSwitchCase="matrixFieldType.Bool"
      [labelText]="form.value.field.name"
    >
      <mat-slide-toggle class="toggle" formControlName="boolValue">
        Enable
      </mat-slide-toggle>
    </scriptac-label>

    <scriptac-label
      *ngSwitchCase="matrixFieldType.Date"
      [labelText]="form.value.field.name"
    >
      <input formControlName="dateValue" placeholder="Choose a Date" [matDatepicker]="picker" />
      <mat-datepicker-toggle matSuffix class="datepicker-toggle" [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker color="accent" #picker></mat-datepicker>
    </scriptac-label>

    <ng-container *ngSwitchCase="matrixFieldType.MultiChoice">
      <scriptac-label [labelText]="form.value.field.name">
        <mat-select
          multiple
          formControlName="multipleChoicesValues"
          placeholder="Multiple Choice"
          [compareWith]="choiceComparisonFunction">
          <mat-option *ngFor="let choice of form.value.field.choices" [value]="choice">
            <quill-view class="small-quill" [content]="choice.name"></quill-view>
          </mat-option>
        </mat-select>
      </scriptac-label>
      <div class="form-field-buttons">
        <button mat-button type="button" class="open-list-button" (click)="openEditField(form.value.field)">
          Open list / Add new
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="matrixFieldType.SingleChoice">
      <scriptac-label [labelText]="form.value.field.name">
        <mat-select
          formControlName="singleChoiceValue"
          placeholder="Single Choice"
          [compareWith]="choiceComparisonFunction">
          <mat-option [value]="null">-- Empty --</mat-option>
          <mat-option *ngFor="let choice of form.value.field.choices" [value]="choice">
            <quill-view class="small-quill" [content]="choice.name"></quill-view>
          </mat-option>
        </mat-select>
      </scriptac-label>
      <div class="form-field-buttons">
        <button mat-button type="button" class="open-list-button" (click)="openEditField(form.value.field)">
          Open list / Add new
        </button>
      </div>
    </ng-container>

    <scriptac-label
      *ngSwitchCase="matrixFieldType.Naupa2Code"
      [labelText]="form.value.field.name"
    >
      <scriptaw-naupa-code-autocomplete
        formControlName="naupa2CodeValues"
        placeholder="NAUPA II Codes"
      >
      </scriptaw-naupa-code-autocomplete>
    </scriptac-label>

    <scriptac-label
      *ngSwitchCase="matrixFieldType.HolderType"
      [labelText]="form.value.field.name"
    >
      <scriptaw-holder-type-autocomplete
        formControlName="holderTypeValues"
        placeholder="Holder Types"
      ></scriptaw-holder-type-autocomplete>
    </scriptac-label>

    <scriptac-label labelText="Comment">
      <input formControlName="note" placeholder="Note" />
    </scriptac-label>
    <mat-divider></mat-divider>
  </fieldset>
</div>
