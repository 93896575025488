import { Injectable } from '@angular/core';

import { IMapperToDto } from './mappers';
import { SearchFilterDto } from './dto/search-filter-dto';

/** Mapper for search filter. */
@Injectable({ providedIn: 'root' })
export class SearchFilterMapper implements IMapperToDto<SearchFilterDto, string> {

  /** @inheritdoc */
  public toDto(searchText: string): SearchFilterDto {
    const params: SearchFilterDto = {};

    if (searchText) {
      params.search = searchText;
    }

    return params;
  }
}
