/* eslint-disable @typescript-eslint/naming-convention */
/**
 * Environment variables passed from webpack (custom-webpack-config.js).
 */
declare const $ENV: {
  /** Production build or not. */
  PRODUCTION: string;

  /** Backend API url. */
  API_URL: string;

  /* Scripta URL. */
  SCRIPTA_URL: string;
};

export const environment = {
  production: $ENV.PRODUCTION === 'TRUE',
  apiUrl: $ENV.API_URL,
  scriptaUrl: $ENV.SCRIPTA_URL ?? 'https://scripta.llc',
};
