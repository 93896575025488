import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FetchListOptions } from '../models/fetch-list-options';
import { PagedList } from '../models/paged-list';
import { HolderType } from '../models/holder-type';

import { AppConfigService } from './app-config.service';
import { PagedListDto } from './mappers/dto/paged-list-dto';
import { HttpParamsMapper } from './mappers/http-params-mapper';
import { PagedListMapper } from './mappers/paged-list.mapper';
import { HolderTypeDto } from './mappers/dto/holder-type-dto';
import { HolderTypeMapper } from './mappers/holder-type.mapper';
import { SearchFilterMapper } from './mappers/search-filter.mapper';

/** Service to get holder type data. */
@Injectable({ providedIn: 'root' })
export class HolderTypeService {

  private readonly apiUrl = new URL('laws/holder-type/', this.config.apiUrl).toString();

  public constructor(
    private readonly config: AppConfigService,
    private readonly paramsMapper: HttpParamsMapper,
    private readonly listMapper: PagedListMapper,
    private readonly holderTypeMapper: HolderTypeMapper,
    private readonly http: HttpClient,
    private readonly searchFilterMapper: SearchFilterMapper,
  ) {
  }

  /**
   * Get holder type paged list.
   * @param options Fetch options.
   */
  public getHolderTypePagedList(options: FetchListOptions): Observable<PagedList<HolderType>> {
    const params = this.paramsMapper.toDto(options, this.searchFilterMapper);

    return this.http.get<PagedListDto<HolderTypeDto>>(this.apiUrl, { params }).pipe(
      map(response => this.listMapper.fromDto(
        response,
        this.holderTypeMapper,
        options.pagination,
      )),
    );
  }
}
