import { Component, ChangeDetectionStrategy } from '@angular/core';

/**
 * Welcome page layout.
 */
@Component({
  selector: 'scriptaw-welcome-page-layout',
  templateUrl: './welcome-page-layout.component.html',
  styleUrls: ['./welcome-page-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomePageLayoutComponent {
}
