<input
  type="text"
  [formControl]="control"
  [matAutocomplete]="auto"
  [placeholder]="placeholder"
/>
<mat-autocomplete #auto="matAutocomplete" [displayWith]="readable">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="loading"
  ></mat-progress-bar>
  <mat-option
    *ngFor="let option of optionsList$ | async; trackBy:trackBy"
    [value]="option"
  >
    {{ readable(option) }}
  </mat-option>
</mat-autocomplete>
