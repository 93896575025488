import { Injectable } from '@angular/core';

import { Naupa2CodesFilters } from '../../models/naupa2-code-filters';

import { Naupa2CodesFiltersDto } from './dto/naupa2-code-filters-dto';
import { IMapperToDto } from './mappers';

/** Mapper for Naupa 2 Codes filters. */
@Injectable({ providedIn: 'root' })
export class Naupa2CodesFiltersMapper implements IMapperToDto<Naupa2CodesFiltersDto, Naupa2CodesFilters> {

  /** @inheritdoc */
  public toDto(data: Naupa2CodesFilters): Naupa2CodesFiltersDto {
    const params: Naupa2CodesFiltersDto = {};

    if (data.search) {
      params.search = data.search;
    }

    if (data.jurisdictionIds && data.jurisdictionIds.length > 0) {
      // Filter id -1 to avoid errors and get all list options
      params.rules__jurisdictions = data.jurisdictionIds.filter(id => id !== -1);
    }

    if (data.propertyTypes && data.propertyTypes.length > 0) {
      params.rules__property_type = data.propertyTypes.map(type => type.id);
    }

    if (data.categoryIds && data.categoryIds.length > 0) {
      params.category__in = data.categoryIds.join(',');
    }

    if (data.hideRestricted) {
      params.is_restricted = false;
    }

    return params;
  }
}
