import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { QuillModule } from 'ngx-quill';

import { DialogComponent } from './components/dialog/dialog.component';
import { LabelComponent } from './components/label/label.component';
import {
  FormControlValidationMessageComponent,
} from './components/form-control-validation-message/form-control-validation-message.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { AppValidationDirective } from './directives/app-validation.directive';
import { NgLetDirective } from './directives/ng-let.directive';
import { MaterialModule } from './material.module';
import { ListDividerPipe } from './pipes/list-divider.pipe';
import { MatrixCellValuePipe } from './pipes/matrix-cell-value.pipe';
import { ResizableColumnComponent } from './components/resizable-column/resizable-column.component';
import { ResizableColumnDirective } from './components/resizable-column/resizable-column.directive';
import { SafePipe } from './pipes/safe.pipe';
import { AutoResizeColumnDirective } from './components/resizable-column/auto-resize-column.directive';

const EXPORTED_DECLARATIONS = [
  AppValidationDirective,
  ValidationMessageComponent,
  FormControlValidationMessageComponent,
  DialogComponent,
  PageLoaderComponent,
  NgLetDirective,
  ListDividerPipe,
  LabelComponent,
  MatrixCellValuePipe,
  AutocompleteComponent,
  ResizableColumnComponent,
  ResizableColumnDirective,
  AutoResizeColumnDirective,
  SafePipe,
];

const EXPORTED_MODULES = [
  MaterialModule,
  NgxMaskModule,
  InfiniteScrollModule,
  BreadcrumbModule,
  NgxMatSelectSearchModule,
  QuillModule,
];

/**
 * Common shared module.
 * Contains reusable components, directives and etc can be shared through all apps in the project.
 */
@NgModule({
  declarations: [...EXPORTED_DECLARATIONS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ...EXPORTED_MODULES,
  ],
  exports: [...EXPORTED_DECLARATIONS, ...EXPORTED_MODULES],
})
export class CommonSharedModule {}
