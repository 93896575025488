import { MatrixField } from '../models/matrix-field';
import { MatrixColumnInfo } from '../models/matrix-column-info';

/**
 * Map matrix fields list to list of table column info.
 * @param fields Matrix fields list.
 */
export function createMatrixColumns(fields: readonly MatrixField[]): MatrixColumnInfo[] {
  return fields.map(field => ({
    name: field.name.toLowerCase(),
    sort: null,
    headerText: field.name,
    shouldDisplay: true,
    tier: field.tier,
  }));
}
