<form [formGroup]="filtersForm">
  <scriptac-label class="full-width" labelText="Search">
    <input formControlName="search" placeholder="Search" />
  </scriptac-label>
</form>

<div class="user-list">
  <mat-progress-bar *ngIf="listManager.listLoading$ | async" mode="indeterminate">
  </mat-progress-bar>

  <mat-selection-list (scroll)="onScroll($event)" [formControl]="listControl" [multiple]="false">
    <ng-container *ngIf="companies$ | async as companies; else loading">
      <ng-container *ngIf="companies.length else emptyMsg">
        <mat-list-option [value]="null">-- No company --</mat-list-option>
        <mat-list-option *ngFor="let company of companies" role="listitem" [value]="company">
          {{ company.name }}
        </mat-list-option>
      </ng-container>
    </ng-container>

    <ng-template #emptyMsg>
      <mat-list-item>No companies found</mat-list-item>
    </ng-template>

    <ng-template #loading>
      <mat-list-item>Loading...</mat-list-item>
    </ng-template>
  </mat-selection-list>
</div>

<p>Option selected: {{ listControl.value[0]?.name }}</p>

<div class="controls full-width">
  <button (click)="onClose()" type="button" mat-flat-button color="warn">Cancel</button>
  <button (click)="onConfirm()" type="button" mat-flat-button color="accent">Confirm</button>
</div>
