import { Injectable } from '@angular/core';
import { environment } from '@scriptac/environments/environment';

/**
 * App config service.
 * Provides information about current application environment configuration.
 */
@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  /** Is production environment. */
  public readonly isProduction = environment.production;

  /** API base URL. */
  public readonly apiUrl = environment.apiUrl;

  /** Scripta URL. */
  public readonly scriptaUrl = environment.scriptaUrl;
}
