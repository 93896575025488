import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExportJobStatus } from '@scriptac/common/core/enums/export-job-status';
import { ExportJob } from '@scriptac/common/core/models/export-job';
import { Observable } from 'rxjs';
import { delay, first, repeat, shareReplay, switchMap, takeWhile, tap } from 'rxjs/operators';

/**
 * Matrix export dialog data.
 */
export interface MatrixExportDialogData<TFilter extends Record<string, any>> {

  /** Filters. */
  readonly filters: TFilter;

  /** Get matrix export result. */
  getMatrixExportResult: (jobId: number) => Observable<ExportJob>;

  /** Start matrix export. */
  startMatrixExport: (args: TFilter) => Observable<ExportJob>;
}

/**
 * Matrix export dialog component.
 */
@Component({
  selector: 'scriptaw-matrix-export-dialog',
  templateUrl: './matrix-export-dialog.component.html',
  styleUrls: ['./matrix-export-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatrixExportDialogComponent {

  /** Status. */
  public readonly exportJobStatus = ExportJobStatus;

  /** Export job. */
  public readonly exportJob$ = this.data.startMatrixExport({ ...this.data.filters }).pipe(
    first(),
    switchMap(job => this.fetchExportJob(job)),
    tap(job => this.autoDownloadFile(job)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: MatrixExportDialogData<{}>,
    private readonly dialogRef: MatDialogRef<MatrixExportDialogComponent>,
  ) { }

  /** Close dialog. */
  public onClose(): void {
    this.dialogRef.close();
  }

  private fetchExportJob(exportJob: ExportJob): Observable<ExportJob> {
    return this.data.getMatrixExportResult(exportJob.id).pipe(
      delay(1000),
      repeat(),
      takeWhile(job => job.status !== ExportJobStatus.Finished && job.status !== ExportJobStatus.Error, true),
    );
  }

  private autoDownloadFile(job: ExportJob): void {
    if (job.status !== ExportJobStatus.Finished || !job.fileLink) {
      return;
    }

    // Start file download automatically.
    // If browser security policy restricts it then 'isAutoDownload' value will be empty (falsy).
    const isAutoDownload = window.open(job.fileLink, '_blank');

    if (isAutoDownload) {
      this.onClose();
    }
  }
}
