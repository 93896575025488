import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { AppUser, AppUserEdit } from '../../models/app-user';
import { FetchListOptions } from '../../models/fetch-list-options';
import { PagedList } from '../../models/paged-list';
import { TierStats } from '../../models/tier-stat';
import { AppConfigService } from '../app-config.service';
import { AppErrorMapper } from '../mappers/app-error.mapper';
import { AppUserMapper } from '../mappers/app-user.mapper';
import { AppUserDto } from '../mappers/dto/app-user-dto';
import { PagedListDto } from '../mappers/dto/paged-list-dto';
import { TierStatsDto } from '../mappers/dto/tier-stats-dto';
import { HttpParamsMapper } from '../mappers/http-params-mapper';
import { PagedListMapper } from '../mappers/paged-list.mapper';
import { TierStatsMapper } from '../mappers/tier-stats.mapper';
import { UsersFiltersMapper } from '../mappers/users-filters.mapper';

/** Users filtering options. */
export interface UsersFilters {
  /** Search data. */
  readonly search?: string;
  /** Exclude users that have any company. */
  readonly hasCompany?: boolean;
  /** Exclude users that have company by company name. */
  readonly companyIdList?: number[];
  /** Is user company owner. */
  readonly isCompanyOwner?: boolean;
  /** Get all users that have company by id + all users without company. */
  readonly inCompanyOrWithout?: number[];
}

/** Service to access users api. */
@Injectable({ providedIn: 'root' })
export class AppUsersApiService {
  private readonly appUsersUrl = new URL('users/app-users/', this.config.apiUrl).toString();

  public constructor(
    private readonly http: HttpClient,
    private readonly appUserMapper: AppUserMapper,
    private readonly listMapper: PagedListMapper,
    private readonly config: AppConfigService,
    private readonly paramsMapper: HttpParamsMapper,
    private readonly usersFiltersMapper: UsersFiltersMapper,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly tierStatsMapper: TierStatsMapper,
  ) {}

  /**
   * Get users paged list.
   * @param options Pagination, sorting and filtering options.
   */
  public getUsersPagedLists(options: FetchListOptions): Observable<PagedList<AppUser>> {
    const params = this.paramsMapper.toDto(options, this.usersFiltersMapper);

    return this.http.get<PagedListDto<AppUserDto>>(this.appUsersUrl, { params }).pipe(
      map(response => this.listMapper.fromDto(response, this.appUserMapper, options.pagination)),
    );
  }

  /**
   * Get user by id.
   * @param id User id.
   */
  public getUserById(id: number): Observable<AppUser> {
    const url = new URL(`${id}/`, this.appUsersUrl).toString();

    return this.http.get<AppUserDto>(url).pipe(map(user => this.appUserMapper.fromDto(user)));
  }

  /**
   * Update user by id.
   *
   * @param id User id.
   * @param user User data.
   */
  public updateUser(id: number, user: AppUserEdit): Observable<void> {
    const url = new URL(`${id}/`, this.appUsersUrl).toString();
    const userDto = this.appUserMapper.toDto(user);

    return this.http.put<AppUserDto>(url, userDto).pipe(
      mapTo(void 0),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.appUserMapper),
    );
  }

  /**
   * Create new user.
   *
   * @param user User data.
   */
  public createUser(user: AppUserEdit): Observable<void> {
    const userDto = this.appUserMapper.toDto(user);

    return this.http.post<AppUserDto>(this.appUsersUrl, userDto).pipe(
      mapTo(void 0),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.appUserMapper),
    );
  }

  /** Get app users tier statistic. */
  public getStatistic(): Observable<TierStats> {
    const url = new URL('tiers-stats/', this.appUsersUrl).toString();

    return this.http.get<TierStatsDto>(url).pipe(
      map(stats => this.tierStatsMapper.fromDto(stats)),
    );
  }
}
