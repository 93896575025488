import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatrixValue } from '@scriptac/common/core/models/matrix-value';
import { MatrixFieldType } from '@scriptac/common/core/enums/matrix-field-type';

/** Matrix dynamic cell. */
@Component({
  selector: 'scriptaw-matrix-dynamic-cell',
  templateUrl: './matrix-dynamic-cell.component.html',
  styleUrls: ['./matrix-dynamic-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatrixDynamicCellComponent {
  /** Matrix value info. */
  @Input()
  public matrixValue: MatrixValue | null = null;

  /** Additional date format for date cell. */
  @Input()
  public dateFormat?: string;

  /**
   * Is cell with matrix value has text type.
   * @param matrixValue Matrix value info.
   */
  public isTextCell(matrixValue: MatrixValue | null): boolean {
    const filedType = matrixValue?.field.fieldType;

    return filedType === MatrixFieldType.Text ||
      filedType === MatrixFieldType.SingleChoice ||
      filedType === MatrixFieldType.MultiChoice;
  }
}
