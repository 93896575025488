import { NullObject } from './null-object';

/** Region model. */
export class Region {
  /** Region id. */
  public readonly id: number;
  /** Region name. */
  public readonly name: string;
  /** Region geoname code. */
  public readonly geonameCode?: string;

  /**
   * @constructor
   * @param data Data to fill class fields.
   */
  public constructor(data: RegionConstructorData) {
    this.id = data.id;
    this.name = data.name;
    this.geonameCode = data.geonameCode;
  }
}

/** Nullable region data. */
export class NullRegion extends Region implements NullObject {
  /** Is null check. */
  public readonly isNull: boolean = true;

  public constructor(
    data: RegionConstructorData = {
      id: -1,
      name: '',
    },
  ) {
    super(data);
  }
}

export type RegionConstructorData = Region;
