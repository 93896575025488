import { DormancyMatrix, Matrix } from './matrix';
import { State } from './state';
import { Revision } from './revision';

/** Law. */
export class Law {
  /** Law id. */
  public readonly id: number;
  /** State where the law is in effect . */
  public readonly stateId: number;
  /** State data. */
  public readonly state: State;
  /** Matrix id. */
  public readonly matrixId: number;
  /** Matrix data. */
  public readonly matrix: LawMatrixData | null;
  /** Data for current revision of law. */
  public readonly currentRevision: Revision | null;
  /** Data for pending revision of law. */
  public readonly pendingRevision: Revision | null;
  /** Data for enacted revision of law. */
  public readonly enactedRevision: Revision | null;
  /** Revisions list. */
  public readonly revisions: Revision[];

  public constructor(data: LawConstructorData) {
    this.id = data.id;
    this.stateId = data.stateId;
    this.state = data.state;
    this.matrixId = data.matrixId;
    this.matrix = data.matrix;
    this.currentRevision = data.currentRevision;
    this.pendingRevision = data.pendingRevision;
    this.enactedRevision = data.enactedRevision;
    this.revisions = data.revisions;
  }
}

/** Matrix data for law. */
export type LawMatrixData = Pick<Matrix, 'id' | 'name' | 'fields'>;

/** Law data for revision. */
export type LawRevisionData = Pick<Law, 'id' | 'state' | 'stateId'>;

/** Law edit data. */
export interface LawEdit {
  /** Law state. */
  readonly state: number;
  /** Law matrix. */
  readonly matrix: number;
}

/** Law data for search results. */
export type SearchResultsLaw = Law & {
  /** Whether law has several revisions (not only current one). */
  readonly hasHistory: boolean;
};

/** Constructor data. */
export type LawConstructorData = Law;

/**
 * Dormancy Law.
 */
export type DormancyLaw = Pick<Law, 'id' | 'state'> & {
  /** Dormancy matrix. */
  readonly matrix: DormancyMatrix;
};

export type CurrentChangeReportLaw = Pick<Law, 'id' | 'matrixId' | 'state' | 'stateId' | 'currentRevision'> & {
  readonly latestPriorRevision: Revision | null;
};

export type EnactedChangeReportLaw = Pick<Law,
  | 'id'
  | 'matrixId'
  | 'state'
  | 'stateId'
  | 'currentRevision'
  | 'enactedRevision'
>;
