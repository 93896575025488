import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { FetchListOptions } from '../../models/fetch-list-options';
import { Law, LawEdit } from '../../models/law';
import { PagedList } from '../../models/paged-list';
import { AppConfigService } from '../app-config.service';
import { AppErrorMapper } from '../mappers/app-error.mapper';
import { LawDto } from '../mappers/dto/law-dto';
import { PagedListDto } from '../mappers/dto/paged-list-dto';
import { HttpParamsMapper } from '../mappers/http-params-mapper';
import { LawMapper } from '../mappers/law.mapper';
import { PagedListMapper } from '../mappers/paged-list.mapper';
import { LawsListFiltersMapper } from '../mappers/laws-list-filters.mapper';
import { LawsListFilters } from '../../models/laws-list-filters';

/** Service for access to law API. */
@Injectable({ providedIn: 'root' })
export class LawApiService {
  private readonly lawsUrl = new URL('laws/laws/', this.config.apiUrl).toString();

  public constructor(
    private readonly lawMapper: LawMapper,
    private readonly lawsFiltersMapper: LawsListFiltersMapper,
    private readonly paramsMapper: HttpParamsMapper,
    private readonly listMapper: PagedListMapper,
    private readonly http: HttpClient,
    private readonly config: AppConfigService,
    private readonly appErrorMapper: AppErrorMapper,
  ) {}

  /**
   * Get laws paged list.
   * @param options Pagination, sorting and filtering options.
   */
  public getLawsPagedList(options: FetchListOptions): Observable<PagedList<Law>> {
    const params = this.paramsMapper.toDto(options, this.lawsFiltersMapper);

    return this.http
      .get<PagedListDto<LawDto>>(this.lawsUrl, { params })
      .pipe(
        map(response => this.listMapper.fromDto(response, this.lawMapper, options.pagination)),
      );
  }

  /**
   * Get all laws list.
   * @param options Filter options.
   */
  public getAllLaws(options: LawsListFilters): Observable<Law[]> {
    const params = new HttpParams({
      fromObject: { ...this.lawsFiltersMapper.toDto(options) },
    });

    return this.http.get<LawDto[]>(this.lawsUrl, { params }).pipe(
      map(response => response.map(dto => this.lawMapper.fromDto(dto))),
    );
  }

  /**
   * Get law by id.
   * @param id Law id.
   */
  public getLawById(id: number): Observable<Law> {
    const url = new URL(`${String(id)}/`, this.lawsUrl).toString();

    return this.http.get<LawDto>(url).pipe(
      map(law => this.lawMapper.fromDto(law)),
    );
  }

  /**
   * Update law by id.
   *
   * @param id Law id.
   * @param law Law data.
   */
  public updateLaw(id: number, law: LawEdit): Observable<Law> {
    const url = new URL(`${id}/`, this.lawsUrl).toString();
    const lawDto = this.lawMapper.toDto(law);

    return this.http.put<LawDto>(url, lawDto).pipe(
      map(dto => this.lawMapper.fromDto(dto)),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.lawMapper),
    );
  }

  /**
   * Create new law.
   *
   * @param law Law data.
   */
  public createLaw(law: LawEdit): Observable<Law> {
    const lawDto = this.lawMapper.toDto(law);

    return this.http.post<LawDto>(this.lawsUrl, lawDto).pipe(
      map(dto => this.lawMapper.fromDto(dto)),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.lawMapper),
    );
  }

  /**
   * Delete law.
   *
   * @param lawId Law id.
   */
  public deleteLaw(lawId: number): Observable<void> {
    return this.http.delete<number>(`${this.lawsUrl}${lawId}/`).pipe(
      mapTo(void 0),
    );
  }
}
