import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Container for loader spinner. */
@Component({
  selector: 'scriptac-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageLoaderComponent {}
