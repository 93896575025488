import { Component, ChangeDetectionStrategy, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyAppUser } from '@scriptac/common/core/models/company';
import { listenControlChanges } from '@scriptac/common/core/rxjs/listen-control-changes';
import {
  AppUsersApiService,
  UsersFilters,
} from '@scriptac/common/core/services/api/app-users-api.service';
import { DEFAULT_THROTTLE_TIME } from '@scriptac/common/core/utils/constants';
import { DestroyableComponent, takeUntilDestroy } from '@scriptac/common/core/utils/destroyable';
import { ListManager, InfiniteScrollListStrategy } from '@scriptac/common/core/utils/list-manager';
import { Subject } from 'rxjs';
import { map, shareReplay, tap, throttleTime } from 'rxjs/operators';

/** Dialog data. */
export interface UserSelectDialogData {
  /** Dialog mode. */
  readonly multiple: boolean;
  /** User filters.  */
  readonly userFilters?: UsersFilters;
  /** We can input users data or receive it from server. */
  readonly usersList?: CompanyAppUser[];
}

/** User select dialog. */
@Component({
  selector: 'scriptaw-user-select-dialog',
  templateUrl: './user-select-dialog.component.html',
  styleUrls: ['./user-select-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroyableComponent()
export class UserSelectDialogComponent implements OnInit {
  /** Table filters. */
  public readonly filtersForm = this.fb.groupTyped<UsersFilters>({
    search: [''],
  });

  /** Stream with filters.  */
  private readonly filters$ = listenControlChanges<UsersFilters>(this.filtersForm).pipe(
    map(filters => ({
      ...filters,
      ...this.data.userFilters,
    })),
  );

  /** Control of selected items. */
  public readonly listControl = this.fb.controlTyped<CompanyAppUser[]>([]);

  /** List manager. */
  public readonly listManager = new ListManager<CompanyAppUser, UsersFilters>({
    strategy: new InfiniteScrollListStrategy(),
    filter$: this.filters$,
  });

  /** Stream with users from dialog data. */
  private readonly localUsersStream$ = this.filters$.pipe(
    map(filters =>
      this.data.usersList?.filter(u => {
        const { search } = filters;
        if (search) {
          return u.firstName.includes(search) || u.lastName.includes(search);
        }

        return true;
      })),
    shareReplay({
      bufferSize: 1,
      refCount: true,
    }),
  );

  /** Stream with users from server. */
  private readonly serverUsersStream$ = this.listManager
    .getPaginatedItems(option => this.usersApiService.getUsersPagedLists(option))
    .pipe(
      shareReplay({
        bufferSize: 1,
        refCount: true,
      }),
    );

  /** Companies list. */
  public readonly users$ = this.data.usersList ? this.localUsersStream$ : this.serverUsersStream$;

  /** Trigger that set throttleTime for getting next data. */
  private readonly getNextDataTrigger$: Subject<void> = new Subject();

  public constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: UserSelectDialogData,
    private readonly dialogRef: MatDialogRef<UserSelectDialogComponent>,
    private readonly usersApiService: AppUsersApiService,
    private readonly fb: FormBuilder,
  ) {}

  /** @inheritdoc */
  public ngOnInit(): void {
    this.getNextDataTrigger$
      .pipe(
        throttleTime(DEFAULT_THROTTLE_TIME),
        tap(() => this.listManager.nextPage()),
        takeUntilDestroy(this),
      )
      .subscribe();
  }

  /**
   * Scroll listener.
   * @param event Event data.
   */
  public onScroll(event: Event): void {
    const target = event.target as HTMLElement;
    const isScrollOnBottom =
      target.offsetHeight + Math.ceil(target.scrollTop) >= target.scrollHeight;

    if (isScrollOnBottom) {
      this.getNextDataTrigger$.next();
    }
  }

  /** Confirm dialog. */
  public onConfirm(): void {
    this.dialogRef.close(this.data.multiple ? this.listControl.value : this.listControl.value[0]);
  }

  /** Close dialog. */
  public onClose(): void {
    this.dialogRef.close();
  }
}
