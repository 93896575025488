import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { NotificationOptions } from '@scriptac/common/core/models/notification-options';

/** Custom notification component. */
@Component({
  selector: 'scriptac-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationMessageComponent {
  /**
   * @constructor
   * @param options Notification options.
   * @param matSnackBarService Material snack bar service.
   */
  public constructor(
    @Inject(MAT_SNACK_BAR_DATA) public options: NotificationOptions,
    private readonly matSnackBarService: MatSnackBar,
  ) {}

  /** Is warning message. */
  public get isWarn(): boolean {
    return this.options.type === 'warn';
  }

  /** Close the snackbar. */
  public closeSnackbar(): void {
    this.matSnackBarService.dismiss();
  }
}
