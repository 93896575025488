import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  ChangeDetectorRef,
  Input, Output,
  EventEmitter, TrackByFunction,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormBuilder } from '@angular/forms';
import { listenControlChanges } from '@scriptac/common/core/rxjs/listen-control-changes';
import {
  LocationFilterOptions,
} from '@scriptac/common/core/services/location.service';
import { ControlValueAccessorBase } from '@scriptac/common/core/utils/control-value-accessor-base';
import { DestroyableComponent, takeUntilDestroy } from '@scriptac/common/core/utils/destroyable';
import { BehaviorSubject } from 'rxjs';
import { tap, map, skip } from 'rxjs/operators';

/** Autocomplete component. */
@Component({
  selector: 'scriptac-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
@DestroyableComponent()
export class AutocompleteComponent<T, P extends T>
  extends ControlValueAccessorBase<T> {

  /** Control for input. */
  public readonly control = this.fb.controlTyped<string | T>('');

  /** Input placeholder. */
  @Input()
  public placeholder = 'Start typing...';

  /** Helps to display option at list and in input. */
  @Input()
  public readable: (arg: T) => string = () => '';

  /** Empty value to be selected if value is empty. */
  @Input()
  public emptyValue: P | null = null;

  /** Flag for display loading. */
  @Input()
  public loading: boolean | null = false;

  /** Options list subject. */
  public optionsList$ = new BehaviorSubject<T[] | null>(null);

  /** Options list. */
  @Input()
  public set options(value: T[] | null) {
    if (value) {
      this.optionsList$.next(value);
    }
  }

  /**
   * Function for trackBy.
   * @param index Track index.
   */
  @Input()
  public trackBy: TrackByFunction<T> = (index: number) => index;

  /** Emitter for filters input changed. */
  @Output()
  public filterChanged$ = new EventEmitter<LocationFilterOptions>();

  public constructor(
    private readonly fb: FormBuilder,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);

    listenControlChanges<string | T>(this.control).pipe(
      skip(1),
      tap(value => {
        if (typeof value !== 'string') {
          this.setValue(value);
        }
      }),
      map(value => typeof value === 'string' ? value : this.readable(value)),
      tap(v => {
        if (v === '' && this.emptyValue) {
          this.control.setValue(this.emptyValue as any);
        }
      }),
      takeUntilDestroy(this),
    ).subscribe(v => this.filterChanged$.emit({ search: v }));
  }

  /** @inheritDoc */
  public afterWriteValue(): void {
    if (this.internalValue) {
      this.control.setValue(this.internalValue);
    }
  }
}
