import { Injectable } from '@angular/core';

import { MatrixFieldChoice, MatrixFieldChoiceEdit } from '../../models/matrix-field-choice';

import { MatrixFieldChoiceDto, MatrixFieldChoiceEditDto } from './dto/matrix-fields-dto';
import { IMapperFromDto, IMapperToDto } from './mappers';

/** Mapper for field choices. */
@Injectable({ providedIn: 'root' })
export class MatrixFieldChoiceMapper
implements IMapperFromDto<MatrixFieldChoiceDto, MatrixFieldChoice>, IMapperToDto<MatrixFieldChoiceEditDto, MatrixFieldChoiceEdit> {

  /** @inheritdoc */
  public toDto(data: MatrixFieldChoiceEdit): MatrixFieldChoiceEditDto {
    return {
      id: data.id ?? null,
      value: data.name,
      to_delete: data.toDelete,
    };
  }

  /** @inheritdoc */
  public fromDto(dto: MatrixFieldChoiceDto): MatrixFieldChoice {
    return {
      id: dto.id,
      name: dto.value,
    };
  }
}
