import { Directive, TemplateRef } from '@angular/core';

/**
 * Directive to mark ng-template as table-header.
 *
 * <ng-container *scriptawTableHeader="let col">
 *  {{ col|uppercase }}
 * </ng-container>.
 */
@Directive({
  selector: '[scriptawTableHeader]',
})
export class TableHeaderDirective {
  public constructor(public template: TemplateRef<any>) {}
}
