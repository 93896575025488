import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatrixFieldType } from '@scriptac/common/core/enums/matrix-field-type';
import { Law } from '@scriptac/common/core/models/law';
import { MatrixField, MatrixFieldEdit } from '@scriptac/common/core/models/matrix-field';
import { MatrixFieldChoice, MatrixFieldChoiceEdit } from '@scriptac/common/core/models/matrix-field-choice';
import { Revision } from '@scriptac/common/core/models/revision';
import { ValidationErrorCode } from '@scriptac/common/core/models/validation-error-code';
import { catchValidationError } from '@scriptac/common/core/rxjs/catch-validation-error';
import { MatrixApiService } from '@scriptac/common/core/services/api/matrix-api.service';
import { EDITOR_CONFIG } from '@scriptac/common/core/utils/constants';
import { getLawMatrixRevisionTitle } from '@scriptac/common/core/utils/get-law-matrix-revision-title';
import { toggleExecutionState } from '@scriptac/common/core/utils/toggle-execution-state';
import { BehaviorSubject, EMPTY, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';

/** Dialog data. */
export interface MatrixChoiceFieldValueSelectDialogData {
  /** Current law. */
  readonly law: Law;
  /** Revision data. */
  readonly revision: Revision | null;
  /** Matrix field. */
  readonly field: MatrixField;
  /** Selected values. */
  readonly selectedValues: readonly MatrixFieldChoice[];
}

/** Dialog result. */
export interface MatrixChoiceFieldValueSelectDialogResult {
  /** Selected values. */
  readonly selectedValues: readonly MatrixFieldChoice[];

  /** Update matrix field. */
  readonly updatedField: MatrixField;
}

/** Matrix choice field select dialog. */
@Component({
  selector: 'scriptaw-matrix-choice-field-value-select-dialog',
  templateUrl: './matrix-choice-field-value-select-dialog.component.html',
  styleUrls: ['./matrix-choice-field-value-select-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatrixChoiceFieldValueSelectDialogComponent {
  @ViewChildren(MatOption)
  public readonly optionElements?: QueryList<MatOption>;

  /** Field. */
  public readonly field$ = new BehaviorSubject(this.data.field);

  /** Is single select mode. */
  public readonly isSingleSelect = this.data.field.fieldType === MatrixFieldType.SingleChoice;

  /** Custom toolbar modules. */
  public readonly editorOptions = EDITOR_CONFIG;

  /** Select form control. */
  public readonly selectFormControl = this.fb.controlTyped<number[]>([...this.data.selectedValues.map(v => v.id)]);

  /** New value form control. */
  public readonly newValueFormControl = this.fb.controlTyped<string>('');

  /** Loading. */
  public readonly loading$ = new ReplaySubject<boolean>(1);

  public constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data: MatrixChoiceFieldValueSelectDialogData,
    private readonly dialogRef: MatDialogRef<MatrixChoiceFieldValueSelectDialogComponent, MatrixChoiceFieldValueSelectDialogResult>,
    private readonly fb: FormBuilder,
    private readonly matrixApiService: MatrixApiService,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  /** Save new value. */
  public saveNewValue(): void {
    this.newValueFormControl.markAllAsTouched();
    const newChoiceValue = this.newValueFormControl.value;
    if (!newChoiceValue?.trim().length) {
      this.newValueFormControl.setErrors({ [ValidationErrorCode.Required]: true });
    }
    if (this.newValueFormControl.invalid) {
      return;
    }

    const oldFieldValue = this.field$.value;
    const choicesList = oldFieldValue.choices.map<MatrixFieldChoiceEdit>(choice => ({
      ...choice,
      toDelete: false,
    }));

    const editField: MatrixFieldEdit = {
      ...oldFieldValue,
      choices: [
        ...choicesList,
        {
          name: newChoiceValue,
          toDelete: false,
        },
      ],
      toDelete: false,
    };

    this.matrixApiService.updateMatrixField(this.field$.value.id, editField).pipe(
      toggleExecutionState(this.loading$),
      first(),
      catchValidationError(error => {
        if (error.validationData.choices) {
          this.newValueFormControl.setErrors({
            [ValidationErrorCode.AppError]: { message: error.validationData.choices },
          });
          this.cdr.markForCheck();
        }
        return EMPTY;
      }),
    ).subscribe(newField => {
      this.field$.next(newField);

      const newChoice = newField.choices.find(c => c.name === newChoiceValue);
      if (newChoice) {
        const newValue = this.isSingleSelect ? [newChoice.id] : this.selectFormControl.value.concat(newChoice.id);
        this.selectFormControl.setValue(newValue);

        setTimeout(() => {
          this.optionElements?.find(item => item.value === newChoice.id)?.focus();
        }, 0);
      }
      this.newValueFormControl.reset('');
    });
  }

  /**
   * Handle multi select.
   * @param event Select event.
   * @param option Option.
   */
  public handleMultiSelect(event: MatCheckboxChange, option: MatrixFieldChoice): void {
    const currentValue = this.selectFormControl.value;
    if (event.checked) {
      this.selectFormControl.patchValue(currentValue.concat(option.id));
    } else {
      this.selectFormControl.patchValue(currentValue.filter(v => v !== option.id));
    }
  }

  /** Cancel dialog. */
  public closeDialog(): void {
    const updatedField = this.field$.value;
    const selectedIds = this.selectFormControl.value;
    const selectedValues = updatedField.choices.filter(v => selectedIds.includes(v.id));

    this.dialogRef.close({
      selectedValues,
      updatedField,
    });
  }

  /**
   * Get titles for related revisions.
   * @param field Field.
   * @param choice Choice.
   */
  public getRelatedRevisionTitles(field: MatrixField, choice: MatrixFieldChoice): string {
    const titles = this.data.law.revisions
      .filter(v => v.id !== this.data.revision?.id)
      .reduce<string[]>((acc, revision) => {
        const foundField = revision.values.find(v => v.fieldId === field.id);
        const isChoiceInRevision = foundField?.choicesValues.map(v => v.id).includes(choice.id) ?? false;
        if (isChoiceInRevision) {
          const revisionTitle = getLawMatrixRevisionTitle(revision, this.data.law.revisions);
          return [...acc, revisionTitle];
        }
        return acc;
      }, []);

    return titles.join(', ');
  }

  /**
   * Check whether choice is new.
   * @param choice Choice.
   */
  public checkIsNewChoice(choice: MatrixFieldChoice): boolean {
    return !this.data.field.choices.map(c => c.id).includes(choice.id);
  }
}
