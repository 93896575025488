<h2 class="header">
  {{ data.revision ? 'Edit Revision' : 'Add New Revision' }}
  <button tabindex="-1" mat-icon-button title="Close dialog" type="button" (click)="onClose()">
    <mat-icon>close</mat-icon>
  </button>
</h2>

<mat-stepper class="custom-stepper" [linear]="true">
  <mat-step [stepControl]="revisionParamsForm">
    <form [formGroup]="revisionParamsForm" (ngSubmit)="checkRevisionParams()">
      <ng-template matStepLabel>Fill out revision params</ng-template>

      <scriptac-label labelText="Status">
        <mat-select disableOptionCentering formControlName="status">
          <mat-option *ngFor="let status of revisionStatus.toArray()" [value]="status">
            {{ revisionStatus.toReadable(status) }}
          </mat-option>
        </mat-select>
      </scriptac-label>

      <scriptac-label labelText="Effective Date" class="datepicker">
        <input formControlName="effectiveSince" [matDatepicker]="sincePicker" [max]="maxSinceDate" placeholder="Start date" />
        <mat-datepicker-toggle class="datepicker-toggle" matSuffix [for]="sincePicker"></mat-datepicker-toggle>
        <mat-datepicker #sincePicker></mat-datepicker>
      </scriptac-label>

      <scriptac-label labelText="Expiration Date" class="datepicker">
        <input formControlName="effectiveTill" [matDatepicker]="tillPicker" [min]="minTillDate" placeholder="End date" />
        <mat-datepicker-toggle class="datepicker-toggle" matSuffix [for]="tillPicker"></mat-datepicker-toggle>
        <mat-datepicker #tillPicker></mat-datepicker>
      </scriptac-label>

      <scriptac-label labelText="Reason for Change">
        <mat-select formControlName="reasonForChange" placeholder="Select">
          <mat-option *ngFor="let type of reasonForChange.toArray()" [value]="type">
            {{ reasonForChange.toReadable(type) }}
          </mat-option>
        </mat-select>
      </scriptac-label>

      <div class="stepper-buttons">
        <button
          mat-button
          matStepperNext
          [disabled]="revisionParamsForm.invalid"
          type="submit">
          Next
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step>
    <ng-template matStepLabel>Fill out revision values</ng-template>

    <fieldset *ngIf="revisionTemplates?.length" class="field-control">
      <scriptac-label labelText="Use values from previous revision">
        <mat-select disableOptionCentering placeholder="Empty" [formControl]="revisionTemplateControl">
          <mat-option>Empty</mat-option>
          <mat-option *ngFor="let revision of revisionTemplates; trackBy: trackByRevisionId" [value]="revision">
            {{ getRevisionTitle(revision, revisionTemplates ?? []) }}
          </mat-option>
        </mat-select>
      </scriptac-label>
      <mat-divider></mat-divider>
    </fieldset>

    <scriptaw-matrix-revision-values-form
      [formControls]="revisionValuesFormControls"
      [stateIds]="[data.law.stateId]"
      (openFieldEdit)="openFieldEditDialog($event)"
    >
    </scriptaw-matrix-revision-values-form>

    <div class="stepper-buttons">
      <div>
        <button mat-button matStepperPrevious type="button">Back</button>
        <button mat-button matStepperNext type="button" (click)="saveRevision()">Save</button>
      </div>
      <button mat-button type="button" (click)="onClose()">Cancel</button>
    </div>
  </mat-step>
</mat-stepper>

<scriptac-page-loader *ngIf="isLoading$ | async"></scriptac-page-loader>
