import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserType } from '@scriptac/common/core/enums/user-type';
import { NavigationLink } from '@scriptac/common/core/models/navigation-link';
import { filterNull } from '@scriptac/common/core/rxjs/filter-null';
import { CurrentUserService } from '@scriptac/common/core/services/current-user.service';
import { mainPageByUserType, routePaths } from 'projects/web/src/app/route-paths';
import { map, tap } from 'rxjs/operators';
import { AccessTierLevel } from '@scriptac/common/core/enums/access-tier-level';

const LINKS_MAP: Map<UserType, NavigationLink[]> = new Map([
  [
    UserType.Admin,
    [
      {
        route: routePaths.dashboard,
        icon: 'dashboard',
        title: 'Dashboard',
      },
      {
        route: routePaths.accounts,
        icon: 'accounts',
        title: 'Accounts',
      },
      {
        route: routePaths.tracking,
        icon: 'tracking',
        title: 'Legislative Tracking',
      },
      {
        route: routePaths.adminReporting,
        icon: 'reporting',
        title: 'Reporting Mechanics',
      },
      {
        route: routePaths.adminDefinitions,
        icon: 'news',
        title: 'Definitions',
      },
      {
        route: routePaths.adminIraDistribution,
        icon: 'news',
        title: 'IRA Distribution',
      },
      {
        route: routePaths.adminPriorityRules,
        icon: 'news',
        title: 'Priority Rules',
      },
      {
        route: routePaths.adminNews,
        icon: 'news',
        title: 'News',
      },
      {
        route: routePaths.settings,
        icon: 'settings',
        title: 'Settings',
      },
    ],
  ],
  [
    UserType.User,
    [
      {
        route: routePaths.search,
        icon: 'search',
        title: 'Search',
      },
      {
        route: routePaths.tracking,
        icon: 'tracking',
        title: 'Legislative Tracking',
      },
      {
        route: routePaths.reporting,
        icon: 'reporting',
        title: 'Reporting Mechanics',
      },
      {
        route: routePaths.currentChangeReport,
        icon: 'reporting',
        title: 'Current Change Report',
      },
      {
        route: routePaths.enactedChangeReport,
        icon: 'reporting',
        title: 'Enacted Change Report',
      },
    ],
  ],
]);

/**
 * General layout component.
 */
@Component({
  selector: 'scriptaw-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent {
  public constructor(
    private readonly currentUserService: CurrentUserService,
    private readonly router: Router,
  ) {}

  /** Sidebar navigation links. */
  public readonly links$ = this.currentUserService.currentUser$.pipe(
    filterNull(),
    tap(user => {
      const { url } = this.router;
      // We have different home pages for different users. That's why we have to redirect user to his main page.
      if (url === '/') {
        this.router.navigate(mainPageByUserType[user.userType]);
      }
    }),
    map(user => {
      let links = LINKS_MAP.get(user.userType);
      if (user.appUserData?.currentAccessTier?.tier === AccessTierLevel.Tier1) {
        links = links?.filter(link => link.route !== routePaths.tracking && link.route !== routePaths.enactedChangeReport);
      }
      return links ?? [];
    }),
  );
}
