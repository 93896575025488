import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { FormBuilder, FormGroupTyped, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LawEdit } from '@scriptac/common/core/models/law';
import { NullState, State } from '@scriptac/common/core/models/state';
import { OnlyValidForm } from '@scriptac/common/core/utils/only-valid-form.decorator';
import { ScriptaValidators } from '@scriptac/common/shared/validators/scripta-validators';
import { BehaviorSubject } from 'rxjs';
import { LocationService, LocationFilterOptions } from '@scriptac/common/core/services/location.service';
import { InfiniteScrollListStrategy, ListManager } from '@scriptac/common/core/utils/list-manager';
import { shareReplay } from 'rxjs/operators';
import { createTrackByPropertyFunction } from '@scriptac/common/core/utils/track-by-property';

/** Data for dialog. */
export interface AddLawDialogData {
  /** State for law. */
  readonly matrixId: number;
}

interface FormData {
  /** State data. */
  readonly state: State;
}

/** Dialog for creating new law in matrix. */
@Component({
  selector: 'scriptaw-matrix-add-law-dialog',
  templateUrl: './matrix-add-law-dialog.component.html',
  styleUrls: ['./matrix-add-law-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatrixAddLawDialogComponent {
  /** Control for states. */
  public readonly form = this.fb.groupTyped<FormData>({
    state: [new NullState(), [Validators.required, ScriptaValidators.nullObjectRequiredValidator]],
  });

  /** Filter with text for state control. */
  public readonly stateFilter$ = new BehaviorSubject<LocationFilterOptions>({ search: '' });

  /**
   * Format state object to display view.
   * @param state State data.
   */
  public readonly stateReadable: (arg: State) => string = (state: State) => state.name;

  /** Empty state object. */
  public readonly emptyState = new NullState();

  /** States list manager. */
  public readonly statesListManager = new ListManager<State, LocationFilterOptions>({
    strategy: new InfiniteScrollListStrategy(),
    filter$: this.stateFilter$,
    pageSize: 100,
  });

  /** Track by for states. */
  public readonly trackByStateId = createTrackByPropertyFunction<State>('id');

  /** States list. */
  public readonly states$ = this.statesListManager
    .getPaginatedItems(options => this.locationService.getStatesPagedList(options)).pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

  public constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: AddLawDialogData,
    private readonly dialogRef: MatDialogRef<MatrixAddLawDialogComponent>,
    private readonly fb: FormBuilder,
    private readonly locationService: LocationService,
  ) {}

  /**
   * Handle confirm click.
   *
   * @param form Current form..
   */
  @OnlyValidForm()
  public onConfirm(form: FormGroupTyped<FormData>): void {
    const law: LawEdit = {
      state: form.value.state.id,
      matrix: this.data.matrixId,
    };

    this.dialogRef.close(law);
  }

  /** Close dialog. */
  public onClose(): void {
    this.dialogRef.close();
  }
}
