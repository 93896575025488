import { IraDistributionRule } from './ira-distribution-rule';

/** IRA Distribution. */
export class IraDistribution {
  /** Id of distribution. */
  public readonly id: number;
  /** Effective date since. */
  public readonly effectiveSince: Date | null;
  /** Effective date till. */
  public readonly effectiveTill: Date | null;
  /** Rule after RMD (Required Minimum Distribution). */
  public readonly rmdRuleAfter: IraDistributionRule;
  /** Rule before RMD (Required Minimum Distribution). */
  public readonly rmdRuleBefore: IraDistributionRule;

  public constructor(data: IraDistributionInitArgs) {
    this.id = data.id;
    this.effectiveSince = data.effectiveSince;
    this.effectiveTill = data.effectiveTill;
    this.rmdRuleBefore = data.rmdRuleBefore;
    this.rmdRuleAfter = data.rmdRuleAfter;
  }

}

export type IraDistributionInitArgs = IraDistribution;
