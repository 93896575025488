import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { AppConfigService } from './app-config.service';
/* eslint-disable @typescript-eslint/naming-convention */

type S3DestinationBucket = 'news_attachments' | 'reporting_templates';

interface S3DirectBody {
  /**
   * Destination bucket.
   */
  readonly dest: S3DestinationBucket;
  /**
   * File name.
   */
  readonly filename: string;
  /**
   * Content type.
   */
  readonly content_type: string;
}

/** Config that comes from API. */
interface S3DirectUploadInfo {
  /** Url for uploading file to S3. */
  readonly form_action: string;

  /** Configuration set up on API. */
  readonly [key: string]: any;
}

/**
 * File storage service for working with s3.
 */
@Injectable({
  providedIn: 'root',
})
export class FileStorageService {
  private readonly s3directUrl = new URL('s3/get_params/', this.config.apiUrl).toString();

  public constructor(
    private readonly http: HttpClient,
    private readonly config: AppConfigService,
  ) { }

  /**
   * Upload file to s3.
   * @param dest Destination option.
   * @param file File to upload.
   * @param filename File name.
   */
  public uploadFile(dest: S3DestinationBucket, file: Blob, filename: string): Observable<string> {
    const body = {
      dest,
      filename,
      content_type: file.type,
    } as S3DirectBody;

    const formData = new FormData();

    return this.http.post<S3DirectUploadInfo>(this.s3directUrl, body).pipe(
      switchMap(redirectInfo => {
        // Set all the configurations from redirectInfo to formData object except form_action (it is url).
        const s3body = Object.keys(redirectInfo).filter(key => key !== 'form_action').reduce((prev: FormData, cur) => {
          prev.append(cur, redirectInfo[cur]);
          return prev;
        }, formData);

        // !!! Important to add a file as the last field of form data. https://stackoverflow.com/a/15235866
        formData.append('file', file);

        // Set response type 'text' because s3 returns XML.
        // Using Object type to avoid errors connected with responseType
        const options: Object = { observe: 'body', responseType: 'text' };

        return this.http.post<string>(redirectInfo.form_action, s3body, options);
      }),
      map(resultXml => {
        const parser = new DOMParser();
        const xmlDocument = parser.parseFromString(resultXml, 'application/xml').documentElement;
        return xmlDocument.querySelector('Location')?.textContent ?? '';
      }),
    );
  }

}
