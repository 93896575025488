import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';

import { FetchListOptions } from '../../models/fetch-list-options';
import { PagedList } from '../../models/paged-list';
import { Revision, RevisionEdit } from '../../models/revision';
import { AppConfigService } from '../app-config.service';
import { AppErrorMapper } from '../mappers/app-error.mapper';
import { PagedListDto } from '../mappers/dto/paged-list-dto';
import { RevisionDto } from '../mappers/dto/revision-dto';
import { HttpParamsMapper } from '../mappers/http-params-mapper';
import { PagedListMapper } from '../mappers/paged-list.mapper';
import { RevisionMapper } from '../mappers/revision.mapper';
import { RevisionsFiltersMapper } from '../mappers/revisions-filters.mapper';

/** Revision filters. */
export interface RevisionsFilters {
  /** Which list of law revisions return. */
  readonly laws?: number[];
  /** Search query. */
  readonly search?: string;
}

/** Service to revision API access. */
@Injectable({ providedIn: 'root' })
export class RevisionApiService {

  private readonly revisionUrl = new URL('laws/revisions/', this.config.apiUrl).toString();

  public constructor(
    private readonly config: AppConfigService,
    private readonly http: HttpClient,
    private readonly paramsMapper: HttpParamsMapper,
    private readonly revisionMapper: RevisionMapper,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly revisionsFiltersMapper: RevisionsFiltersMapper,
    private readonly listMapper: PagedListMapper,
  ) { }

  /**
   * Create revision.
   *
   * @param data Creation data.
   */
  public createRevision(data: RevisionEdit): Observable<Revision> {
    const revisionDto = this.revisionMapper.toDto(data);

    return this.http.post<RevisionDto>(this.revisionUrl, revisionDto).pipe(
      map(dto => this.revisionMapper.fromDto(dto)),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.revisionMapper),
    );
  }

  /**
   * Update revision data by id.
   *
   * @param id Revision ID.
   * @param revision Revision edit data.
   */
  public updateRevisionById(id: number, revision: RevisionEdit): Observable<Revision> {
    const url = new URL(`${id}/`, this.revisionUrl).toString();
    const revisionDto = this.revisionMapper.toDto(revision);

    return this.http.put<RevisionDto>(url, revisionDto).pipe(
      map(dto => this.revisionMapper.fromDto(dto)),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.revisionMapper),
    );
  }

  /**
   * Get paged revisions list.
   *
   * @param options List options.
   */
  public getRevisionsPagedList(options: FetchListOptions<RevisionsFilters>): Observable<PagedList<Revision>> {
    const params = this.paramsMapper.toDto(options, this.revisionsFiltersMapper);

    return this.http
      .get<PagedListDto<RevisionDto>>(this.revisionUrl, { params })
      .pipe(
        map(response => this.listMapper.fromDto(response, this.revisionMapper, options.pagination)),
      );
  }

  /**
   * Get revision by id.
   *
   * @param id Revision id.
   */
  public getRevisionById(id: number): Observable<Revision> {
    const url = new URL(`${id}/`, this.revisionUrl).toString();

    return this.http.get<RevisionDto>(url).pipe(
      map(dto => this.revisionMapper.fromDto(dto)),
    );
  }

  /**
   * Delete revision.
   * @param id Revision id.
   */
  public deleteRevision(id: number): Observable<void> {
    const url = new URL(`${id}/`, this.revisionUrl).toString();

    return this.http.delete(url).pipe(
      mapTo(void 0),
    );
  }
}
