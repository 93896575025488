import { Injectable } from '@angular/core';

import { ExportJob } from '../../models/export-job';

import { ExportJobDto } from './dto/export-job-dto';
import { IMapperFromDto } from './mappers';

/** Mapper for Export job entity. */
@Injectable({ providedIn: 'root' })
export class ExportJobMapper implements IMapperFromDto<ExportJobDto, ExportJob> {
  /** @inheritdoc */
  public fromDto(dto: ExportJobDto): ExportJob {
    return new ExportJob({
      id: dto.id,
      status: dto.export_status,
      fileLink: dto.data_file,
      progress: {
        current: dto.progress?.info.current ?? 0,
        total: dto.progress?.info.total ?? 0,
      },
    });
  }
}
