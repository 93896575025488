import { Injectable } from '@angular/core';

import { IraDistribution } from '../../models/ira-distribution';

import { IMapper } from './mappers';
import { IraDistributionDto } from './dto/ira-distribution-dto';
import { IraDistributionRuleMapper } from './ira-distribution-rule.mapper';
import { DateMapper } from './date.mapper';

/** Mapper for IRA Distribution. */
@Injectable({ providedIn: 'root' })
export class IraDistributionMapper implements IMapper<IraDistributionDto, IraDistribution> {
  public constructor(
    private readonly iraDistributionRuleMapper: IraDistributionRuleMapper,
    private readonly dateMapper: DateMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(data: IraDistributionDto): IraDistribution {
    return new IraDistribution({
      id: data.id,
      effectiveSince: data.effective_since ? this.dateMapper.fromDto(data.effective_since) : null,
      effectiveTill: data.effective_till ? this.dateMapper.fromDto(data.effective_till) : null,
      rmdRuleBefore: this.iraDistributionRuleMapper.fromDto(data.rmd_rule_before),
      rmdRuleAfter: this.iraDistributionRuleMapper.fromDto(data.rmd_rule_after),
    });
  }

  /** @inheritdoc */
  public toDto(data: IraDistribution): IraDistributionDto {
    return {
      id: data.id,
      effective_since: data.effectiveSince ? this.dateMapper.toDto(data.effectiveSince) : null,
      effective_till: data.effectiveTill ? this.dateMapper.toDto(data.effectiveTill) : null,
      rmd_rule_before: this.iraDistributionRuleMapper.toDto(data.rmdRuleBefore),
      rmd_rule_after: this.iraDistributionRuleMapper.toDto(data.rmdRuleAfter),
    };
  }
}
