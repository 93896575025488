import { Injectable } from '@angular/core';

import { ReportingMechanicsFilters } from '../reporting-data.service';

import { ReportingTemplatesFiltersDto } from './dto/reporting-templates-filter-dto';
import { IMapperToDto } from './mappers';

/** Mapper for reporting template filters. */
@Injectable({ providedIn: 'root' })
export class ReportingTemplatesFilterMapper implements IMapperToDto<ReportingTemplatesFiltersDto, ReportingMechanicsFilters> {

  /** @inheritdoc */
  public toDto(data: ReportingMechanicsFilters): ReportingTemplatesFiltersDto {
    const params: ReportingTemplatesFiltersDto = {};

    const statesIds = data.states?.map(state => state.id).filter(id => id !== -1);
    if (statesIds && statesIds.length > 0) {
      params.jurisdiction__in = statesIds.join(',');
    }

    if (data.holderTypes && data.holderTypes.length > 0) {
      params.holder_types = data.holderTypes.map(state => state.id).join(',');
    }

    return params;
  }
}
