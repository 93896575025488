import { Pipe, PipeTransform } from '@angular/core';
import { MatrixValue } from '@scriptac/common/core/models/matrix-value';
import { MatrixFieldType } from '@scriptac/common/core/enums/matrix-field-type';
import { dateToFormattedString } from '@scriptac/common/core/utils/datetime-util';
import { stringifyListByProp } from '@scriptac/common/core/utils/stringifyListByProp';

/**
 * Pipe to transform dynamic cell matrix value to template string format.
 */
@Pipe({ name: 'matrixCellValue' })
export class MatrixCellValuePipe implements PipeTransform {

  /**
   * Util to calculate cell value according to field type to use in template.
   * @param matrixValue Value for current cell with type, name and data.
   * @param dateFormat Date cell format.
   */
  public transform(matrixValue: MatrixValue | null, dateFormat = 'MM/dd/yyyy'): string {
    if (!matrixValue) {
      return '';
    }

    switch (matrixValue.field.fieldType) {
      case MatrixFieldType.Text:
        return matrixValue.textValue;
      case MatrixFieldType.Bool:
        if (matrixValue.boolValue === null) {
          return '';
        }
        return matrixValue.boolValue ? 'Y' : 'N';
      case (MatrixFieldType.Date):
        return matrixValue.dateValue ? dateToFormattedString(matrixValue.dateValue, dateFormat) : '';
      case (MatrixFieldType.MultiChoice):
      case (MatrixFieldType.SingleChoice):
        return stringifyListByProp(matrixValue.choicesValues, 'name', ', ');
      case (MatrixFieldType.Naupa2Code):
        return stringifyListByProp(matrixValue.naupa2CodeValues, 'code', ', ');
      case (MatrixFieldType.HolderType):
        return stringifyListByProp(matrixValue.holderTypeValues, 'name', ', ');
      default:
        return matrixValue.textValue;
    }

  }
}
