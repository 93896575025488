import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

/** Dialog data. */
export interface ConfirmDialogData {
  /** Dialog msg. */
  readonly message: string;
}

/** Base app confirm dialog. */
@Component({
  selector: 'scriptaw-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  public constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: ConfirmDialogData,
    private readonly dialogRef: MatDialogRef<ConfirmDialogComponent>,
  ) {}

  /**
   * Close dialog.
   *
   * @param confirm Dialog result.
   */
  public close(confirm: boolean): void {
    this.dialogRef.close(confirm);
  }
}
