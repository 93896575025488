import { ExportJobStatus } from '../enums/export-job-status';

/**
 * Export Job.
 */
export class ExportJob {

  /** Job id. */
  public readonly id: number;

  /** Status. */
  public readonly status: ExportJobStatus;

  /** File link. */
  public readonly fileLink: string;

  /** Export job progress. */
  public readonly progress: {
    readonly current: number;
    readonly total: number;
  };

  public constructor(data: ExportJobInitArgs) {
    this.id = data.id;
    this.status = data.status;
    this.fileLink = data.fileLink;
    this.progress = data.progress;
  }
}

type ExportJobInitArgs = ExportJob;
