import { PropertyType } from './property-type';
import { State } from './state';

/** Rule between naupa 2 code and property type. */
export class Rule {
  /** Id. */
  public readonly id: number;
  /** Naupa 2 code id. */
  public readonly naupa2CodeId: number;
  /** Property type. */
  public readonly propertyTypeId: number;
  /** Property type data. */
  public readonly propertyTypeData: PropertyType | null;
  /** States for this naupa 2 code and property type. */
  public readonly stateIds: number[];
  /** States data. */
  public readonly statesData: State[] | null;

  public constructor(data: RuleInitArgs) {
    this.id = data.id;
    this.naupa2CodeId = data.naupa2CodeId;
    this.propertyTypeId = data.propertyTypeId;
    this.propertyTypeData = data.propertyTypeData;
    this.stateIds = data.stateIds;
    this.statesData = data.statesData;
  }
}

export type RuleInitArgs = Rule;
