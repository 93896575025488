import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationLink } from '@scriptac/common/core/models/navigation-link';
import { AppConfigService } from '@scriptac/common/core/services/app-config.service';
import { CurrentUserService } from '@scriptac/common/core/services/current-user.service';
import { DestroyableComponent, takeUntilDestroy } from '@scriptac/common/core/utils/destroyable';
import { routePaths } from 'projects/web/src/app/route-paths';

/** Header component. */
@Component({
  selector: 'scriptaw-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroyableComponent()
export class HeaderComponent {
  /** Links for buttons panel. */
  @Input()
  public links: NavigationLink[] | null = [];

  /** Route paths. */
  public readonly routePaths = routePaths;

  public constructor(
    private readonly currentUserService: CurrentUserService,
    private readonly appConfigService: AppConfigService,
    private readonly router: Router,
  ) { }

  /** Logout current user. */
  public logout(): void {
    this.currentUserService
      .logout()
      .pipe(takeUntilDestroy(this))
      .subscribe(() => {
        if (this.appConfigService.isProduction) {
          // Redirect to external resource.
          window.location.href = this.appConfigService.scriptaUrl;
        } else {
          this.router.navigate(routePaths.welcome);
        }
      });
  }
}
