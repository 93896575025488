import { UserType } from '../enums/user-type';

import { AppUser } from './app-user';

export type UserProfileEdit = Pick<AppUser, 'firstName' | 'lastName' | 'email' | 'phone'>;

export type ProfileAppUserData = Pick<
  AppUser,
  'phone' | 'accessTier' | 'isCompanyOwner' | 'company' | 'companyData' | 'currentAccessTier'
>;

/** User model. */
export class UserProfile {
  /** User ID. */
  public readonly id: number;

  /** Type of user. */
  public readonly userType: UserType;

  /** User email. */
  public readonly email: string;

  /** First name of user. */
  public readonly firstName: string;

  /** Last name of user. */
  public readonly lastName: string;

  /** Is user active. */
  public readonly isActive: boolean;

  /** App user data. */
  public readonly appUserData?: ProfileAppUserData;

  /**
   * @constructor
   * @param data Constructor data.
   */
  public constructor(data: UserProfileConstructorData) {
    this.id = data.id;
    this.email = data.email;
    this.userType = data.userType;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.isActive = data.isActive;
    this.appUserData = data.appUserData;
  }
}

/** Data for select option constructor. */
export type UserProfileConstructorData = UserProfile;
