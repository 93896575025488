import { Directive, TemplateRef } from '@angular/core';

/**
 * Directive to mark ng-container as table-cell.
 *
 * <ng-container *scriptawTableCell="let row">
 *  {{ row|scriptawTableCell }}
 * </ng-container>.
 */
@Directive({
  selector: '[scriptawTableCell]',
})
export class TableCellDirective {
  public constructor(public template: TemplateRef<any>) {}
}
