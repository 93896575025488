import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { City } from '../models/city';
import { FetchListOptions } from '../models/fetch-list-options';
import { PagedList } from '../models/paged-list';
import { State } from '../models/state';

import { AppConfigService } from './app-config.service';
import { CityMapper } from './mappers/city.mapper';

import { CityDto } from './mappers/dto/city-dto';
import { PagedListDto } from './mappers/dto/paged-list-dto';
import { StateDto } from './mappers/dto/state-dto';
import { StateMapper } from './mappers/state.mapper';
import { HttpParamsMapper } from './mappers/http-params-mapper';
import { PagedListMapper } from './mappers/paged-list.mapper';

/** Filter options. */
export interface LocationFilterOptions {
  /** Search. */
  readonly search?: string;
}

/** Service to get locations data. */
@Injectable({ providedIn: 'root' })
export class LocationService {
  /** States list. */
  public readonly states$: Observable<State[]>;

  private readonly citiesUrl = new URL('locations/cities/', this.config.apiUrl).toString();
  private readonly stateUrl = new URL('locations/states/', this.config.apiUrl).toString();

  public constructor(
    private readonly config: AppConfigService,
    private readonly paramsMapper: HttpParamsMapper,
    private readonly listMapper: PagedListMapper,
    private readonly cityMapper: CityMapper,
    private readonly stateMapper: StateMapper,
    private readonly http: HttpClient,
  ) {
    this.states$ = this.getAllStates().pipe(
      shareReplay(1),
    );
  }

  /**
   * Get cities paged list.
   * @param options Fetch options.
   */
  public getCitiesPagedList(options: FetchListOptions): Observable<PagedList<City>> {
    const params = this.paramsMapper.toDto(options);

    return this.http
      .get<PagedListDto<CityDto>>(this.citiesUrl, { params })
      .pipe(
        map(response => this.listMapper.fromDto(response, this.cityMapper, options.pagination)),
      );
  }

  /**
   * Get states paged list.
   * @param options Fetch options.
   */
  public getStatesPagedList(options: FetchListOptions): Observable<PagedList<State>> {
    const params = this.paramsMapper.toDto(options);

    return this.http.get<PagedListDto<StateDto>>(this.stateUrl, { params }).pipe(
      map(response => this.listMapper.fromDto(
        response,
        this.stateMapper,
        options.pagination,
      )),
    );
  }

  private getAllStates(): Observable<State[]> {
    return this.http.get<StateDto[]>(this.stateUrl).pipe(
      map(response => response.map(dto => this.stateMapper.fromDto(dto))),
    );
  }
}
