<nav class="links">
  <a
    *ngFor="let link of tabs; trackBy: trackByIndex"
    class="link-item"
    routerLinkActive="active"
    [routerLink]="link.path"
  >
    {{ link.label }}
  </a>
</nav>
