import { Injectable } from '@angular/core';
import { IMapperFromDto } from '@scriptac/common/core/services/mappers/mappers';
import { MatrixFieldChoiceDto } from '@scriptac/common/core/services/mappers/dto/matrix-fields-dto';
import { MatrixFieldChoice } from '@scriptac/common/core/models/matrix-field-choice';

/** Choices value mapper. */
@Injectable({ providedIn: 'root' })
export class ChoicesValueMapper implements IMapperFromDto<MatrixFieldChoiceDto, MatrixFieldChoice> {

  /** @inheritdoc */
  public fromDto(dto: MatrixFieldChoiceDto): MatrixFieldChoice {
    return new MatrixFieldChoice({
      id: dto.id,
      name: dto.value,
    });
  }
}
