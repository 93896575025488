import { Injectable } from '@angular/core';

import { City } from '../../models/city';

import { CityDto } from './dto/city-dto';
import { IMapperFromDto } from './mappers';
import { RegionMapper } from './region.mapper';

/** Mapper for cities. */
@Injectable({ providedIn: 'root' })
export class CityMapper implements IMapperFromDto<CityDto, City> {
  /**
   * @constructor
   * @param regionMapper Mapper for regions.
   */
  public constructor(private readonly regionMapper: RegionMapper) {}

  /** @inheritdoc */
  public fromDto(dto: CityDto): City {
    return new City({
      id: dto.id,
      name: dto.name,
      region: dto.region,
      timezone: dto.timezone,
      regionData: this.regionMapper.fromDto(dto.region_data),
    });
  }
}
