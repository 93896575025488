/**
 * Sort directions.
 */
export enum SortDirection {
  /** Sort ascending (A -> Z). */
  ASC = 'asc',

  /** Sort descending (Z -> A). */
  DESC = 'desc',
}
