<table
  mat-table
  class="full-width">
  <ng-container *ngFor="let col of columns; trackBy: trackByName" [matColumnDef]="col.name">
    <th *matHeaderCellDef mat-header-cell class="header-cell">
      <span *ngIf="col.name !== 'title'; else titleHeaderCell">
        {{ col.headerText }}
      </span>
      <ng-template #titleHeaderCell></ng-template>
    </th>
  </ng-container>

  <!-- Header cells -->
  <tr *matHeaderRowDef="columnNames" mat-header-row></tr>

  <tr *matRowDef="let row; columns: columnNames" mat-row></tr>

  <!-- Empty Table message -->
  <ng-container matColumnDef="noRecords">
    <td *matFooterCellDef mat-cell class="empty-table-message" [attr.colspan]="columns.length">
      {{ emptyMessage }}
    </td>
  </ng-container>
  <tr
    *matFooterRowDef="['noRecords']"
    mat-footer-row
    [class.hidden]="columnNames.length > 0"
  >
  </tr>
</table>
<template>
  <mat-icon #lockIcon class="lock-icon" svgIcon="lock-black"></mat-icon>
</template>
