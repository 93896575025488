<mat-divider></mat-divider>
<div class="header">
  <h3>Reporting Information</h3>
</div>
<mat-divider></mat-divider>

<form class="filters-form" [formGroup]="filtersForm">
  <scriptac-label labelText="Jurisdiction" class="jurisdiction-select">
    <scriptaw-states-autocomplete
      formControlName="states"
      [needAllOption]="true"
    ></scriptaw-states-autocomplete>
  </scriptac-label>
  <scriptaw-export-menu-buttons
    *ngIf="showExportButton$ | async"
    (selectExportFileFormat)="exportResults($event)"
  ></scriptaw-export-menu-buttons>
</form>

<scriptaw-base-table
  emptyMessage="No items found."
  [rows]="reportingTemplate$ | async"
  [columns]="(tableColumns$ | async) || []"
  [trackBy]="trackById"
  [loading]="isLoading$ | async"
>
  <!-- Jurisdiction. -->
  <ng-container scriptawTableColumn="jurisdiction">
    <ng-container *scriptawTableCell="let row">
      <span>{{ row.state.name }}</span>
    </ng-container>
  </ng-container>

  <!-- View Data. -->
  <ng-container scriptawTableColumn="viewData">
    <ng-container *scriptawTableCell="let row">
      <a
        mat-flat-button
        class="link"
        [routerLink]="viewDataLink$ | async"
        [queryParams]="getViewDataQueryParams(row)"
      >
        View Data
      </a>
    </ng-container>
  </ng-container>

  <!-- Download Data. -->
  <ng-container scriptawTableColumn="downloadTemplate">
    <ng-container *scriptawTableCell="let row">
      <div *ngIf="row.templateUrl; else uploadDocument" class="template-cell">
        <a
          mat-flat-button
          class="link"
          download="Due Diligence Template"
          [href]="row.templateUrl | safe: 'resourceUrl'"
        >
          Download
        </a>
        <div *ngIf="isAdmin$ | async" class="actions">
          <button mat-icon-button type="button" title="Edit Template" (click)="editTemplate(row)">
            <mat-icon svgIcon="edit"></mat-icon>
          </button>
          <button
            mat-icon-button
            type="button"
            title="Delete Template"
            (click)="deleteTemplate(row)"
          >
            <mat-icon svgIcon="trash"></mat-icon>
          </button>
        </div>
      </div>
      <ng-template #uploadDocument>
        <button
          *ngIf="isAdmin$ | async"
          mat-flat-button
          class="link"
          type="button"
          (click)="editTemplate(row)"
        >
          Upload
        </button>
      </ng-template>
    </ng-container>
  </ng-container>
</scriptaw-base-table>
