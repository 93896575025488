import { ChangeDetectionStrategy, Component, ContentChild, Input } from '@angular/core';
import { FormControl, FormControlName, ValidationErrors } from '@angular/forms';
import { EMPTY, merge, Observable, ReplaySubject } from 'rxjs';
import { distinct, map, mapTo, switchMap } from 'rxjs/operators';

/**
 * Label component. Displays error and label for the input component.
 */
@Component({
  selector: 'scriptac-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelComponent {
  /** Text of control's label.  */
  @Input()
  public labelText = '';

  /** Hide error message. */
  @Input()
  public hideError = false;

  /** Catch inner input by form control directive. */
  @ContentChild(FormControlName)
  public set input(i: FormControlName) {
    if (i) {
      this.input$.next(i);
    }
  }

  /** Errors stream. */
  public readonly errors$: Observable<ValidationErrors | null>;

  private readonly input$ = new ReplaySubject<FormControlName>(1);

  public constructor() {
    this.errors$ = this.initErrorStream();
  }

  private initErrorStream(): Observable<ValidationErrors | null> {
    return this.input$.pipe(
      distinct(),
      switchMap(input => merge(
        input.statusChanges ?? EMPTY,
        this.catchControlTouchedChange(input.control),
      ).pipe(mapTo(input))),
      map(input => input.errors),
    );
  }

  private catchControlTouchedChange(control: FormControl): Observable<void> {
    return new Observable(observer => {
      const hostTouchedFn = control.markAsTouched;
      control.markAsTouched = () => {
        observer.next();
        observer.complete();
        hostTouchedFn.call(control);
      };
    });
  }
}
