<div class="control-container">
  <mat-chip-list multiple #chipList>
    <mat-chip
      *ngFor="let category of selectedItems | keyvalue; trackBy: trackByKey"
      (removed)="remove(category.value)"
      class="selected-option"
    >
      {{ category.value.code }}
      <mat-icon matChipRemove class="cancel-icon">cancel</mat-icon>
    </mat-chip>
    <input
      matInput
      class="autocomplete-input"
      #trigger="matAutocompleteTrigger"
      [formControl]="searchControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [placeholder]="placeholder"
      (click)="trigger.openPanel()"
    >
    <mat-icon matSuffix class="dropdown-arrow">arrow_drop_down</mat-icon>
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <div infiniteScroll class="infinite-scroll" [scrollWindow]="false" (scrolled)="listManager.nextPage()">
      <ng-container *ngLet="codes$ | async as codes">
        <ng-container *ngLet="listManager.listLoading$ | async as loading">
          <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

          <mat-option *ngIf="!codes?.length && !loading"> Not found </mat-option>
        </ng-container>

        <mat-option *ngFor="let category of codes" [value]="category">
          {{ category.code }} ({{ category.name }})
        </mat-option>
      </ng-container>
    </div>
  </mat-autocomplete>
</div>
