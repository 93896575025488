import { AccessTierLevel } from '../enums/access-tier-level';

/** App user model. */
export class AccessTier {
  /** Tier. */
  public readonly tier: AccessTierLevel;
  /** Value until tier valid. */
  public readonly validUntil: Date;
  /** Is active. */
  public readonly isActive?: boolean;

  /**
   * @constructor
   * @param data Data to fill class fields.
   */
  public constructor(data: AccessTierConstructorData) {
    this.tier = data.tier;
    this.validUntil = data.validUntil;
    this.isActive = data.isActive;
  }
}

export type AccessTierConstructorData = AccessTier;
