import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@saritasa/angular-typed-forms';
import { environment } from '@scriptac/environments/environment';

import { AppModule } from './app/app.module';

/** Checks whether the app works in prod environment. */
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
