import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { DestroyableComponent } from '../utils/destroyable';
import { MatrixColumnInfo } from '../models/matrix-column-info';

import { StorageService } from './storage.service';
import { CurrentUserService } from './current-user.service';

/** Service to store and get matrix columns order.  */
@Injectable()
@DestroyableComponent()
export class MatrixColumnsStorageService {
  private readonly currentUser$ = this.currentUserService.currentUser$;

  public constructor(
    private readonly storageService: StorageService,
    private readonly currentUserService: CurrentUserService,
  ) {
  }

  /**
   * Save columns order to storage.
   * @param columns Columns list.
   * @param matrixId Matrix id with these columns.
   */
  public saveColumns(columns: MatrixColumnInfo[], matrixId: number | string): Observable<void> {
    const serializedColumns = columns
      .map(el => ({ name: el.name, headerText: el.headerText, width: el.width ?? null, shouldDisplay: el.shouldDisplay }));

    return this.currentUser$.pipe(
      switchMap(user => {
        if (user) {
          const key = this.createKey(matrixId, user.id);
          return this.storageService.set(key, serializedColumns);
        }
        return [];
      }),
    );
  }

  /**
   * Get columns order from storage.
   * @param matrixId Matrix id for which we get columns order.
   */
  public getColumns(matrixId: number | string): Observable<MatrixColumnInfo[] | null> {
    return this.currentUser$.pipe(
      switchMap(user => {
        if (user) {
          const key = this.createKey(matrixId, user.id);
          return this.storageService.get<{ name: string; headerText: string; width: number; shouldDisplay: boolean; }[]>(key);
        }
        return [];
      }),
      map(savedColumns =>
        savedColumns ? savedColumns.map(el => ({
          name: el.name,
          sort: null,
          headerText: el.headerText,
          width: el.width,
          shouldDisplay: el.shouldDisplay,
        })) : null),
    );
  }

  private createKey(matrixId: number | string, userId: number): string {
    return `${userId}-${matrixId}`;
  }
}
