import { Injectable } from '@angular/core';

import { MatrixSearchType } from '../../enums/matrix-search-type';
import { Naupa2Code } from '../../models/naupa-2-code';

import { MatrixFilters } from '../api/matrix-api.service';
import { stringifyListByProp } from '../../utils/stringifyListByProp';

import { MatrixFiltersDto } from './dto/matrix-filters-dto';
import { IMapperToDto } from './mappers';

type MatrixQueryParams = Partial<Record<keyof MatrixFilters, string>>;

/**
 * Mapper for Matrix filters.
 */
@Injectable({ providedIn: 'root' })
export class MatrixFiltersMapper implements IMapperToDto<MatrixFiltersDto, MatrixFilters> {
  /** @inheritdoc */
  public toDto(data?: MatrixFilters): MatrixFiltersDto {
    const filters: MatrixFiltersDto = {};

    // We can't pass undefined or null to Angular HttpParams
    // That's why we need these additional checks.
    if (data?.search) {
      filters.search = data.search;
    }

    if (data?.isArchived !== undefined) {
      filters.is_archived = data.isArchived;
    }

    if (data?.type) {
      filters.search_type = data.type;
    }

    if (data?.naupa2Codes && data.naupa2Codes.length > 0) {
      filters.naupa2_codes = stringifyListByProp(data.naupa2Codes, 'id');
    }

    return filters;
  }

  /**
   * To query params.
   * @param data Filters.
   */
  public toQueryParams(data: MatrixFilters): MatrixQueryParams {
    return {
      search: data.search ? data.search : undefined,
      type: data.type,
      // Add naupa2Code to query params as 'codeId:codeName'
      // Example: `naupa2Codes=1:AC01,2:AC02,3:AC03`
      naupa2Codes: data.naupa2Codes?.length ?
        data.naupa2Codes?.map(code => `${code.id}:${code.code}`).join(',') :
        undefined,
    };
  }

  /**
   * To query params.
   * @param data Filters.
   */
  public fromQueryParams(data: MatrixQueryParams): MatrixFilters {
    const naupa2Codes = data.naupa2Codes?.split(',').map(code => {
      const [id, name] = code.split(':');
      return new Naupa2Code({
        id: Number(id),
        code: name,
        // We don't need other fields for display, so we omit them.
      } as any);
    });

    return {
      search: data.search,
      type: data.type as MatrixSearchType,
      naupa2Codes,
    };
  }
}
