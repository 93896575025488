import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { AppUserCompanyData } from '@scriptac/common/core/models/app-user';
import { listenControlChanges } from '@scriptac/common/core/rxjs/listen-control-changes';
import {
  CompaniesApiService,
  CompaniesFilters,
} from '@scriptac/common/core/services/api/companies-api.service';
import { DEFAULT_THROTTLE_TIME } from '@scriptac/common/core/utils/constants';
import { DestroyableComponent, takeUntilDestroy } from '@scriptac/common/core/utils/destroyable';
import { InfiniteScrollListStrategy, ListManager } from '@scriptac/common/core/utils/list-manager';
import { Subject } from 'rxjs';
import { shareReplay, tap, throttleTime } from 'rxjs/operators';

/** Dialog for company select. */
@Component({
  selector: 'scriptaw-company-select-dialog',
  templateUrl: './company-select-dialog.component.html',
  styleUrls: ['./company-select-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@DestroyableComponent()
export class CompanySelectDialogComponent implements OnInit {
  /** Table filters. */
  public readonly filtersForm = this.fb.groupTyped<CompaniesFilters>({
    search: [''],
  });

  /** Control of selected items. */
  public readonly listControl = this.fb.controlTyped<(AppUserCompanyData | null)[]>([]);

  /** List manager. */
  public readonly listManager = new ListManager<AppUserCompanyData, CompaniesFilters>({
    strategy: new InfiniteScrollListStrategy(),
    filter$: listenControlChanges(this.filtersForm),
  });

  /** List of companies. */
  public readonly companies$ = this.listManager
    .getPaginatedItems(option => this.companiesApiService.getCompaniesPagedLists(option))
    .pipe(
      shareReplay({
        bufferSize: 1,
        refCount: true,
      }),
    );

  /** Trigger that set throttleTime for getting next data. */
  private readonly getNextDataTrigger$: Subject<void> = new Subject();

  public constructor(
    private readonly fb: FormBuilder,
    private readonly dialogRef: MatDialogRef<CompanySelectDialogComponent>,
    private readonly companiesApiService: CompaniesApiService,
  ) {}

  /** @inheritdoc */
  public ngOnInit(): void {
    this.getNextDataTrigger$
      .pipe(
        throttleTime(DEFAULT_THROTTLE_TIME),
        tap(() => this.listManager.nextPage()),
        takeUntilDestroy(this),
      )
      .subscribe();
  }

  /**
   * Scroll listener.
   * @param event Event data.
   */
  public onScroll(event: Event): void {
    const target = event.target as HTMLElement;
    const isScrollOnBottom =
      target.offsetHeight + Math.ceil(target.scrollTop) >= target.scrollHeight;

    if (isScrollOnBottom) {
      this.getNextDataTrigger$.next();
    }
  }

  /** Confirm dialog. */
  public onConfirm(): void {
    this.dialogRef.close(this.listControl.value[0]);
  }

  /** Close dialog. */
  public onClose(): void {
    this.dialogRef.close();
  }
}
