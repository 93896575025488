import { Injectable } from '@angular/core';

import { Sort } from '../../models/sort';

import { IMapperToDto } from './mappers';

/**
 * Sort DTO.
 */
export interface SortDto {
  /** Field to order by. */
  readonly ordering: string;
}

/**
 * Sort params.
 */
export interface SortParams {
  /** SortInput object. */
  readonly sort: SortDto[];
}

/**
 * Mapper for Sort.
 */
@Injectable({ providedIn: 'root' })
export class SortMapper implements IMapperToDto<SortDto, Sort> {
  /** @inheritDoc */
  public toDto(sort: Sort): SortDto {
    // For descending sort use field name with '-' (e.g. '-field_name').
    return {
      ordering: sort.direction === 'asc' ? sort.field : `-${sort.field}`,
    };
  }
}
