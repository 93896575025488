<div class="progress-bar-container">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
</div>

<!-- This wrapper is needed to keep loader position on top -->
<div class="table-wrapper">
  <table
    mat-table
    matSort
    class="full-width"
    [trackBy]="trackBy"
    [dataSource]="dataSource"
    [matSortDirection]="sort?.direction ?? ''"
    [matSortActive]="sort?.field ?? ''"
    (matSortChange)="onSortChange($event)"
  >
    <ng-container *ngFor="let col of columns" [matColumnDef]="col.name" [stickyEnd]="col.isSticky">
      <ng-container *ngLet="getColumnByName(col.name) as column" >
        <!-- HEADER FOR THE CELL -->
        <th
          *matHeaderCellDef
          mat-header-cell
          class="fixed-header-cell"
          [disabled]="!col.sort"
          [mat-sort-header]="col.sort || ''"
          [ngStyle]="getCellStyles(col)"
        >
          <ng-container
            *ngIf="column; else defaultCellBlock"
            [ngTemplateOutletContext]="{ $implicit: col, headerText: column.headerText }"
            [ngTemplateOutlet]="column.header?.template || defaultHeaderTemplate"
          >
          </ng-container>
          <!-- If not found column template -->
          <ng-template #defaultCellBlock>
            <ng-container
              [ngTemplateOutletContext]="{ $implicit: col }"
              [ngTemplateOutlet]="defaultHeaderTemplate"
            >
            </ng-container>
          </ng-template>
        </th>

        <!-- BODY FOR THE CELL -->
        <td
          *matCellDef="let element"
          mat-cell
          [ngClass]="column ? getTableCellClass(column, element, col.name) : ''"
          [ngStyle]="getCellStyles(col)"
        >
          <!-- If found column template -->
          <ng-container
            *ngIf="column; else elseCellBlock"
            [ngTemplateOutletContext]="{ $implicit: element, col: col.name }"
            [ngTemplateOutlet]="column.cell?.template || defaultCellTemplate"
          >
          </ng-container>
          <!-- If not found column template -->
          <ng-template #elseCellBlock>
            <ng-container
              [ngTemplateOutletContext]="{ $implicit: element, col: col.name }"
              [ngTemplateOutlet]="defaultCellTemplate"
            >
            </ng-container>
          </ng-template>
        </td>
      </ng-container>
    </ng-container>

    <!-- Header cells -->
    <tr *matHeaderRowDef="columnNames; sticky: true" mat-header-row></tr>

    <tr *matRowDef="let row; columns: columnNames" mat-row [ngClass]="customRowClass(row)"></tr>

    <!-- Empty Table message -->
    <ng-container matColumnDef="noRecords">
      <td *matFooterCellDef mat-cell class="empty-table-message" [attr.colspan]="columns.length">
        {{ loading ? 'Loading...' : emptyMessage }}
      </td>
    </ng-container>
    <tr
      *matFooterRowDef="['noRecords']"
      mat-footer-row
      [class.hidden]="dataSource.data.length > 0"
    ></tr>
  </table>

  <!-- If not found cell template -->
  <ng-template #defaultCellTemplate let-row let-col="col">
    <div [title]="row[col] || ''">
      {{ row[col] || '' }}
    </div>
  </ng-template>

  <!-- If not found header template -->
  <ng-template #defaultHeaderTemplate let-col let-headerText="headerText">
    {{ getTableHeaderText(col, headerText) }}
  </ng-template>
</div>

<mat-paginator
  *ngIf="pagination"
  color="primary"
  [pageSize]="pagination.pageSize"
  [pageIndex]="pagination.page"
  [length]="pagination.totalCount"
  [hidePageSize]="true"
  (page)="paginationChanged($event)"
>
</mat-paginator>
