<ng-container *ngIf="exportJob$ | async as job else loading">
  <ng-container [ngSwitch]="job.status">
    <ng-container *ngSwitchCase="exportJobStatus.Pending">
      <p>Your file is preparing. Progress:</p>
      <span>{{ job.progress.current }} / {{ job.progress.total }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="exportJobStatus.Error">
      <p>Export error! Please, close this window and try again later.</p>
      <button mat-flat-button type="button" (click)="onClose()">
        Close
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="exportJobStatus.Finished">
      <p>Your file is ready. Click button below to download:</p>
      <a mat-flat-button color="accent" download="Export" [href]="job.fileLink">
        Download
      </a>
      <button mat-flat-button type="button" (click)="onClose()">
        Close
      </button>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #loading>
  <mat-spinner color="accent" class="loader" [diameter]="70">
  </mat-spinner>
</ng-template>