import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@scriptac/common/shared/common-shared.module';

import { SharedModule } from '../modules/shared/shared.module';

import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './layout.component';

/**
 * Layouts module.
 */
@NgModule({
  declarations: [LayoutComponent, HeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    CommonSharedModule,
    ReactiveFormsModule,
  ],
  exports: [LayoutComponent],
})
export class LayoutsModule {}
