import { RevisionStatus } from '../enums/revision-status';
import { ReasonForChange } from '../enums/reason-for-change';

import { Law, LawRevisionData } from './law';
import { VerticalMatrixElement } from './vertical-matrix-element';
import { MatrixValue, MatrixValueEdit } from './matrix-value';

/** Revision edit data. */
export type RevisionEdit = Pick<Revision, 'law' | 'effectiveSince' | 'effectiveTill' | 'status' | 'reasonForChange'> & {
  values: MatrixValueEdit[];
};

export type RevisionLawDataDto = Pick<Law, 'id' | 'stateId' | 'state'>;

/** Revision model. */
export class Revision implements VerticalMatrixElement {
  /** Revision id. */
  public readonly id: number;
  /** Revision version. */
  public readonly version: number;
  /** Revision status. */
  public readonly status: RevisionStatus;
  /** Revision effective date since. */
  public readonly effectiveSince: Date | null;
  /** Revision effective date till. */
  public readonly effectiveTill: Date | null;
  /** Law id of this revision. */
  public readonly law: number;
  /** Law data of this revision. */
  public readonly lawData?: LawRevisionData | null;
  /** Revision column values. */
  public values: MatrixValue[];
  /** Reason for change revision. */
  public readonly reasonForChange: ReasonForChange | null;

  public constructor(data: RevisionConstructorData) {
    this.id = data.id;
    this.version = data.version;
    this.status = data.status;
    this.effectiveSince = data.effectiveSince;
    this.effectiveTill = data.effectiveTill;
    this.law = data.law;
    this.lawData = data.lawData;
    this.values = data.values;
    this.reasonForChange = data.reasonForChange;
  }
}

/** Constructor data. */
export type RevisionConstructorData = Revision;
