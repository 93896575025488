import { PaginationOptions } from './pagination-options';

/**
 * Pagination list.
 */
export class PagedList<T> {
  /** Pagination. */
  public pagination: PaginationOptions;

  /** Items. */
  public items: T[];

  /**
   * @constructor
   * @param data Data to fill class fields.
   */
  public constructor(data: PagedListConstructorData<T>) {
    this.pagination = data.pagination;
    this.items = data.items;
  }
}

export type PagedListConstructorData<T> = PagedList<T>;
