import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IraDistribution } from '../models/ira-distribution';

import { AppConfigService } from './app-config.service';
import { IraDistributionDto } from './mappers/dto/ira-distribution-dto';
import { IraDistributionMapper } from './mappers/ira-distribution.mapper';

/**
 * IRA Distribution rules service.
 */
@Injectable({
  providedIn: 'root',
})
export class IraDistributionRulesService {
  private readonly iraDistributionUrl: string;

  public constructor(
    config: AppConfigService,
    private readonly http: HttpClient,
    private readonly iraDistributionMapper: IraDistributionMapper,
  ) {
    this.iraDistributionUrl = new URL('laws/ira-distribution/', config.apiUrl).toString();
  }

  /**
   * Get list of IRA distributions.
   */
  public getIraDistributions(): Observable<IraDistribution[]> {
    return this.http
      .get<IraDistributionDto[]>(this.iraDistributionUrl)
      .pipe(
        map(response => response.map(distribution => this.iraDistributionMapper.fromDto(distribution))),
      );
  }

  /**
   * Get IRA distribution by id.
   * @param id Id.
   */
  public getIraDistributionById(id: number): Observable<IraDistribution> {
    const url = new URL(`${id}/`, this.iraDistributionUrl).toString();
    return this.http
      .get<IraDistributionDto>(url)
      .pipe(
        map(dto => this.iraDistributionMapper.fromDto(dto)),
      );
  }

  /**
   * Save IRA distribution.
   * @param ira IRA Distribution.
   */
  public saveIraDistribution(ira: IraDistribution): Observable<IraDistribution> {
    const url = new URL(`${ira.id}/`, this.iraDistributionUrl).toString();
    const dto = this.iraDistributionMapper.toDto(ira);

    return this.http
      .put<IraDistributionDto>(url, dto)
      .pipe(
        map(response => this.iraDistributionMapper.fromDto(response)),
      );
  }
}
