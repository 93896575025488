<div class="control-container">
  <button mat-flat-button class="custom-icon-button" type="button" title="Add Filter" (click)="filter.open()">
      <span class="button-content">
        <mat-icon svgIcon="add"></mat-icon>
        <span>Add Filter</span>
      </span>
  </button>
  <mat-select multiple
              #filter
              panelClass="custom-select-dropdown"
              [disableOptionCentering]="true"
              class="hidden"
              [compareWith]="columnComparisonFunction"
              [formControl]="selectFormControl">
    <mat-option>
      <ngx-mat-select-search
        placeholderLabel="Search"
        noEntriesFoundLabel="No data found"
        [formControl]="searchControl">
      </ngx-mat-select-search>
    </mat-option>
    <mat-option *ngFor="let option of filteredOptions$ | async; trackBy: trackBy"
                [value]="option">
      {{ option.headerText }}
    </mat-option>
  </mat-select>
</div>
