import { Component, ChangeDetectionStrategy } from '@angular/core';

/** Page for wrong url`s. */
@Component({
  selector: 'scriptaw-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundPageComponent {}
