import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { Naupa2Code } from '../../models/naupa-2-code';

import { Naupa2CodeDto, Naupa2CodeEditDto } from './dto/naupa-2-code-dto';
import { ValidationErrorDto, extractErrorMessage } from './dto/validation-error-dto';
import { IMapperFromDto, IMapperToDto } from './mappers';
import { PropertyCodeCategoryMapper } from './property-code-category.mapper';
import { RuleMapper } from './rule.mapper';

/** Naupa code mapper. */
@Injectable({ providedIn: 'root' })
export class Naupa2CodeMapper implements IMapperFromDto<Naupa2CodeDto, Naupa2Code>,
IMapperToDto<Naupa2CodeEditDto, Naupa2Code> {

  public constructor(
    private readonly ruleMapper: RuleMapper,
    private readonly categoryMapper: PropertyCodeCategoryMapper,
  ) {}

  /** @inheritdoc */
  public fromDto(dto: Naupa2CodeDto): Naupa2Code {
    return new Naupa2Code({
      id: dto.id,
      code: dto.code,
      rules: dto.rules_data ? dto.rules_data.map(t => this.ruleMapper.fromDto(t)) : [],
      categoryId: dto.category,
      category: dto.category_data ? this.categoryMapper.fromDto(dto.category_data) : null as any,
      isRestricted: dto.is_restricted,
    });
  }

  /** @inheritdoc */
  public toDto(data: Naupa2Code): Naupa2CodeEditDto {
    return {
      id: data.id,
      code: data.code,
      category: data.categoryId,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<Naupa2CodeDto>,
  ): EntityValidationErrors<Naupa2Code> {
    const validationErrors: EntityValidationErrors<Naupa2Code> = {
      id: extractErrorMessage(errorDto.id),
      code: extractErrorMessage(errorDto.code),
      categoryId: extractErrorMessage(errorDto.category),
    };
    return validationErrors;
  }
}
