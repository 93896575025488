<div class="control-container">
  <mat-chip-list multiple #chipList>
    <mat-chip
      *ngFor="let state of getSortedSelectedStates(); trackBy: trackById"
      (removed)="remove(state)"
      class="selected-option"
    >
      {{ state.geonameCode }}
      <mat-icon *ngIf="state.id !== -1" matChipRemove class="cancel-icon">cancel</mat-icon>
    </mat-chip>
    <input
      matInput
      class="autocomplete-input"
      #trigger="matAutocompleteTrigger"
      [formControl]="stateControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [placeholder]="placeholder"
      (click)="trigger.openPanel()"
    />
    <mat-icon matSuffix class="dropdown-arrow">arrow_drop_down</mat-icon>
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <ng-container *ngLet="filteredStates$ | async as states">
      <mat-option *ngIf="!states?.length"> Not found </mat-option>
      <mat-option *ngFor="let state of states" [value]="state">
        {{ state.name }}
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</div>
