import {
  ValidationErrors,
  AbstractControlTyped,
  AbstractControl,
  ValidatorFn,
  FormGroupTyped,
  FormArray,
} from '@angular/forms';
import {
  MatrixFieldChoiceEdit,
} from '@scriptac/common/core/models/matrix-field-choice';
import { NullObject } from '@scriptac/common/core/models/null-object';
import { ValidationErrorCode } from '@scriptac/common/core/models/validation-error-code';
import { MatrixField } from '@scriptac/common/core/models/matrix-field';

export namespace ScriptaValidators {
  /**
   * Validate if 2 inputs are equals.
   * @param control1 Control to compare.
   */
  export function comparePasswordValidator(control1: AbstractControl): ValidatorFn {
    return (control2: AbstractControl): ValidationErrors | null => {
      const pass = control1.value;
      const pass2 = control2.value;
      return pass !== pass2 ?
        { [ValidationErrorCode.AppError]: { message: 'Passwords are not equal' } } :
        null;
    };
  }

  /**
   * Require validator for nullObjects.
   *
   * @param control Object that can be nullable control.
   */
  export function nullObjectRequiredValidator(
    control: AbstractControlTyped<NullObject>,
  ): ValidationErrors | null {
    return control.value?.isNull ? { [ValidationErrorCode.Required]: true } : null;
  }

  /**
   * Require validator for choices array.
   * Check if at least one item not for delete.
   *
   * @param control Choices list control.
   */
  export function choicesRequiredValidator(
    control: AbstractControlTyped<MatrixFieldChoiceEdit[]>,
  ): ValidationErrors | null {
    const { value } = control;
    return value.some(v => !v.toDelete) ? null : { [ValidationErrorCode.Required]: true };
  }

  /**
   * Checks whether the same properties` value from form groups inside FormArray are unique.
   * (f.e, FormArray <- FormGroup[] <- MatrixField with property name).
   * @param control Control is compared with others.
   */
  export function formArrayUniqueValuesValidator(control: AbstractControl): ValidationErrors | null {
    const formGroup = control.parent as FormGroupTyped<MatrixField>;
    const formArray = formGroup?.parent as FormArray;

    if (!formArray || formArray.controls.length < 2) {
      return null;
    }

    for (const arrayControl of formArray.controls) {
      if (arrayControl.value.id !== formGroup.value.id &&
        arrayControl.value.name.toLowerCase() === control.value.toLowerCase() &&
        !arrayControl.value.toDelete) {
        return {
          [ValidationErrorCode.UniqueValue]: {
            message: 'Fields must have unique names',
            coincidentControl: arrayControl,
          },
        };
      }
    }

    return null;
  }

  /**
   * Min date validator.
   * @param minDate Min date.
   * @param message Error message.
   */
  export function minDateValidator(minDate: Date, message?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const date = control.value;

      const errorMessage = message ?? `Minimum value is ${minDate.toLocaleDateString('en-US')}`;

      if (date < minDate) {
        return {
          [ValidationErrorCode.AppError]: {
            message: errorMessage,
          },
        };
      }

      return null;
    };
  }

  /**
   * Max date validator.
   * @param maxDate Max date.
   * @param message Message.
   */
  export function maxDateValidator(maxDate: Date, message?: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const date = control.value;

      const errorMessage = message ?? `Maximum value is ${maxDate.toLocaleDateString('en-US')}`;

      if (date > maxDate) {
        return {
          [ValidationErrorCode.AppError]: {
            message: errorMessage,
          },
        };
      }

      return null;
    };
  }

  /**
   * At least one of two fields is required.
   * @param destControlName Name of the control that should be checked.
   * @param errorMessage Error message text.
   */
  export function atLeastOneRequiredValidator(destControlName: string, errorMessage: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control.parent;
      const destControl = formGroup?.get(destControlName);
      if (!control.value && !destControl?.value) {
        return {
          [ValidationErrorCode.AppError]: { message: errorMessage },
        };
      }
      return null;
    };
  }
}
