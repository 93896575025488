import { Injectable } from '@angular/core';

import { MatrixSearchResult } from '../../models/matrix-search-result';

import { IMapperFromDto } from './mappers';
import { MatrixSearchResultDto } from './dto/matrix-search-result-dto';
import { MatrixFieldMapper } from './matrix-field-mapper';

/** Matrix search results mapper. */
@Injectable({ providedIn: 'root' })
export class SearchResultsMapper implements IMapperFromDto<MatrixSearchResultDto, MatrixSearchResult> {
  public constructor(
    private readonly matrixFieldMapper: MatrixFieldMapper,
  ) {
  }

  /** @inheritdoc */
  public fromDto(dto: MatrixSearchResultDto): MatrixSearchResult {
    return new MatrixSearchResult({
      id: dto.id,
      name: dto.name,
      searchType: dto.search_type,
      fields: dto.fields.map(field => this.matrixFieldMapper.fromDto(field)),
    });
  }
}
