import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@scriptac/common/shared/common-shared.module';

import { BaseTableComponent } from './components/base-table/base-table.component';
import { FooterComponent } from './components/footer/footer.component';
import { NotFoundPageComponent } from './components/not-found-page/not-found-page.component';
import { TableCellDirective } from './directives/table/table-cell.directive';
import { TableColumnDirective } from './directives/table/table-column.directive';
import { TableHeaderDirective } from './directives/table/table-header.directive';
import { UserSelectDialogComponent } from './components/user-select-dialog/user-select-dialog.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CompanySelectDialogComponent } from './components/company-select-dialog/company-select-dialog.component';
import { StatesAutocompleteComponent } from './components/states-autocomplete/states-autocomplete.component';
import { VerticalMatrixComponent } from './components/vertical-matrix/vertical-matrix.component';
import { HorizontalMatrixComponent } from './components/horizontal-matrix/horizontal-matrix.component';
import {
  NaupaCodeAutocompleteComponent,
} from './components/naupa-code-autocomplete/naupa-code-autocomplete.component';
import { NavigationTabsComponent } from './components/navigation-tabs/navigation-tabs.component';
import { PropertyTypeAutocompleteComponent } from './components/property-type-autocomplete/property-type-autocomplete.component';
import { ColumnsFilterSelectComponent } from './components/columns-filter-select/columns-filter-select.component';
import { WelcomePageHeaderComponent } from './components/welcome-page-header/welcome-page-header.component';
import { WelcomePageLayoutComponent } from './components/welcome-page-layout/welcome-page-layout.component';
import { CategoryAutocompleteComponent } from './components/category-autocomplete/category-autocomplete.component';
import { MatrixNamesAutocompleteComponent } from './components/matrix-names-autocomplete/matrix-names-autocomplete.component';
import { AdvancedBaseTableComponent } from './components/advanced-base-table/advanced-base-table.component';
import { UserTierLockComponent } from './components/user-tier-lock/user-tier-lock.component';
import { IraDistributionMatrixComponent } from './components/ira-distribution-matrix/ira-distribution-matrix.component';
import { RevisionMatrixWithChangesComponent } from './components/revision-matrix-with-changes/revision-matrix-with-changes.component';
import { PriorityRulesMatrixComponent } from './components/priority-rules-matrix/priority-rules-matrix.component';
import { ReportingDataMatrixComponent } from './components/reporting-data-matrix/reporting-data-matrix.component';
import { MatrixRevisionValuesFormComponent } from './components/matrix-revision-values-form/matrix-revision-values-form.component';
import { HolderTypeAutocompleteComponent } from './components/holder-type-autocomplete/holder-type-autocomplete.component';
import { MatrixRevisionDialogComponent } from './components/matrix-revision-dialog/matrix-revision-dialog.component';
import { DueDiligenceMatrixComponent } from './components/due-diligence-matrix/due-diligence-matrix.component';
import { ReportingTemplatesTableComponent } from './components/reporting-templates-table/reporting-templates-table.component';
import { MatrixDynamicCellComponent } from './components/matrix-dynamic-cell/matrix-dynamic-cell.component';
import { CustomTableCellDirective } from './directives/table/custom-table-cell.directive';
import { MatrixChoiceFieldValueSelectDialogComponent } from './components/matrix-revision-dialog/components/matrix-choice-field-value-select-dialog/matrix-choice-field-value-select-dialog.component';
import { CookiePolicyDialogComponent } from './components/cookie-policy-dialog/cookie-policy-dialog.component';
import { PrivacyPolicyDialogComponent } from './components/privacy-policy-dialog/privacy-policy-dialog.component';
import { ExportMenuButtonsComponent } from './components/export-menu-buttons/export-menu-buttons.component';
import { MatrixExportDialogComponent } from './components/matrix-export-dialog/matrix-export-dialog.component';

const EXPORTED_DECLARATIONS = [
  BaseTableComponent,
  FooterComponent,
  TableCellDirective,
  TableColumnDirective,
  TableHeaderDirective,
  UserSelectDialogComponent,
  CompanySelectDialogComponent,
  ConfirmDialogComponent,
  StatesAutocompleteComponent,
  NaupaCodeAutocompleteComponent,
  VerticalMatrixComponent,
  HorizontalMatrixComponent,
  NavigationTabsComponent,
  PropertyTypeAutocompleteComponent,
  ColumnsFilterSelectComponent,
  WelcomePageLayoutComponent,
  WelcomePageHeaderComponent,
  CategoryAutocompleteComponent,
  MatrixNamesAutocompleteComponent,
  AdvancedBaseTableComponent,
  UserTierLockComponent,
  IraDistributionMatrixComponent,
  RevisionMatrixWithChangesComponent,
  PriorityRulesMatrixComponent,
  ReportingDataMatrixComponent,
  MatrixRevisionValuesFormComponent,
  HolderTypeAutocompleteComponent,
  MatrixRevisionDialogComponent,
  MatrixChoiceFieldValueSelectDialogComponent,
  DueDiligenceMatrixComponent,
  ReportingTemplatesTableComponent,
  MatrixDynamicCellComponent,
  CookiePolicyDialogComponent,
  PrivacyPolicyDialogComponent,
  CustomTableCellDirective,
  ExportMenuButtonsComponent,
  MatrixExportDialogComponent,
];

/**
 * Shared module.
 */
@NgModule({
  declarations: [
    NotFoundPageComponent,
    ...EXPORTED_DECLARATIONS,
  ],
  imports: [CommonModule, CommonSharedModule, ReactiveFormsModule, RouterModule],
  exports: [...EXPORTED_DECLARATIONS],
})
export class SharedModule { }
