import { FormArray, FormGroup, FormGroupTyped } from '@angular/forms';
import { throwError, EMPTY, OperatorFunction, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AppValidationError, EntityValidationErrors } from '../models/app-error';
import { ValidationErrorCode } from '../models/validation-error-code';

/**
 * Util operator function to catch `AppValidationError` on presentational logic.
 * @param subjectOrForm Subject to emit data if it was there.
 */
export function catchValidationData<T, R>(
  subjectOrForm: Subject<EntityValidationErrors<T>> | FormGroupTyped<T>,
): OperatorFunction<R, R | never> {
  return source =>
    source.pipe(
      catchError(error => {
        // In case error is what we want, pass it to provided subject and finish the stream
        if (error instanceof AppValidationError) {
          const { validationData } = error;
          if (subjectOrForm instanceof Subject) {
            subjectOrForm.next(validationData);
          } else {
            fillFormWithError(subjectOrForm, validationData);
          }
          return EMPTY;
        }

        // Otherwise, let the error go
        return throwError(error);
      }),
    );
}

/**
 * Add errors in form.
 *
 * @param form Form to add errors.
 * @param errors Errors list.
 */
export function fillFormWithError<T>(form: FormGroupTyped<T>, errors: EntityValidationErrors<T>): void {
  const controlKeys = Object.keys(form.controls) as (keyof T)[];
  controlKeys.forEach(key => {
    const error = errors[key];
    const control = form.controls[key];
    if (error && control) {
      // If error is not nested
      if (typeof error === 'string') {
        control.setErrors({
          [ValidationErrorCode.AppError]: {
            message: error,
          },
        });
      } else if (Array.isArray(error) && typeof error[0] === 'string') {
        control.setErrors({
          [ValidationErrorCode.AppError]: {
            message: error[0],
          },
        });
      } else if (control instanceof FormArray) {
        control.controls.forEach((nestedControl, index) => {
          // TODO: Update EntityValidationErrors type to support arrays.
          const nestedError = (error as any)[index];
          fillFormWithError(
            nestedControl as FormGroupTyped<T[keyof T]>,
            nestedError as Object as EntityValidationErrors<T[keyof T]>,
          );
        });
      } else if (control instanceof FormGroup && typeof error === 'object') {
        // Since we checked the error type, help typescript with error typing
        fillFormWithError(
          control as FormGroupTyped<T[keyof T]>,
          error as Object as EntityValidationErrors<T[keyof T]>,
        );
      }
    }
  });
}
