import { enumToArray } from '../utils/enum-to-array';

/**
 * User types.
 */
export enum UserType {
  /** Admin. */
  Admin = 'ADMIN',

  /** User. */
  User = 'APP_USER',
}

export namespace UserType {
  const TITLE_MAP: Record<UserType, string> = {
    [UserType.Admin]: 'Admin',
    [UserType.User]: 'User',
  };

  /**
   * Convert a certain enum value to readable title.
   * @param value Value of enum.
   */
  // tslint:disable-next-line: completed-docs
  export function toReadable(value: UserType): string {
    const readable = TITLE_MAP[value];
    if (readable === undefined) {
      console.warn(`Unknown UserType: ${value}`);
    }
    return readable;
  }

  /**
   * Get list of all enum items.
   */
  // tslint:disable-next-line: completed-docs
  export function toArray(): UserType[] {
    return enumToArray(UserType);
  }

  /**
   * Track by function.
   * @param _index Index of item.
   * @param item Item.
   */
  // tslint:disable-next-line:completed-docs
  export function trackBy(_index: number, item: UserType): UserType {
    return item;
  }
}
