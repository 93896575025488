import { Rule } from './rule';

/** Property type. */
export class PropertyType {
  /** Id of property. */
  public readonly id: number;
  /** Property name. */
  public readonly name: string;
  /** Rules' list for this naupa code with property types. */
  public readonly rules: Rule[];

  public constructor(data: PropertyType) {
    this.id = data.id;
    this.name = data.name;
    this.rules = data.rules;
  }
}
