import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessTierLevel } from '@scriptac/common/core/enums/access-tier-level';
import { UserType } from '@scriptac/common/core/enums/user-type';
import { Law, LawEdit, SearchResultsLaw } from '@scriptac/common/core/models/law';
import { MatrixColumnInfo } from '@scriptac/common/core/models/matrix-column-info';
import { MatrixSearchResult } from '@scriptac/common/core/models/matrix-search-result';
import { MatrixValue } from '@scriptac/common/core/models/matrix-value';
import { PaginationOptions } from '@scriptac/common/core/models/pagination-options';
import { Revision } from '@scriptac/common/core/models/revision';
import { filterNull } from '@scriptac/common/core/rxjs/filter-null';
import { listenControlChanges } from '@scriptac/common/core/rxjs/listen-control-changes';
import { LawApiService } from '@scriptac/common/core/services/api/law-api.service';
import { CurrentUserService } from '@scriptac/common/core/services/current-user.service';
import { DialogUtilsService } from '@scriptac/common/core/services/dialog-utils.service';
import { StateFilterQueryParamsService } from '@scriptac/common/core/services/mappers/state-filter-query-params.mapper';
import { MatrixColumnsStorageService } from '@scriptac/common/core/services/matrix-columns-storage.service';
import { NotificationService } from '@scriptac/common/core/services/notifications.service';
import { ReportingDataService, ReportingMechanicsFilters } from '@scriptac/common/core/services/reporting-data.service';
import { createMatrixColumns } from '@scriptac/common/core/utils/create-matrix-columns';
import { DestroyableComponent, takeUntilDestroy } from '@scriptac/common/core/utils/destroyable';
import { getInitialMatrixColumns } from '@scriptac/common/core/utils/get-initial-matrix-columns';
import { ListManager, TableListStrategy } from '@scriptac/common/core/utils/list-manager';
import { combineLatest, merge, Subject } from 'rxjs';
import { filter, first, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { REPORTING_DATE_FORMAT } from '@scriptac/common/core/utils/constants';
import { ExportFileFormat } from '@scriptac/common/core/enums/export-file-format';
import { ReportingMatrixExportFilters } from '@scriptac/common/core/models/reporting-matrix-export-filters';
import { MatrixExportService } from '@scriptac/common/core/services/matrix-export.service';

import { routePaths } from '../../../../route-paths';
import {
  MatrixRevisionDialogComponent,
  AddRevisionDialogData,
  MatrixRevisionDialogResult,
} from '../matrix-revision-dialog/matrix-revision-dialog.component';

import { AddLawDialogData, MatrixAddLawDialogComponent } from '../../../admin-matrix/matrix-add-law-dialog/matrix-add-law-dialog.component';
import { MatrixExportDialogComponent, MatrixExportDialogData } from '../matrix-export-dialog/matrix-export-dialog.component';
import { ALL_OPTION_ID } from '../states-autocomplete/states-autocomplete.component';

/** Reporting data matrix. */
@Component({
  selector: 'scriptaw-reporting-data-matrix',
  templateUrl: './reporting-data-matrix.component.html',
  styleUrls: ['./reporting-data-matrix.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MatrixColumnsStorageService],
})
@DestroyableComponent()
export class ReportingDataMatrixComponent implements OnInit {
  /** Route paths. */
  public readonly routePaths = routePaths;

  /** Date format to display. */
  public readonly dateFormat = REPORTING_DATE_FORMAT;

  /** Is admin. */
  public readonly isAdmin$ = this.currentUserService.currentUser$.pipe(
    map(user => user?.userType === UserType.Admin),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  private readonly reloadMatrix$ = new Subject<void>();

  /** Matrix data stream. */
  public readonly matrix$ = this.reloadMatrix$.pipe(
    startWith(null),
    switchMap(() => this.reportingDataService.getReportingMatrix()),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  /** Filters form. */
  public readonly filtersForm = this.fb.groupTyped<ReportingMechanicsFilters>({
    states: [undefined],
  });

  /** Filters. */
  public readonly filters$ = combineLatest([
    listenControlChanges<ReportingMechanicsFilters>(this.filtersForm),
    this.matrix$,
  ]).pipe(
    map(([filters]) => filters),
  );

  /** States names. */
  public readonly statesNames$ = this.filters$.pipe(
    filterNull(),
    map(filters => filters.states?.map(state => state.name).join(', ')),
  );

  /** Table manager. */
  public readonly tableManager = new ListManager<SearchResultsLaw, ReportingMechanicsFilters>({
    strategy: new TableListStrategy(),
    filter$: this.filters$,
  });

  /** Matrix laws list. */
  public readonly matrixLaws$ = this.tableManager.getPaginatedItems(
    options => this.reportingDataService.getReportingMatrixLaws(options),
  ).pipe(
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  /** Update columns from filter. */
  private readonly updatedColumns$ = new Subject<MatrixColumnInfo[]>();

  /** Columns from storage to active matrix. */
  private readonly columnsFromStorage$ = this.matrix$.pipe(
    switchMap(matrix => this.matrixColumnsStorageService.getColumns(matrix.id)),
  );

  private readonly columnsUpdate$ = merge(
    this.updatedColumns$,
    this.columnsFromStorage$,
  );

  /** Columns data for active tab matrix. */
  public readonly columns$ = combineLatest([
    this.matrix$,
    this.columnsUpdate$,
  ]).pipe(
    map(([matrix, savedColumns]) => this.calculateMatrixColumns(matrix, savedColumns)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  /** Show export button according to user tier. */
  public readonly showExportButton$ = this.currentUserService.currentUser$.pipe(
    map(user => user?.appUserData?.currentAccessTier?.tier === AccessTierLevel.Tier3),
  );

  /** Export file format. */
  public readonly exportFileFormat = ExportFileFormat;

  public constructor(
    private readonly reportingDataService: ReportingDataService,
    private readonly currentUserService: CurrentUserService,
    private readonly matrixColumnsStorageService: MatrixColumnsStorageService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly stateFilterQueryParamsMapper: StateFilterQueryParamsService,
    private readonly dialog: MatDialog,
    private readonly fb: FormBuilder,
    private readonly notificationService: NotificationService,
    private readonly lawService: LawApiService,
    private readonly dialogUtilsService: DialogUtilsService,
    private readonly matrixExportService: MatrixExportService,
  ) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    const states = this.route.snapshot.queryParamMap.get('states');
    if (states) {
      this.filtersForm.patchValue({
        states: this.stateFilterQueryParamsMapper.fromQueryParams(states),
      });
    }

    this.filters$.pipe(
      takeUntilDestroy(this),
    ).subscribe(filters => this.setQueryParams(filters));
  }

  /**
   * Export results.
   * @param exportFileFormat Export file format.
   */
  public exportResults(exportFileFormat: ExportFileFormat): void {
    this.filters$.pipe(first())
      .subscribe(form => {
        const stateIds = form.states?.map(s => s.id).filter(id => id !== ALL_OPTION_ID);
        this.openExportDialog(exportFileFormat, stateIds);
      });
  }

  /**
   * Edit law.
   */
  public addLaw(): void {
    this.matrix$.pipe(
      first(),
      switchMap(matrix => this.dialog.open<MatrixAddLawDialogComponent, AddLawDialogData, LawEdit>(MatrixAddLawDialogComponent, {
        width: '600px',
        data: {
          matrixId: matrix.id,
        },
      }).afterClosed()),
      filterNull(),
      switchMap(updatedLaw => this.lawService.createLaw(updatedLaw)),
      tap(law => this.openRevisionDialog(law)),
      takeUntilDestroy(this),
    ).subscribe(updateLaw => {
      if (updateLaw) {
        this.notificationService.showSuccess('Law saved successfully');
        this.tableManager.reloadList();
      }
    });
  }

  /**
   * Open add revision dialog.
   * @param law Law.
   */
  public openRevisionDialog(law: Law): void {
    this.matrix$.pipe(
      first(),
      switchMap(matrix => {
        const lawWithMatrixData = new Law({
          ...law,
          matrix,
        });
        const templateRevisions = [
          law.currentRevision,
          law.pendingRevision,
          law.enactedRevision,
        ].filter((revision): revision is Revision => Boolean(revision));

        return this.dialog.open<MatrixRevisionDialogComponent, AddRevisionDialogData,
          MatrixRevisionDialogResult>(MatrixRevisionDialogComponent, {
            width: '100%',
            data: {
              law: lawWithMatrixData,
              revision: null,
              templateRevisions,
            },
          }).afterClosed();
      }),
    ).subscribe(result => {
      if (result?.revision !== null) {
        this.notificationService.showSuccess('Revision saved successfully');
        this.tableManager.reloadList();
      }

      if (result?.shouldReloadMatrix) {
        this.reloadMatrix$.next();
      }
    });
  }

  /**
   * Delete law.
   * @param law Law.
   */
  public deleteLaw(law: SearchResultsLaw): void {
    this.dialogUtilsService.openConfirmationModal('Are you sure you want to delete this law?')
      .pipe(
        filter(Boolean),
        switchMap(() => this.lawService.deleteLaw(law.id)),
      ).subscribe(() => {
        this.notificationService.showSuccess('Law was deleted successfully');
        this.tableManager.reloadList();
      });
  }

  /**
   * Get values list from law data.
   * @param law Law data.
   */
  public getValuesListFromLaw(law: SearchResultsLaw): MatrixValue[] {
    return law.currentRevision?.values ?? law.pendingRevision?.values ?? [];
  }

  /**
   * Save columns to storage.
   * @param columns Columns list.
   */
  public saveUpdatedColumns(columns: MatrixColumnInfo[]): void {
    this.matrix$.pipe(
      first(),
      switchMap(matrix => this.matrixColumnsStorageService.saveColumns(columns, matrix.id)),
    )
      .subscribe(() => this.updatedColumns$.next(columns));
  }

  /**
   * Whether display column with view history buttons.
   * @param laws Laws list.
   */
  public shouldDisplayViewHistoryColumn(laws: SearchResultsLaw[] | null): boolean {
    return laws ? laws.some(law => this.shouldDisplayViewHistory(law)) : false;
  }

  /**
   * Whether display view history button for law.
   * @param law Law info.
   */
  public shouldDisplayViewHistory(law: SearchResultsLaw): boolean {
    return law.hasHistory;
  }

  /**
   * Paginator changed.
   * @param page Page event.
   */
  public paginationChanged(page: PageEvent): void {
    const newPagination = new PaginationOptions({
      page: page.pageIndex,
      pageSize: page.pageSize,
      totalCount: page.length,
    });

    this.tableManager.setPagination(newPagination, true);
  }

  private calculateMatrixColumns(matrix: MatrixSearchResult, savedColumns: MatrixColumnInfo[] | null): MatrixColumnInfo[] {
    const columnsFromApi = createMatrixColumns(matrix.fields);
    return getInitialMatrixColumns(savedColumns, columnsFromApi);
  }

  private openExportDialog(fileFormat: ExportFileFormat, jurisdictionIds: number[] = []): void {
    this.dialog.open<MatrixExportDialogComponent, MatrixExportDialogData<ReportingMatrixExportFilters>>(MatrixExportDialogComponent, {
      width: '500px',
      data: {
        filters: {
          fileFormat,
          jurisdictionIds,
        },
        getMatrixExportResult: jobId => this.matrixExportService.getReportingMatrixExportResult(jobId),
        startMatrixExport: args => this.matrixExportService.startReportingMatrixExport(args),
      },
      disableClose: true,
    });
  }

  private setQueryParams(filters: ReportingMechanicsFilters): void {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          states: filters.states?.length ?
            this.stateFilterQueryParamsMapper.toQueryParams(filters.states) :
            undefined,
        },
      },
    );
  }
}
