import { Sort as MatSort } from '@angular/material/sort';

import { SortDirection } from '../enums/sort-direction';
import { Sort } from '../models/sort';

/**
 * Convert material Sort model to internal Sort model.
 * @param matSort Mat sort data.
 */
export function matSortToInternalSort(matSort: MatSort): Sort | undefined {
  if (!matSort.direction) {
    return undefined;
  }

  return new Sort({
    field: matSort.active,
    direction: <SortDirection>matSort.direction,
  });
}
