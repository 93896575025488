/** Interface for constructor data. */
export interface PaginationConstructorData {
  /** Current Page. */
  readonly page?: number;
  /** Page Size. */
  readonly pageSize?: number;
  /** Total Count. */
  readonly totalCount?: number;
  /** Is next page exist. */
  readonly haveNext?: boolean;
}

/**
 * Pagination data.
 */
export class PaginationOptions {
  /** Current Page. */
  public readonly page: number;

  /** Page Size. */
  public readonly pageSize: number;

  /** Total Count. */
  public readonly totalCount: number;

  /** Is next page exist. */
  public readonly haveNext: boolean;

  private readonly defaultPageSize = 10;

  /**
   * @constructor
   * @param data Data to fill class fields.
   */
  public constructor(data?: PaginationConstructorData) {
    this.page = data?.page ?? 0;
    this.pageSize = data?.pageSize ?? this.defaultPageSize;
    this.totalCount = data?.totalCount ?? 1;
    this.haveNext = data?.haveNext ?? ((this.page + 1) * this.pageSize) < this.totalCount;
  }

  /** Get offset. */
  public get offset(): number {
    return this.pageSize * (this.page);
  }
}
