import { Injectable } from '@angular/core';

import { PaginationOptions } from '../../models/pagination-options';

import { IMapperToDto } from './mappers';

/**
 * Pagination params.
 */
export interface PaginationParams {
  /** Offset. */
  readonly offset: number;
  /** Count of items. */
  readonly limit: number;
}

/**
 * Mapper for Pagination.
 */
@Injectable({ providedIn: 'root' })
export class PaginationOptionsMapper implements IMapperToDto<PaginationParams, PaginationOptions> {
  /** @inheritDoc */
  public toDto(pagination: PaginationOptions): PaginationParams {
    return {
      offset: pagination.offset,
      limit: pagination.pageSize,
    };
  }
}
