<div *ngIf="loading$ | async" class="progress-bar-container">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<!-- This wrapper is needed to keep loader position on top -->
<div class="table-wrapper">
  <table mat-table class="full-width" [dataSource]="dataSource">
    <ng-container *ngFor="let col of columns; trackBy: trackByName" [matColumnDef]="col.name">
      <th mat-header-cell *matHeaderCellDef>
        {{ col.headerText }}
      </th>
      <td mat-cell *matCellDef="let element">
        <ng-container [ngSwitch]="col.name">
          <ng-container *ngSwitchCase="titleColumnName">
            <span class="title-cell">{{ element[col.name] }}</span>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <div class="table-cell-text">
              {{ element[col.name] }}
            </div>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <!-- Header row for title cell -->
    <ng-container matColumnDef="header-title">
      <th mat-header-cell *matHeaderCellDef class="title-top-cell">
      </th>
    </ng-container>

    <div *ngIf="iraDistributionRules$ | async"></div>

    <ng-container *ngFor="let ira of iraDistributionRules$ | async; trackBy: trackById">
      <ng-container [matColumnDef]="'ira-col-' + ira.id">
        <th *matHeaderCellDef mat-header-cell [attr.colspan]="2">
          <div class="table-header">
            <span>
              {{ getTableHeaderText(ira) }}
            </span>
            <button
              *ngIf="showAdminActions"
              mat-icon-button
              class="table-header-btn"
              type="button"
              title="Edit revision"
              (click)="editIraRule(ira)">
              <mat-icon svgIcon="edit"></mat-icon>
            </button>
          </div>
        </th>
      </ng-container>
    </ng-container>

    <tr 
      *matHeaderRowDef="headerColumns$ | async"
      mat-header-row
      [class.hidden]="dataSource.data.length === 0"
    >
    </tr>

    <!-- Header cells -->
    <tr *matHeaderRowDef="columnNames" mat-header-row [class.hidden]="dataSource.data.length === 0"></tr>

    <tr *matRowDef="let row; columns: columnNames" mat-row></tr>

    <!-- Empty Table message -->
    <ng-container matColumnDef="noRecords">
      <td *matFooterCellDef mat-cell class="empty-table-message" [attr.colspan]="columns.length">
        <span *ngIf="loading$ | async; else noDataRow">Loading data...</span>
        <ng-template #noDataRow>No item found.</ng-template>
      </td>
    </ng-container>
    <tr
      *matFooterRowDef="['noRecords']"
      mat-footer-row
      [class.hidden]="dataSource.data.length > 0"
    ></tr>
  </table>
</div>
