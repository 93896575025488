import { Injectable } from '@angular/core';

import { PropertyCodeCategoryFilters } from '../property-code-category.service';

import { PropertyCodeCategoryFiltersDto } from './dto/property-code-categoty-filters-dto';
import { IMapperToDto } from './mappers';

/** Mapper for Property Code Category filters. */
@Injectable({ providedIn: 'root' })
export class PropertyCodeCategoryFiltersMapper implements IMapperToDto<PropertyCodeCategoryFiltersDto, PropertyCodeCategoryFilters> {

  /** @inheritdoc */
  public toDto(data: PropertyCodeCategoryFilters): PropertyCodeCategoryFiltersDto {
    const params: PropertyCodeCategoryFiltersDto = {};

    if (data.search) {
      params.search = data.search;
    }

    if (data.jurisdictionIds && data.jurisdictionIds.length > 0) {
      // Filter id -1 to avoid errors and get all list options
      params.codes__types__jurisdictions = data.jurisdictionIds.filter(id => id !== -1);
    }

    if (data.hideStateSpecific) {
      params.is_state_specific = false;
    }

    return params;
  }
}
