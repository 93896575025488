import { AccessTier } from './access-tier';
import { AppUser } from './app-user';
import { City } from './city';

/** Data about company users. */
export type CompanyAppUser = Pick<AppUser, 'id' | 'firstName' | 'lastName' | 'email' | 'phone'>;

/** Data about new company owner. */
export type CompanyNewOwner = Pick<AppUser, 'firstName' | 'lastName' | 'email' | 'phone'>;

export type CompanyEditData = Pick<
  Company,
  'name' | 'owner' | 'ownerData' | 'cityData' | 'accessTier' | 'address' | 'zipcode' | 'usersData' | 'isActive'
> & { readonly newOwner?: CompanyNewOwner; };

/** Interface for companies DTO. */
export class Company {
  /** Company id. */
  public readonly id: number;
  /** Company name. */
  public readonly name: string;
  /** Is company active. */
  public readonly isActive: boolean;
  /** Company owner id. */
  public readonly owner?: number;
  /** Data about company owner. */
  public readonly ownerData?: CompanyAppUser;
  /** Company city id. */
  public readonly city: number;
  /** Data about city. */
  public readonly cityData: City;
  /** Company access tier. */
  public readonly accessTier: AccessTier;
  /** Company address. */
  public readonly address: string;
  /** Company zipcode. */
  public readonly zipcode: string;
  /** List of users id. */
  public readonly users: number[];
  /** List users object. */
  public readonly usersData: CompanyAppUser[];

  /**
   * @constructor
   * @param data Data to fill class fields.
   */
  public constructor(data: CompanyConstructorData) {
    this.id = data.id;
    this.name = data.name;
    this.isActive = data.isActive;
    this.owner = data.owner;
    this.ownerData = data.ownerData;
    this.city = data.city;
    this.cityData = data.cityData;
    this.accessTier = data.accessTier;
    this.address = data.address;
    this.zipcode = data.zipcode;
    this.users = data.users;
    this.usersData = data.usersData;
  }
}

export type CompanyConstructorData = Company;
