<ng-container *ngIf="field$ | async as field">
  <h3 class="field-name">
    {{ field.name }}
  </h3>
  <div class="selection-list custom-select-list">
    <ng-container *ngIf="isSingleSelect; else multiSelect">
      <mat-radio-group [value]="selectFormControl.value[0]" (change)="selectFormControl.setValue([$event.value])">
        <div *ngFor="let option of field.choices">
          <mat-radio-button class="example-radio-button" [value]="option.id">
            <mat-option class="hidden-option" [value]="option.id"></mat-option>
            <div class="selection-list-option">
              <div class="selection-list-option__text">
                <quill-view class="small-quill" [content]="option.name"></quill-view>
                <div *ngIf="getRelatedRevisionTitles(field, option) as titles" class="selection-list-option__revisions">
                  Used in: {{ titles }}
                </div>
              </div>
              <span *ngIf="checkIsNewChoice(option)" class="selection-list-option__new-indicator">
                new
              </span>
            </div>
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </ng-container>
    <ng-template #multiSelect>
      <div *ngFor="let option of field.choices">
        <mat-checkbox
          [checked]="selectFormControl.value.includes(option.id)"
          (change)="handleMultiSelect($event, option)"
        >
          <mat-option class="hidden-option" [value]="option.id"></mat-option>
          <div class="selection-list-option">
            <div class="selection-list-option__text">
              <quill-view class="small-quill" [content]="option.name"></quill-view>
              <div *ngIf="getRelatedRevisionTitles(field, option) as titles" class="selection-list-option__revisions">
                Used in: {{ titles }}
              </div>
            </div>
            <span *ngIf="checkIsNewChoice(option)" class="selection-list-option__new-indicator">
              new
            </span>
          </div>
        </mat-checkbox>
      </div>
    </ng-template>
  </div>
</ng-container>

<p class="enter-value-message">Enter new value:</p>
<div class="progress-container">
  <mat-progress-bar *ngIf="loading$ | async" mode="indeterminate"></mat-progress-bar>
</div>
<div class="editor-wrapper">
  <quill-editor
    #editor
    class="text-editor"
    [modules]="editorOptions"
    [formControl]="newValueFormControl"
    (click)="editor.quillEditor.focus()"
  >
  </quill-editor>
  <button mat-button type="button" [disabled]="loading$ | async" (click)="saveNewValue()">
    Add
  </button>
</div>
<scriptac-form-control-validation-message [control]="newValueFormControl"></scriptac-form-control-validation-message>

<div class="action-buttons">
  <button
    mat-button
    type="button"
    [disabled]="loading$ | async"
    (click)="closeDialog()"
  >
    Close
  </button>
</div>
