import { enumToArray } from '../utils/enum-to-array';

/** Level of access tiers. */
export enum AccessTierLevel {
  Tier1 = 1,
  Tier2 = 2,
  Tier3 = 3,
}

export namespace AccessTierLevel {
  const TITLE_MAP: Record<AccessTierLevel, string> = {
    [AccessTierLevel.Tier1]: 'Tier 1',
    [AccessTierLevel.Tier2]: 'Tier 2',
    [AccessTierLevel.Tier3]: 'Tier 3',
  };

  /**
   * Convert a certain enum value to readable title.
   * @param value Value of enum.
   */
  // tslint:disable-next-line: completed-docs
  export function toReadable(value?: AccessTierLevel | null): string {
    if (!value) {
      return '';
    }

    const readable = TITLE_MAP[value];
    if (readable === undefined) {
      console.warn(`Unknown AccessTierLevel: ${value}`);
    }
    return readable;
  }

  /**
   * Get list of all enum items.
   */
  // tslint:disable-next-line: completed-docs
  export function toArray(): AccessTierLevel[] {
    return enumToArray(AccessTierLevel);
  }

  /**
   * Track by function.
   * @param _index Index of item.
   * @param item Item.
   */
  // tslint:disable-next-line:completed-docs
  export function trackBy(_index: number, item: AccessTierLevel): AccessTierLevel {
    return item;
  }
}
