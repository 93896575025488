import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserType } from '@scriptac/common/core/enums/user-type';
import { AccessTierLevel } from '@scriptac/common/core/enums/access-tier-level';

import { LayoutComponent } from './layouts/layout.component';
import { AuthPageComponent } from './modules/auth/auth-page.component';
import { AuthGuard } from './modules/auth/guards/auth.guard';
import { NonAuthGuard } from './modules/auth/guards/non-auth.guard';
import { RestrictUserTypesGuard } from './modules/auth/guards/restrict-user-types.guard';
import { NotFoundPageComponent } from './modules/shared/components/not-found-page/not-found-page.component';
import { RestrictUserTierGuard } from './modules/auth/guards/restrict-user-tier.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'search',
        loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
      },
      {
        path: 'tracking',
        loadChildren: () =>
          import('./modules/legislative-tracking/legislative-tracking.module').then(m => m.LegislativeTrackingModule),
        canActivate: [RestrictUserTierGuard],
        data: {
          breadcrumb: 'Legislative Tracking',
          userTiers: [AccessTierLevel.Tier2, AccessTierLevel.Tier3],
        },
      },
      {
        path: 'reporting',
        loadChildren: () =>
          import('./modules/reporting/reporting.module').then(m => m.ReportingModule),
        data: { breadcrumb: 'Reporting' },
      },
      {
        path: 'change-report',
        loadChildren: () =>
          import('./modules/change-report/change-report.module').then(m => m.ChangeReportModule),
        data: { breadcrumb: 'Change Report' },
      },
      {
        path: 'profile',
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
        data: { breadcrumb: 'Profile' },
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/admin-matrix/admin-matrix.module').then(m => m.AdminMatrixModule),
        canActivate: [RestrictUserTypesGuard],
        data: {
          userTypes: [UserType.Admin],
        },
      },
      {
        path: 'ira-distribution-rules',
        loadChildren: () =>
          import('./modules/ira-distribution-admin/ira-distribution-admin.module').then(m => m.IraDistributionAdminModule),
        canActivate: [RestrictUserTypesGuard],
        data: {
          userTypes: [UserType.Admin],
          breadcrumb: 'IRA Distribution Rules',
        },
      },
      {
        path: 'priority-rules',
        loadChildren: () =>
          import('./modules/priority-rules-admin/priority-rules-admin.module').then(m => m.PriorityRulesAdminModule),
        canActivate: [RestrictUserTypesGuard],
        data: {
          userTypes: [UserType.Admin],
          breadcrumb: 'Priority Rules',
        },
      },
      {
        path: 'definitions',
        loadChildren: () =>
          import('./modules/definitions-admin/definitions-admin.module').then(m => m.DefinitionsAdminModule),
        canActivate: [RestrictUserTypesGuard],
        data: {
          userTypes: [UserType.Admin],
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'admin-news',
        loadChildren: () =>
          import('./modules/admin-news/admin-news.module').then(m => m.AdminNewsModule),
        canActivate: [RestrictUserTypesGuard],
        data: {
          userTypes: [UserType.Admin],
          breadcrumb: 'News',
        },
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./modules/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [RestrictUserTypesGuard],
        data: {
          breadcrumb: 'Settings',
          userTypes: [UserType.Admin],
        },
      },
      {
        path: 'accounts',
        loadChildren: () =>
          import('./modules/accounts/accounts.module').then(m => m.AccountsModule),
        canActivate: [RestrictUserTypesGuard],
        data: {
          breadcrumb: 'Accounts',
          userTypes: [UserType.Admin],
        },
      },
      {
        path: 'admin-reporting',
        loadChildren: () =>
          import('./modules/admin-reporting/admin-reporting.module').then(m => m.AdminReportingModule),
        canActivate: [RestrictUserTypesGuard],
        canLoad: [RestrictUserTypesGuard],
        data: {
          breadcrumb: 'Reporting Mechanics',
          userTypes: [UserType.Admin],
        },
      },
      {
        path: 'not-found',
        component: NotFoundPageComponent,
      },
    ],
  },
  {
    path: 'welcome',
    loadChildren: () =>
      import('./modules/welcome/welcome.module').then(m => m.WelcomeModule),
  },
  {
    path: 'news',
    loadChildren: () =>
      import('./modules/news/news.module').then(m => m.NewsModule),
  },
  {
    path: 'auth',
    component: AuthPageComponent,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [NonAuthGuard],
  },
  { path: '', pathMatch: 'full', redirectTo: 'welcome' },
  { path: '**', redirectTo: 'auth' },
];

/** App routing module. */
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
